/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BoxDoorItem, EditSmartCab, SmartCab, SmartCabOverview, UnboxingStatistics, UpdateBoxDoor, } from "@ixam/apis/types/smart_cab";
// @ts-ignore
import { SpacePerms, UserRole } from "@ixam/apis/types/perms";
export namespace SmartCabs {
    export type ListAPIPayload = {};
    export function list(payload: ListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<SmartCab[]>>(`/api/smartCabs`, { method, data, params, skipErrorHandler });
    }
    export type UpdateAPIPayload = {
        smartCabCode: string;
        lockerId: string;
        value: Partial<EditSmartCab>;
    };
    export function update(payload: UpdateAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["value"] = payload["value"];
        return request<ResponseSuccess<void>>(`/api/smartCabs/${payload.smartCabCode}/lockers/${payload.lockerId}`, { method, data, params, skipErrorHandler });
    }
    export type DeletesAPIPayload = {
        smartCabCode: string;
        lockerId: string;
    };
    export function deletes(payload: DeletesAPIPayload, skipErrorHandler?: boolean) {
        const method = "delete";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<void>>(`/api/smartCabs/${payload.smartCabCode}/lockers/${payload.lockerId}`, { method, data, params, skipErrorHandler });
    }
    export type BoxDoorListAPIPayload = {
        smartCabCode: string;
        lockerId: string;
    };
    export function boxDoorList(payload: BoxDoorListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<BoxDoorItem[]>>(`/api/smartCabs/${payload.smartCabCode}/lockers/${payload.lockerId}/boxDoors`, { method, data, params, skipErrorHandler });
    }
    export type UpdateBoxDoorsAPIPayload = {
        smartCabCode: string;
        boxId: string;
        value: Partial<UpdateBoxDoor>;
    };
    export function updateBoxDoors(payload: UpdateBoxDoorsAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["value"] = payload["value"];
        return request<ResponseSuccess<void>>(`/api/smartCabs/${payload.smartCabCode}/lockers/-/boxDoors/${payload.boxId}`, { method, data, params, skipErrorHandler });
    }
    export type OverviewAPIPayload = {};
    export function overview(payload: OverviewAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<SmartCabOverview>>(`/api/smartCabs:overview`, { method, data, params, skipErrorHandler });
    }
    export type TimesStatisticsAPIPayload = {
        dateType: string;
        lockerId: string;
    };
    export function timesStatistics(payload: TimesStatisticsAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["dateType"] = `${payload["dateType"]}`;
        params["lockerId"] = `${payload["lockerId"]}`;
        return request<ResponseSuccess<{
            date: string;
            count: number;
        }[]>>(`/api/smartCabs:timesStatistics`, { method, data, params, skipErrorHandler });
    }
    export type UnboxingStatisticsAPIPayload = {
        dateType: string;
        lockerId: string;
    };
    export function unboxingStatistics(payload: UnboxingStatisticsAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["dateType"] = `${payload["dateType"]}`;
        params["lockerId"] = `${payload["lockerId"]}`;
        return request<ResponseSuccess<UnboxingStatistics[]>>(`/api/smartCabs:unboxingStatistics`, { method, data, params, skipErrorHandler });
    }
}
