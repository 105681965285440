// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'all.token.invalid': '您的身份已过期，请重新登录',
  'all.lang.chinese': '简体中文',
  'all.lang.english': 'English',
  'all.lang.korean': '한국어',
  'all.remarks': '备注',
  'all.add-remarks': '备注',
  'all.serial.number': '序号',
  'all.cancel': '取消',
  'all.save': '保存',
  'all.confirm': '确定',
  'all.reset': '重置',
  'all.delete': '删除',
  'all.edit': '编辑',
  'all.operating': '操作',
  'all.add': '添加',
  'all.add.success': '添加成功',
  // 字符限制
  'all.valid.max.long': '最大长度{count}',
  'all.valid.min.long': '最小长度{count}',
  'all.unit': '个',

  'all.list.number': '编号',
  'all.online': '在线',
  'all.offline': '离线',
  'all.status': '状态',
  'all.occupy': '占用',
  'all.vacant': '空闲',

  'all.upload-success': '上传成功',
  'all.uploadFail': '上传图片失败',
  'all.time': '时间',
  'all.minute': '分钟',
  'all.hour': '小时',
  'all.unknown': '未知',
};
