// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { ADMIN_URL, IMAGE_URL, PARTNER_URL } from '@/global';
import { Avatar, Dropdown } from 'antd';
import { EditOutlined, InfoCircleOutlined, LogoutOutlined, RetweetOutlined } from '@ant-design/icons';
import { UserLoginResult } from '@ixam/apis/types';
import { getToken } from '@/utils/utils';
import { history, useIntl, useModel } from '@umijs/max';
import React, { useCallback, useMemo } from 'react';
import SelectLang from '@/components/SelectLang';
import styles from '../index.less';

type RightContentProps = {
  user: UserLoginResult;
};

enum MenuItemKey {
  ACCOUNT_INFO = 'ACCOUNT_INFO',
  SWITCH_PROJECT = 'SWITCH_PROJECT',
  TO_PARTNER = 'TO_PARTNER',
  TO_ADMIN = 'TO_ADMIN',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  LOGOUT = 'LOGOUT',
}

const RightContent: React.FC<RightContentProps> = ({ user }) => {
  const { formatMessage } = useIntl();
  const { logout } = useModel('Login.model');

  const handleMenuClick = useCallback(
    ({ key }: { key: string }) => {
      switch (key) {
        case MenuItemKey.CHANGE_PASSWORD:
          history.push('/changePassword');
          break;
        case MenuItemKey.SWITCH_PROJECT:
          history.push('/project/switch');
          break;
        case MenuItemKey.TO_PARTNER:
          window.location.href = `${PARTNER_URL}/autoLogin?token=${getToken()}&role=partner`;
          break;
        case MenuItemKey.TO_ADMIN:
          window.location.href = `${ADMIN_URL}/autoLogin?token=${getToken()}&role=admin`;
          break;
        case MenuItemKey.ACCOUNT_INFO:
          history.push('/changeAccountInfo');
          break;
        case MenuItemKey.LOGOUT:
          logout();
          break;
      }
    },
    [logout]
  );

  const menu = useMemo(() => {
    const items = [
      {
        key: MenuItemKey.ACCOUNT_INFO,
        icon: <InfoCircleOutlined />,
        label: formatMessage({ id: 'header.menu.account-info' }),
      },
    ];
    if (user.multipleCompany) {
      items.push({
        key: MenuItemKey.SWITCH_PROJECT,
        icon: <RetweetOutlined />,
        label: formatMessage({ id: 'header.menu.switch-company' }),
      });
    }
    if (user.hasPartnerPermissions) {
      items.push({
        key: MenuItemKey.TO_PARTNER,
        icon: <RetweetOutlined />,
        label: formatMessage({ id: 'header.menu.to-partner' }),
      });
    }
    if (user.hasAdminPermissions) {
      items.push({
        key: MenuItemKey.TO_ADMIN,
        icon: <RetweetOutlined />,
        label: formatMessage({ id: 'header.menu.to-admin' }),
      });
    }
    items.push(
      {
        key: MenuItemKey.CHANGE_PASSWORD,
        icon: <EditOutlined />,
        label: formatMessage({ id: 'header.menu.change-password' }),
      },
      { key: MenuItemKey.LOGOUT, icon: <LogoutOutlined />, label: formatMessage({ id: 'header.menu.logout' }) }
    );
    return { items, onClick: handleMenuClick };
  }, [user, formatMessage, handleMenuClick]);
  // TODO 这里没有添加深色页面的UI，遇到深色页面时再添加
  return (
    <div className={styles['right-content']}>
      <SelectLang style={{ marginRight: 10, color: '#fff' }} />
      {user.companyName && <div className={styles['company-name']}>{user.companyName}</div>}
      <Dropdown menu={menu} overlayClassName={styles['right-content-dropdown']}>
        <span className={styles.action}>
          <Avatar size="small" className={styles.avatar} src={user.avatar || `${IMAGE_URL}header.png`} alt="avatar" />
          <span>{user.username || ''}</span>
        </span>
      </Dropdown>
    </div>
  );
};

export default RightContent;
