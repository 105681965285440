/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { OpenApiPermission } from "@ixam/apis/types/open-api";
export namespace OpenApi {
    export type GetOpenApiPermissionsAPIPayload = {
        projectId: number;
    };
    export function getOpenApiPermissions(payload: GetOpenApiPermissionsAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["projectId"] = `${payload["projectId"]}`;
        return request<ResponseSuccess<OpenApiPermission[]>>(`/api/openApiPermissions`, { method, data, params, skipErrorHandler });
    }
    export type SetOpenApiPermissionsAPIPayload = {
        projectId: number;
        permissions: OpenApiPermission[];
    };
    export function setOpenApiPermissions(payload: SetOpenApiPermissionsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["projectId"] = payload["projectId"];
        data["permissions"] = payload["permissions"];
        return request<ResponseSuccess<void>>(`/api/setOpenApiPermissions`, { method, data, params, skipErrorHandler });
    }
}
