// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 平面图管理
  'floor-plan.workplace': '职场',
  'floor-plan.floor': '楼层',
  'floor-plan.area': '区域',
  'floor-plan.coverage': '覆盖面积',
  'floor-plan.add.area-label': '区域',
  'floor-plan.add.coverage-label': '覆盖面积',
  'floor-plan.select': '选择平面图',
  'floor-plan.edit.expand': '展开全部',
  'floor-plan.edit.collapse': '收起',
  'floor-plan.select.available-space': '选择可利用空间',
  'floor-plan.book-not-found-all': '在全部平面图上均未找到该工位。',
  'floor-plan.book-not-found-current': '当前平面图未找到该工位，但在平面图{floorPlans}找到了该工位。',
  'floor-plan.book-found-all': '当前平面图已找到该工位，同时在平面图{floorPlans}也找到了该工位。',
  'floor-plan.book-found-current': '当前平面图已找到该工位。',
  'floor-plan.book-point': '、',
};
