/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { ShareLink } from "@ixam/apis/types/share";
export namespace Share {
    export type ToggleAPIPayload = {
        enable: boolean;
        tag: string;
        map_id?: number;
    };
    export function toggle(payload: ToggleAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["enable"] = payload["enable"];
        data["tag"] = payload["tag"];
        data["map_id"] = payload["map_id"];
        return request<ResponseSuccess<ShareLink>>(`/api/share`, { method, data, params, skipErrorHandler });
    }
    export type GetShareAPIPayload = {
        tag: string;
        map_id?: number;
    };
    export function getShare(payload: GetShareAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["tag"] = `${payload["tag"]}`;
        params["map_id"] = `${payload["map_id"]}`;
        return request<ResponseSuccess<ShareLink | null>>(`/api/share`, { method, data, params, skipErrorHandler });
    }
    export type ResetAPIPayload = {
        tag: string;
        map_id?: number;
    };
    export function reset(payload: ResetAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["tag"] = payload["tag"];
        data["map_id"] = payload["map_id"];
        return request<ResponseSuccess<ShareLink>>(`/api/share/reset`, { method, data, params, skipErrorHandler });
    }
}
