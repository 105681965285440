/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BeginHour, DaysFilter, EndHour, MatchType, QueryType } from "@ixam/apis/types/constant";
// @ts-ignore
import { CancelStatusWeeklyStatItem, SpotBookStatistics, SpotBookTotalStatItem, SpotBookWeeklyStatItem, UseTimeWeekdayItem, } from "@ixam/apis/types/spot-book-analysis";
export namespace SpotBookAnalysis {
    export type SpotBookStatisticsAPIPayload = {
        workdayType: DaysFilter;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: QueryType;
        floorPlanId?: number;
        restrictTags?: number[];
        matchType?: MatchType;
    };
    export function spotBookStatistics(payload: SpotBookStatisticsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["restrictTags"] = payload["restrictTags"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<SpotBookStatistics>>(`/api/spot/book/statistics`, { method, data, params, skipErrorHandler });
    }
    export type GetSpotBookTotalStatAPIPayload = {
        workdayType: DaysFilter;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: QueryType;
        floorPlanId?: number;
        restrictTags?: number[];
        matchType?: MatchType;
    };
    export function getSpotBookTotalStat(payload: GetSpotBookTotalStatAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["restrictTags"] = payload["restrictTags"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<SpotBookTotalStatItem>>(`/api/spot/book/totalStat`, { method, data, params, skipErrorHandler });
    }
    export type GetSpotBookWeeklyStatAPIPayload = {
        workdayType: DaysFilter;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: QueryType;
        floorPlanId?: number;
        restrictTags?: number[];
        matchType?: MatchType;
    };
    export function getSpotBookWeeklyStat(payload: GetSpotBookWeeklyStatAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["restrictTags"] = payload["restrictTags"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<SpotBookWeeklyStatItem>>(`/api/spot/book/statWeekly`, { method, data, params, skipErrorHandler });
    }
    export type GetSpotBookUseTimeWeeklyStatAPIPayload = {
        workdayType: DaysFilter;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: QueryType;
        floorPlanId?: number;
        restrictTags?: number[];
        matchType?: MatchType;
    };
    export function getSpotBookUseTimeWeeklyStat(payload: GetSpotBookUseTimeWeeklyStatAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["restrictTags"] = payload["restrictTags"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<UseTimeWeekdayItem>>(`/api/spot/book/useTimeWeeklyStat`, { method, data, params, skipErrorHandler });
    }
    export type GetSpotBookCancelStatusWeeklyStatAPIPayload = {
        workdayType: DaysFilter;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: QueryType;
        floorPlanId?: number;
        restrictTags?: number[];
        matchType?: MatchType;
    };
    export function getSpotBookCancelStatusWeeklyStat(payload: GetSpotBookCancelStatusWeeklyStatAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["restrictTags"] = payload["restrictTags"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<CancelStatusWeeklyStatItem>>(`/api/spot/book/cancelWeeklyStat`, { method, data, params, skipErrorHandler });
    }
}
