/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { AdminEvaluationItem, EvaluationToUpdate, EvaluationToUpdateMobile } from "@ixam/apis/types/evaluation";
// @ts-ignore
import { SortType } from "@ixam/apis/types/constant";
export namespace Evaluation {
    export type GetEvaluationListAdminAPIPayload = {
        keyword?: string;
        limit?: number;
        offset?: number;
        sortBy?: string;
        sortType?: SortType;
    };
    export function getEvaluationListAdmin(payload: GetEvaluationListAdminAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["keyword"] = `${payload["keyword"]}`;
        params["limit"] = `${payload["limit"]}`;
        params["offset"] = `${payload["offset"]}`;
        params["sortBy"] = `${payload["sortBy"]}`;
        params["sortType"] = `${payload["sortType"]}`;
        return request<ResponseSuccess<{
            evaluationList: AdminEvaluationItem[];
            total: number;
        }>>(`/api/admin/evaluation/list`, { method, data, params, skipErrorHandler });
    }
    export type DeleteEvaluationAPIPayload = {
        ticketIds: number[];
    };
    export function deleteEvaluation(payload: DeleteEvaluationAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ticketIds"] = payload["ticketIds"];
        return request<ResponseSuccess<void>>(`/api/admin/evaluation/delete`, { method, data, params, skipErrorHandler });
    }
    export type UpdateEvaluationAPIPayload = {
        evaluation: EvaluationToUpdate;
    };
    export function updateEvaluation(payload: UpdateEvaluationAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["evaluation"] = payload["evaluation"];
        return request<ResponseSuccess<void>>(`/api/admin/evaluation/update`, { method, data, params, skipErrorHandler });
    }
    export type UpdateEvaluationMobileAPIPayload = {
        evaluation: EvaluationToUpdateMobile;
    };
    export function updateEvaluationMobile(payload: UpdateEvaluationMobileAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["evaluation"] = payload["evaluation"];
        return request<ResponseSuccess<void>>(`/api/evaluation/update`, { method, data, params, skipErrorHandler });
    }
    export type AppendEvaluationMobileAPIPayload = {
        evaluation: EvaluationToUpdateMobile;
    };
    export function appendEvaluationMobile(payload: AppendEvaluationMobileAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["evaluation"] = payload["evaluation"];
        return request<ResponseSuccess<void>>(`/api/evaluation/append`, { method, data, params, skipErrorHandler });
    }
}
