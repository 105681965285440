/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BeginHour, DaysFilter, EndHour, MinTimeRule } from "@ixam/apis/types/constant";
// @ts-ignore
import { OverviewDataType } from "@ixam/apis/types/overview";
export namespace Overview {
    export type IndexAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
    };
    export function index(payload: IndexAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        return request<ResponseSuccess<OverviewDataType>>(`/api/overview/data`, { method, data, params, skipErrorHandler });
    }
}
