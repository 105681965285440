/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { AssetInfo, AssetToUpdate } from "@ixam/apis/types/asset";
// @ts-ignore
import { FileType } from "@ixam/apis/types/_types";
// @ts-ignore
import { Lang } from "@ixam/apis/types/constant";
// @ts-ignore
import { Ticket } from "@ixam/apis/types/ticket";
// @ts-ignore
import TimeZone from "@ixam/apis/types/timezone";
export namespace Asset {
    export type GetAssetListAPIPayload = {};
    export function getAssetList(payload: GetAssetListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<AssetInfo[]>>(`/api/space/asset/list`, { method, data, params, skipErrorHandler });
    }
    export type EditAssetAPIPayload = {
        data: AssetToUpdate;
    };
    export function editAsset(payload: EditAssetAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/space/asset/edit`, { method, data, params, skipErrorHandler });
    }
    export type GetSingleAssetInfoAPIPayload = {
        uuid: string;
    };
    export function getSingleAssetInfo(payload: GetSingleAssetInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["uuid"] = `${payload["uuid"]}`;
        return request<ResponseSuccess<AssetInfo>>(`/api/mobile/asset/singleInfo`, { method, data, params, skipErrorHandler });
    }
    export type GetAssetAfterSaleListAPIPayload = {
        uuid: string;
    };
    export function getAssetAfterSaleList(payload: GetAssetAfterSaleListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["uuid"] = `${payload["uuid"]}`;
        return request<ResponseSuccess<Ticket[]>>(`/api/mobile/asset/afterSaleList`, { method, data, params, skipErrorHandler });
    }
    export type UpdateProductImageUrlAPIPayload = {
        spotIds: string[];
        url: string;
    };
    export function updateProductImageUrl(payload: UpdateProductImageUrlAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["spotIds"] = payload["spotIds"];
        data["url"] = payload["url"];
        return request<ResponseSuccess<{
            total_count: number;
            success_count: number;
        }>>(`/api/space/asset/updateProductImageUrl`, { method, data, params, skipErrorHandler });
    }
    export type DownloadAssetQrCodeBatchAPIPayload = {
        ids: string[];
        title?: string;
        subTitle?: string;
        hints?: string;
        logoUrl?: string;
        locale?: string;
        width?: number;
        height?: number;
    };
    export function downloadAssetQrCodeBatch(payload: DownloadAssetQrCodeBatchAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["title"] = payload["title"];
        data["subTitle"] = payload["subTitle"];
        data["hints"] = payload["hints"];
        data["logoUrl"] = payload["logoUrl"];
        data["locale"] = payload["locale"];
        data["width"] = payload["width"];
        data["height"] = payload["height"];
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/space/asset/data_qrcodes_download`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type DownloadAssetUpdateTemplateAPIPayload = {
        ids: string[];
        lang: Lang;
        timeZone: TimeZone;
    };
    export function downloadAssetUpdateTemplate(payload: DownloadAssetUpdateTemplateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["lang"] = payload["lang"];
        data["timeZone"] = payload["timeZone"];
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/space/asset/get_template`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type UpdateAssetByTemplateAPIPayload = {
        file: File;
        timeZone: TimeZone;
    };
    export function updateAssetByTemplate(payload: UpdateAssetByTemplateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data = new FormData();
        data.append("token", token);
        data.append("file", payload["file"]);
        data.append("timeZone", payload["timeZone"]);
        return request<ResponseSuccess<{
            total_count: number;
            success_count: number;
        }>>(`/api/space/asset/update_by_template`, { method, data, params, skipErrorHandler });
    }
    export type ExportAssetsAPIPayload = {
        lang: Lang;
        timeZone: TimeZone;
        ids?: string[];
    };
    export function exportAssets(payload: ExportAssetsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["lang"] = payload["lang"];
        data["timeZone"] = payload["timeZone"];
        data["ids"] = payload["ids"];
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/space/asset/export`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
}
