/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { FileType } from "@ixam/apis/types/_types";
// @ts-ignore
import { HandsetItem, PartnerHandsetItem } from "@ixam/apis/types/handset";
// @ts-ignore
import { ProjectStatus } from "@ixam/apis/types/partner-project";
export namespace Handset {
    export type GetHandsetListAPIPayload = {};
    export function getHandsetList(payload: GetHandsetListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<HandsetItem[]>>(`/api/admin/handset/list`, { method, data, params, skipErrorHandler });
    }
    export type BulkAddHandsetAPIPayload = {
        handsetIds: string[];
        partnerId?: number;
        remark?: string;
    };
    export function bulkAddHandset(payload: BulkAddHandsetAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["handsetIds"] = payload["handsetIds"];
        data["partnerId"] = payload["partnerId"];
        data["remark"] = payload["remark"];
        return request<ResponseSuccess<void>>(`/api/admin/handset/bulkAdd`, { method, data, params, skipErrorHandler });
    }
    export type UpdateHandsetAPIPayload = {
        id: string;
        isDel?: boolean;
        remark?: string | null;
    };
    export function updateHandset(payload: UpdateHandsetAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["id"] = payload["id"];
        data["isDel"] = payload["isDel"];
        data["remark"] = payload["remark"];
        return request<ResponseSuccess<void>>(`/api/admin/handset/update`, { method, data, params, skipErrorHandler });
    }
    export type ImportHandsetBatchAPIPayload = {
        file: File;
    };
    export function importHandsetBatch(payload: ImportHandsetBatchAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data = new FormData();
        data.append("token", token);
        data.append("file", payload["file"]);
        return request<ResponseSuccess<{
            total_count: number;
            success_count: number;
        }>>(`/api/admin/handset/import`, { method, data, params, skipErrorHandler });
    }
    export type ForceImportAndDownloadHandsetErrorListAPIPayload = {
        file: File;
    };
    export function forceImportAndDownloadHandsetErrorList(payload: ForceImportAndDownloadHandsetErrorListAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data = new FormData();
        data.append("token", token);
        data.append("file", payload["file"]);
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/admin/handset/downloadErrorList`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type BatchAssignHandsetAPIPayload = {
        ids: string[];
        partnerId?: number;
    };
    export function batchAssignHandset(payload: BatchAssignHandsetAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["partnerId"] = payload["partnerId"];
        return request<ResponseSuccess<void>>(`/api/admin/handset/batchAssign`, { method, data, params, skipErrorHandler });
    }
    export type BatchDeleteHandsetAPIPayload = {
        ids: string[];
    };
    export function batchDeleteHandset(payload: BatchDeleteHandsetAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        return request<ResponseSuccess<{
            success_total: number;
        }>>(`/api/admin/handset/batchDelete`, { method, data, params, skipErrorHandler });
    }
    export type GetSpotStatInfoAPIPayload = {
        uuid: string;
    };
    export function getSpotStatInfo(payload: GetSpotStatInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["uuid"] = `${payload["uuid"]}`;
        return request<ResponseSuccess<{
            resourceId: string;
            hasSensor: boolean;
            inUse: boolean;
            companyStatus: ProjectStatus;
            isShared: boolean;
            name: string;
            isOccupied: boolean;
            productImageUrl?: string | null;
        }>>(`/api/mobile/handset/getSpotStatInfo`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerHandsetListAPIPayload = {
        query?: string;
    };
    export function getPartnerHandsetList(payload: GetPartnerHandsetListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["query"] = `${payload["query"]}`;
        return request<ResponseSuccess<PartnerHandsetItem[]>>(`/api/partner/handset/list`, { method, data, params, skipErrorHandler });
    }
    export type EditPartnerHandsetAPIPayload = {
        id: string;
        remark?: string;
    };
    export function editPartnerHandset(payload: EditPartnerHandsetAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["id"] = payload["id"];
        data["remark"] = payload["remark"];
        return request<ResponseSuccess<void>>(`/api/partner/handset/edit`, { method, data, params, skipErrorHandler });
    }
}
