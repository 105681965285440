/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BeginHour, DaysFilter, EndHour, MatchType, MinTimeRule, QueryType } from "@ixam/apis/types/constant";
// @ts-ignore
import { PhoneBoothAnalysisOverview, PhoneBoothUsageRate, PhoneBoothUseTimeResult, } from "@ixam/apis/types/phone_booth_analysis";
export namespace PhoneBoothAnalysis {
    export type OverviewAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: QueryType;
        floorPlanId?: number;
        matchType?: MatchType;
        restrictTags?: number[];
    };
    export function overview(payload: OverviewAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["workdayType"] = `${payload["workdayType"]}`;
        params["minTimeRule"] = `${payload["minTimeRule"]}`;
        params["beginHour"] = `${payload["beginHour"]}`;
        params["endHour"] = `${payload["endHour"]}`;
        params["dateType"] = `${payload["dateType"]}`;
        params["floorPlanId"] = `${payload["floorPlanId"]}`;
        params["matchType"] = `${payload["matchType"]}`;
        if (payload["restrictTags"])
            params["restrictTags"] = payload["restrictTags"].map((item) => `${item}`);
        return request<ResponseSuccess<PhoneBoothAnalysisOverview>>(`/api/phoneBoothAnalysis/-/overview`, { method, data, params, skipErrorHandler });
    }
    export type UsageRateAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: QueryType;
        floorPlanId?: number;
        matchType?: MatchType;
        restrictTags?: number[];
    };
    export function usageRate(payload: UsageRateAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["workdayType"] = `${payload["workdayType"]}`;
        params["minTimeRule"] = `${payload["minTimeRule"]}`;
        params["beginHour"] = `${payload["beginHour"]}`;
        params["endHour"] = `${payload["endHour"]}`;
        params["dateType"] = `${payload["dateType"]}`;
        params["floorPlanId"] = `${payload["floorPlanId"]}`;
        params["matchType"] = `${payload["matchType"]}`;
        if (payload["restrictTags"])
            params["restrictTags"] = payload["restrictTags"].map((item) => `${item}`);
        return request<ResponseSuccess<PhoneBoothUsageRate[]>>(`/api/phoneBoothAnalysis/-/usageRate`, { method, data, params, skipErrorHandler });
    }
    export type WeeklyUsageAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: QueryType;
        floorPlanId?: number;
        matchType?: MatchType;
        restrictTags?: number[];
    };
    export function weeklyUsage(payload: WeeklyUsageAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["workdayType"] = `${payload["workdayType"]}`;
        params["minTimeRule"] = `${payload["minTimeRule"]}`;
        params["beginHour"] = `${payload["beginHour"]}`;
        params["endHour"] = `${payload["endHour"]}`;
        params["dateType"] = `${payload["dateType"]}`;
        params["floorPlanId"] = `${payload["floorPlanId"]}`;
        params["matchType"] = `${payload["matchType"]}`;
        if (payload["restrictTags"])
            params["restrictTags"] = payload["restrictTags"].map((item) => `${item}`);
        return request<ResponseSuccess<{
            weekday: number;
            duration: number;
        }[]>>(`/api/phoneBoothAnalysis/-/weeklyUsage`, { method, data, params, skipErrorHandler });
    }
    export type SingleUseTimeAPIPayload = {
        phoneBoothId: string;
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: QueryType;
        floorPlanId?: number;
        matchType?: MatchType;
        restrictTags?: number[];
    };
    export function singleUseTime(payload: SingleUseTimeAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["phoneBoothId"] = `${payload["phoneBoothId"]}`;
        params["workdayType"] = `${payload["workdayType"]}`;
        params["minTimeRule"] = `${payload["minTimeRule"]}`;
        params["beginHour"] = `${payload["beginHour"]}`;
        params["endHour"] = `${payload["endHour"]}`;
        params["dateType"] = `${payload["dateType"]}`;
        params["floorPlanId"] = `${payload["floorPlanId"]}`;
        params["matchType"] = `${payload["matchType"]}`;
        if (payload["restrictTags"])
            params["restrictTags"] = payload["restrictTags"].map((item) => `${item}`);
        return request<ResponseSuccess<PhoneBoothUseTimeResult>>(`/api/phoneBoothAnalysis/-/useTime`, { method, data, params, skipErrorHandler });
    }
}
