/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { FaqItem, Fault, FeedbackItem, FeedbackToUpdate, MobileBannerItem, S8FaultItem, TicketItemMobile, TicketToAdd, TicketToAddMobile, TicketToUpdate, WandCFaultItem, } from "@ixam/apis/types/after_sale";
// @ts-ignore
import { Lang, RepairState, SortType } from "@ixam/apis/types/constant";
// @ts-ignore
import { Ticket } from "@ixam/apis/types/ticket";
export namespace AfterSale {
    export type GetFaultListAPIPayload = {
        lang?: Lang;
    };
    export function getFaultList(payload: GetFaultListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["lang"] = `${payload["lang"]}`;
        return request<ResponseSuccess<Fault[]>>(`/api/after_sale/fault_list`, { method, data, params, skipErrorHandler });
    }
    export type GetFeedbackListAPIPayload = {};
    export function getFeedbackList(payload: GetFeedbackListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<FeedbackItem[]>>(`/api/admin/feedback/list`, { method, data, params, skipErrorHandler });
    }
    export type UpdateFeedbackAPIPayload = {
        feedback: FeedbackToUpdate;
    };
    export function updateFeedback(payload: UpdateFeedbackAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["feedback"] = payload["feedback"];
        return request<ResponseSuccess<void>>(`/api/admin/feedback/update`, { method, data, params, skipErrorHandler });
    }
    export type GetQuestionListAPIPayload = {
        offset?: number;
        limit?: number;
        query?: string;
        region?: string[];
        state?: RepairState;
        sortBy?: string;
        sortType?: SortType;
    };
    export function getQuestionList(payload: GetQuestionListAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["offset"] = payload["offset"];
        data["limit"] = payload["limit"];
        data["query"] = payload["query"];
        data["region"] = payload["region"];
        data["state"] = payload["state"];
        data["sortBy"] = payload["sortBy"];
        data["sortType"] = payload["sortType"];
        return request<ResponseSuccess<{
            ticketList: Ticket[];
            total: number;
        }>>(`/api/admin/afterSales/list`, { method, data, params, skipErrorHandler });
    }
    export type AddAfterSalesAPIPayload = {
        ticket: TicketToAdd;
    };
    export function addAfterSales(payload: AddAfterSalesAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ticket"] = payload["ticket"];
        return request<ResponseSuccess<void>>(`/api/admin/afterSales/add`, { method, data, params, skipErrorHandler });
    }
    export type DeleteAfterSalesAPIPayload = {
        ticketIds: number[];
    };
    export function deleteAfterSales(payload: DeleteAfterSalesAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ticketIds"] = payload["ticketIds"];
        return request<ResponseSuccess<void>>(`/api/admin/afterSales/delete`, { method, data, params, skipErrorHandler });
    }
    export type UpdateAfterSalesAPIPayload = {
        ticket: TicketToUpdate;
        initApproval: boolean;
        pushReport: boolean;
    };
    export function updateAfterSales(payload: UpdateAfterSalesAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ticket"] = payload["ticket"];
        data["initApproval"] = payload["initApproval"];
        data["pushReport"] = payload["pushReport"];
        return request<ResponseSuccess<void>>(`/api/admin/afterSales/update`, { method, data, params, skipErrorHandler });
    }
    export type ChangeStatusAPIPayload = {
        ticketIds: number[];
        state: RepairState;
        initApproval: boolean;
        pushReport: boolean;
    };
    export function changeStatus(payload: ChangeStatusAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ticketIds"] = payload["ticketIds"];
        data["state"] = payload["state"];
        data["initApproval"] = payload["initApproval"];
        data["pushReport"] = payload["pushReport"];
        return request<ResponseSuccess<void>>(`/api/admin/afterSales/changeStatus`, { method, data, params, skipErrorHandler });
    }
    export type GetAfterSaleContactInfoAPIPayload = {};
    export function getAfterSaleContactInfo(payload: GetAfterSaleContactInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<{
            email: string;
        }>>(`/api/admin/afterSales/getContactInfo`, { method, data, params, skipErrorHandler });
    }
    export type UpdateAfterSaleContactInfoAPIPayload = {
        email: string;
    };
    export function updateAfterSaleContactInfo(payload: UpdateAfterSaleContactInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["email"] = payload["email"];
        return request<ResponseSuccess<void>>(`/api/admin/afterSales/updateContactInfo`, { method, data, params, skipErrorHandler });
    }
    export type GetTicketListMobileAPIPayload = {};
    export function getTicketListMobile(payload: GetTicketListMobileAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<TicketItemMobile[]>>(`/api/afterSales/list`, { method, data, params, skipErrorHandler });
    }
    export type UrgeTicketMobileAPIPayload = {
        ticketId: number;
    };
    export function urgeTicketMobile(payload: UrgeTicketMobileAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ticketId"] = payload["ticketId"];
        return request<ResponseSuccess<void>>(`/api/afterSales/urge`, { method, data, params, skipErrorHandler });
    }
    export type ResolveTicketMobileAPIPayload = {
        ticketId: number;
    };
    export function resolveTicketMobile(payload: ResolveTicketMobileAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ticketId"] = payload["ticketId"];
        return request<ResponseSuccess<void>>(`/api/after_sale/update`, { method, data, params, skipErrorHandler });
    }
    export type GetAfterSaleFaqListAPIPayload = {
        query?: string;
        lang?: Lang;
    };
    export function getAfterSaleFaqList(payload: GetAfterSaleFaqListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["query"] = `${payload["query"]}`;
        params["lang"] = `${payload["lang"]}`;
        return request<ResponseSuccess<FaqItem[]>>(`/api/after_sale/faq`, { method, data, params, skipErrorHandler });
    }
    export type GetAfterSaleBannerListAPIPayload = {
        lang?: Lang;
    };
    export function getAfterSaleBannerList(payload: GetAfterSaleBannerListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["lang"] = `${payload["lang"]}`;
        return request<ResponseSuccess<MobileBannerItem[]>>(`/api/after_sale/banner`, { method, data, params, skipErrorHandler });
    }
    export type AddTicketMobileAPIPayload = {
        ticket: TicketToAddMobile;
        token?: string;
    };
    export function addTicketMobile(payload: AddTicketMobileAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ticket"] = payload["ticket"];
        data["token"] = payload["token"];
        return request<ResponseSuccess<void>>(`/api/after_sale/repair`, { method, data, params, skipErrorHandler });
    }
    export type GetWandCFaultListAPIPayload = {
        lang?: Lang;
    };
    export function getWandCFaultList(payload: GetWandCFaultListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["lang"] = `${payload["lang"]}`;
        return request<ResponseSuccess<WandCFaultItem[]>>(`/api/after_sale/fault/wandc`, { method, data, params, skipErrorHandler });
    }
    export type GetS8FaultListAPIPayload = {
        lang?: Lang;
    };
    export function getS8FaultList(payload: GetS8FaultListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["lang"] = `${payload["lang"]}`;
        return request<ResponseSuccess<S8FaultItem[]>>(`/api/after_sale/fault/s8`, { method, data, params, skipErrorHandler });
    }
}
