// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import {
  BookResult,
  FloorPlanItem,
  RealtimeFloorPlan,
  Scope,
  SpaceSpotStatus,
  SpotBookItemOfFloorPlan,
  UserInfo,
} from '@ixam/apis/types';
import { RealtimeStatus, SpaceSpotBook, User } from '@/services';
import { getIntl } from '@umijs/max';
import { message } from 'antd';
import { useCallback, useState } from 'react';
import moment from 'moment';

export enum SpotTypeColor {
  ASSIGNED_PERSONAL = '#46ff4e',
  UNASSIGNED_PERSONAL = '#b0ffc8',
  BOOKED_SHARED = '#ff8700',
  UNBOOKED_SHARED = '#ffbd73',
}

const { getRealtimeFloorPlanList, getRealtimeStatus } = RealtimeStatus;
const { getSpaceSpotBookRecord, searchReversionDashboard } = SpaceSpotBook;

const { list } = User;

export type SpotBookItemOfFloorPlanList = SpotBookItemOfFloorPlan & { status: SpotTypeColor; bookItem?: BookResult };

export default () => {
  const [floorPlanList, setFloorPlanList] = useState<RealtimeFloorPlan[]>([]);
  const [floorPlanId, setFloorPlanId] = useState<RealtimeFloorPlan['id'] | null>(null);
  const [spotList, setSpotList] = useState<SpotBookItemOfFloorPlanList[]>([]);
  const [personList, setPersonList] = useState<UserInfo[]>([]);
  const [usedRate, setUsedRate] = useState<number>(0);

  const getSpotData = useCallback(async (payload: number | null) => {
    if (!payload) return;
    const data = (
      await getSpaceSpotBookRecord({
        floorPlanId: payload,
        startTime: moment().unix(),
        endTime: moment().unix(),
      })
    ).data;
    const spotList = [];
    for (let i = 0; i < data.length; i++) {
      let status: SpotTypeColor;
      let bookItem = undefined;
      if (data[i].isShared && data[i].bookList.length) {
        status = SpotTypeColor.BOOKED_SHARED;
        bookItem = data[i].bookList[0];
      } else if (data[i].isShared && !data[i].bookList.length) {
        status = SpotTypeColor.UNBOOKED_SHARED;
      } else if (!data[i].isShared && (data[i].assignee || data[i].employeeInfo)) {
        status = SpotTypeColor.ASSIGNED_PERSONAL;
      } else {
        status = SpotTypeColor.UNASSIGNED_PERSONAL;
      }

      spotList[i] = { ...data[i], status, bookItem };
    }
    setSpotList(spotList);
  }, []);

  const getRealtimeData = useCallback(async (payload: number | null) => {
    if (!payload) return;
    const response = await getRealtimeStatus({ id: payload });
    const spotList = response.data.resources;
    const usedNumber = spotList.filter((item) => item.status === SpaceSpotStatus.MANNED).length;
    const unusedNumber = spotList.filter((item) => item.status === SpaceSpotStatus.UNMANNED).length;
    const usedRate = Math.round((usedNumber * 100) / (usedNumber + unusedNumber || 1));
    setUsedRate(usedRate);
  }, []);

  const changeFloorPlan = useCallback(
    async (payload: number) => {
      await setFloorPlanId(payload);
      await getSpotData(payload);
      await getRealtimeData(payload);
    },
    [getRealtimeData, getSpotData]
  );

  const getFloorPlanList = useCallback(
    async (payload: any) => {
      const response = await getRealtimeFloorPlanList(payload);
      const list = response.data.floorPlanList;
      setFloorPlanList(list);
      if (list[0] && !floorPlanId) await changeFloorPlan(list[0].id);
    },
    [changeFloorPlan, floorPlanId]
  );

  const searchSpot = useCallback(
    async (payload: SpaceSpotBook.SearchReversionDashboardAPIPayload) => {
      if (!payload.assigneeId) return;
      const response = await searchReversionDashboard(payload);
      const list = response.data;
      if (!list.length) {
        return message.info(getIntl().formatMessage({ id: 'floor-plan.book-not-found-all' }));
      }
      const createFloorPlanList = (data: FloorPlanItem[]) => {
        return data
          .filter((v) => v.floorPlanId !== floorPlanId)
          .map((v) => v.floorPlanName)
          .join(getIntl().formatMessage({ id: 'floor-plan.book-point' }));
      };
      const showMessage = (key: string, data: FloorPlanItem[]) => {
        message.info(getIntl().formatMessage({ id: key }, { floorPlans: createFloorPlanList(data) }));
      };

      // 只在其他平面图找到了该点位
      if (!list.some((v) => v.floorPlanId === floorPlanId))
        return showMessage('floor-plan.book-not-found-current', list);
      // 只在当前平面图找到了该点位
      if (list.length === 1) {
        return message.info(getIntl().formatMessage({ id: 'floor-plan.book-found-current' }));
      }

      // 当前平面图和其他平面图都找到了该点位
      showMessage('floor-plan.book-found-all', list);
    },
    [floorPlanId]
  );

  const getPersonList = useCallback(async () => {
    const response = await list({ scope: Scope.PROJECT });
    setPersonList(response.data);
  }, []);

  return {
    floorPlanList,
    floorPlanId,
    spotList,
    usedRate,
    personList,
    getPersonList,
    searchSpot,
    getFloorPlanList,
    changeFloorPlan,
    getRealtimeData,
    getSpotData,
  };
};
