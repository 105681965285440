// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { Menu, UserLoginResult } from '@ixam/apis/types';

const RESERVED_KEYS = ['umi_locale'];

interface StorageAPI<T> {
  get: () => T | undefined;
  save: (data: T) => void;
  clear: () => void;
}

const clearStorage = () => {
  const data = RESERVED_KEYS.map((key) => localStorage.getItem(key));
  localStorage.clear();
  RESERVED_KEYS.forEach((key, index) => {
    const item = data[index];
    if (item !== null) localStorage.setItem(key, item);
  });
};

class Storage {
  private readonly USER_INFO = 'USER_INFO';
  private readonly SIDEBAR_LIST = 'SIDEBAR_LIST';

  private createAPI<T>(key: string): StorageAPI<T> {
    return {
      get: () => get(key),
      save: (data: T) => save(key, data),
      clear: () => clear(key),
    };
  }

  clearAll() {
    clearStorage();
  }

  get user() {
    return this.createAPI<UserLoginResult>(this.USER_INFO);
  }

  get sidebarList() {
    return this.createAPI<Menu[]>(this.SIDEBAR_LIST);
  }
}

export default new Storage();

function save(key: string, val: any) {
  let saveValue = val;
  if (typeof val === 'object') {
    saveValue = JSON.stringify(val);
  }
  localStorage.setItem(key, saveValue);
}

function get<T>(key: string): T | undefined {
  let strVal = localStorage.getItem(key);
  try {
    if (strVal) {
      return JSON.parse(strVal);
    }
  } catch (err) {}
  return;
}

function clear(key: string) {
  localStorage.removeItem(key);
}
