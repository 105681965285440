// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'inventory.number': '序号',
  'inventory.name': '名称',
  'inventory.url': '跳转URL',
  'inventory.add': '添加',
  'inventory.operation': '操作',
  'inventory.edit': '编辑',
  'inventory.delete': '删除',
  'inventory.top': '置顶',
  'inventory.add.inventory.item': '添加资源位',
  'inventory.edit.inventory.item': '编辑资源位',
  'inventory.icon': '图标',
  'inventory.icon.size': '建议图标为正方形，仅支持2MB以内png、jpg、jpeg文件。',
  'inventory.cn.name': '中文名称',
  'inventory.us.name': '英文名称',
  'inventory.upload.icon': '请上传图标',
  'inventory.enter.cn.name': '请填写中文名称',
  'inventory.enter.us.name': '请填写英文名称',
  'inventory.cn.characters': '建议不超过8个汉字',
  'inventory.us.letters': '建议不超过12个字符',
  'inventory.enter.CANTEENMENU': '今日菜单资源位：请输入“CANTEENMENU”',
  'inventory.enter.CLEANING': '一键保洁资源位：请输入“CLEANING”',
  'inventory.enter.REPAIRING': '一键报修资源位：请输入“REPAIRING”',
  'inventory.delete.confirm': '确定要删除该资源位吗？',
  'inventory.warning': '不能上传超过2MB的图片',
};
