// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { Dropdown } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { Lang } from '@ixam/apis/types';
import { getLocale, setLocale, useIntl } from '@umijs/max';
import React, { useCallback, useMemo } from 'react';
import styles from './index.less';

const SelectLang: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const { formatMessage } = useIntl();

  const handleMenuClick = useCallback(({ key }: { key: string }) => setLocale(key), []);

  const menu = useMemo(
    () => ({
      items: [
        { key: Lang.zh_cn, label: formatMessage({ id: 'all.lang.chinese' }) },
        { key: Lang.en_us, label: formatMessage({ id: 'all.lang.english' }) },
        { key: Lang.ko_kr, label: formatMessage({ id: 'all.lang.korean' }) },
      ],
      onClick: handleMenuClick,
      selectedKeys: [getLocale()],
    }),
    [formatMessage, handleMenuClick]
  );

  return (
    <Dropdown menu={menu} placement="bottomRight" overlayClassName={styles['switch-lang-dropdown']}>
      <GlobalOutlined className={styles['lang-icon']} style={style} />
    </Dropdown>
  );
};

export default SelectLang;
