/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { FileType } from "@ixam/apis/types/_types";
// @ts-ignore
import { GenerateTecDataType, TecProductCreate, TecProductItem, TecQrCodeData } from "@ixam/apis/types/tec-product";
// @ts-ignore
import TimeZone from "@ixam/apis/types/timezone";
export namespace TecProduct {
    export type GetDeviceDetailAPIPayload = {
        uuid: string;
    };
    export function getDeviceDetail(payload: GetDeviceDetailAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["uuid"] = `${payload["uuid"]}`;
        return request<ResponseSuccess<TecQrCodeData>>(`/api/product/info`, { method, data, params, skipErrorHandler });
    }
    export type DownloadSpotQrCodeBatchAPIPayload = {
        ids: string[];
        title?: string;
        subTitle?: string;
        hints?: string;
        logoUrl?: string;
        locale?: string;
        width?: number;
        height?: number;
    };
    export function downloadSpotQrCodeBatch(payload: DownloadSpotQrCodeBatchAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["title"] = payload["title"];
        data["subTitle"] = payload["subTitle"];
        data["hints"] = payload["hints"];
        data["logoUrl"] = payload["logoUrl"];
        data["locale"] = payload["locale"];
        data["width"] = payload["width"];
        data["height"] = payload["height"];
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/partner/tec_product/data_qrcodes_download`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type GenerateTecDataAPIPayload = {
        data: GenerateTecDataType[];
    };
    export function generateTecData(payload: GenerateTecDataAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/partner/tec_product/generate_data`, { method, data, params, skipErrorHandler });
    }
    export type GetTecProductListAPIPayload = {};
    export function getTecProductList(payload: GetTecProductListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<TecProductItem[]>>(`/api/partner/tec_product/list`, { method, data, params, skipErrorHandler });
    }
    export type DeleteTecProductAPIPayload = {
        ids: string[];
    };
    export function deleteTecProduct(payload: DeleteTecProductAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        return request<ResponseSuccess<void>>(`/api/partner/tec_product/delete`, { method, data, params, skipErrorHandler });
    }
    export type EditTecProductAPIPayload = {
        data: TecProductItem;
    };
    export function editTecProduct(payload: EditTecProductAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/partner/tec_product/edit`, { method, data, params, skipErrorHandler });
    }
    export type GenerateSingleTecDataAPIPayload = {
        data: TecProductCreate;
    };
    export function generateSingleTecData(payload: GenerateSingleTecDataAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/partner/tec_product/generate_single`, { method, data, params, skipErrorHandler });
    }
    export type ExportTecProductAPIPayload = {
        ids?: string[];
    };
    export function exportTecProduct(payload: ExportTecProductAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/partner/tec_product/export`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type DownloadTecProductUpdateTemplateAPIPayload = {
        ids: string[];
    };
    export function downloadTecProductUpdateTemplate(payload: DownloadTecProductUpdateTemplateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/partner/tec_product/get_template`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type UpdateTecProductByTemplateAPIPayload = {
        file: File;
        timeZone: TimeZone;
    };
    export function updateTecProductByTemplate(payload: UpdateTecProductByTemplateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data = new FormData();
        data.append("token", token);
        data.append("file", payload["file"]);
        data.append("timeZone", payload["timeZone"]);
        return request<ResponseSuccess<void>>(`/api/partner/tec_product/update_by_template`, { method, data, params, skipErrorHandler });
    }
    export type UpdateTecProductImageAPIPayload = {
        ids: string[];
        url: string;
    };
    export function updateTecProductImage(payload: UpdateTecProductImageAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["url"] = payload["url"];
        return request<ResponseSuccess<{
            total_count: number;
            success_count: number;
        }>>(`/api/partner/tec_product/updateProductImage`, { method, data, params, skipErrorHandler });
    }
}
