// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { TagType } from '@ixam/apis/types';
import errorList from '@ixam/apis/types/errors/external-error-messages';
import errors from '@ixam/apis/types/errors/external-errors';

export type ErrorType = keyof typeof errors;
export default { errorList, errors };

export const API_URL = window.IXAM_API_URL;
export const SPACE_URL = window.IXAM_SPACE_URL;
export const PARTNER_URL = window.IXAM_PARTNER_URL;
export const MOBILE_URL = window.IXAM_MOBILE_URL;
export const ADMIN_URL = window.IXAM_ADMIN_URL;
export const CDN_BASE_URL = window.CDN_BASE_URL;
export const CDN_ROOT_URL = window.CDN_ROOT_URL;
export const IS_ON_PREMISES = window.IS_ON_PREMISES;

export const DOWNLOAD_URL = `${CDN_ROOT_URL}/download/`;
export const IMAGE_URL = `${CDN_ROOT_URL}/image/`;
export const ICONFONT_URL = `${CDN_ROOT_URL}/iconfont/font_2004379_z82kt8brbe.js`;

export const DEFAULT_PAGESIZE_OPTIONS = ['15', '60', '100', '300'];
export const PASS_EXP: RegExp = /^[a-z_A-Z0-9-.!@#$%\\^&*)(+={}[\]/",'<>~·`?:;|]+$/;

/**
 * @description 标签类型列表
 */
export const TagTypeList = () => {
  return {
    department: {
      name: 'tag.department',
      tagType: TagType.DEPARTMENT,
    },
    area: {
      name: 'tag.area',
      tagType: TagType.AREA,
    },
    other: {
      name: 'tag.other',
      tagType: TagType.OTHER,
    },
  };
};
