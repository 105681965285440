/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { FileType } from "@ixam/apis/types/_types";
// @ts-ignore
import { FloorPlanInfo, FloorPlanItemPositionToAdd, FloorPlanListItem, UpdateFloorPlanResult, } from "@ixam/apis/types/partner-floor-plan";
// @ts-ignore
import { SensorDirection } from "@ixam/apis/types/sensor";
// @ts-ignore
import { SpotType, SubSpotType } from "@ixam/apis/types/spot";
export namespace PartnerFloorPlan {
    export type GetFloorPlanListAPIPayload = {
        companyId: number;
        query?: string;
    };
    export function getFloorPlanList(payload: GetFloorPlanListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["companyId"] = `${payload["companyId"]}`;
        params["query"] = `${payload["query"]}`;
        return request<ResponseSuccess<FloorPlanListItem[]>>(`/api/floorPlan/list`, { method, data, params, skipErrorHandler });
    }
    export type AddFloorPlanAPIPayload = {
        url: string;
        name: string;
        companyId: number;
        height: number;
        width: number;
        coverage?: string;
        area?: string;
        floor?: string;
        workplace?: string;
    };
    export function addFloorPlan(payload: AddFloorPlanAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["url"] = payload["url"];
        data["name"] = payload["name"];
        data["companyId"] = payload["companyId"];
        data["height"] = payload["height"];
        data["width"] = payload["width"];
        data["coverage"] = payload["coverage"];
        data["area"] = payload["area"];
        data["floor"] = payload["floor"];
        data["workplace"] = payload["workplace"];
        return request<ResponseSuccess<{
            id: number;
        }>>(`/api/floorPlan/add`, { method, data, params, skipErrorHandler });
    }
    export type EditFloorPlanAPIPayload = {
        id: number;
        name?: string;
        workplace?: string;
        floor?: string;
        area?: string;
        coverage?: string;
        resourceRadius?: number;
        url?: string;
    };
    export function editFloorPlan(payload: EditFloorPlanAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["id"] = payload["id"];
        data["name"] = payload["name"];
        data["workplace"] = payload["workplace"];
        data["floor"] = payload["floor"];
        data["area"] = payload["area"];
        data["coverage"] = payload["coverage"];
        data["resourceRadius"] = payload["resourceRadius"];
        data["url"] = payload["url"];
        return request<ResponseSuccess<void>>(`/api/floorPlan/update`, { method, data, params, skipErrorHandler });
    }
    export type DeleteFloorPlanAPIPayload = {
        id: number;
    };
    export function deleteFloorPlan(payload: DeleteFloorPlanAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["id"] = payload["id"];
        return request<ResponseSuccess<void>>(`/api/floorPlan/delete`, { method, data, params, skipErrorHandler });
    }
    export type GetFloorPlanInfoAPIPayload = {
        id: number;
    };
    export function getFloorPlanInfo(payload: GetFloorPlanInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["id"] = `${payload["id"]}`;
        return request<ResponseSuccess<FloorPlanInfo>>(`/api/floorPlan/info`, { method, data, params, skipErrorHandler });
    }
    export type AddFloorPlanSpotAPIPayload = {
        floorPlanId: number;
        spotList: {
            count?: number;
            positionX: number;
            positionY: number;
        }[];
        spotType: SpotType;
        spotSubtype?: SubSpotType;
    };
    export function addFloorPlanSpot(payload: AddFloorPlanSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["floorPlanId"] = payload["floorPlanId"];
        data["spotList"] = payload["spotList"];
        data["spotType"] = payload["spotType"];
        data["spotSubtype"] = payload["spotSubtype"];
        return request<ResponseSuccess<string[]>>(`/api/floorPlan/resource/add`, { method, data, params, skipErrorHandler });
    }
    export type DeleteFloorPlanResourceAPIPayload = {
        floorPlanId: number;
        resourceId: string;
    };
    export function deleteFloorPlanResource(payload: DeleteFloorPlanResourceAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["floorPlanId"] = payload["floorPlanId"];
        data["resourceId"] = payload["resourceId"];
        return request<ResponseSuccess<void>>(`/api/floorPlan/resource/delete`, { method, data, params, skipErrorHandler });
    }
    export type UpdateFloorPlanSpotAPIPayload = {
        floorPlanId: number;
        spotList: FloorPlanItemPositionToAdd[];
    };
    export function updateFloorPlanSpot(payload: UpdateFloorPlanSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["floorPlanId"] = payload["floorPlanId"];
        data["spotList"] = payload["spotList"];
        return request<ResponseSuccess<void>>(`/api/floorPlan/resource/update`, { method, data, params, skipErrorHandler });
    }
    export type UpdateFloorPlanResourceSensorsAPIPayload = {
        floorPlanId: number;
        resourceList: {
            id: string;
            sensors: {
                tag: string;
                sensorDirection?: SensorDirection;
            }[];
        }[];
    };
    export function updateFloorPlanResourceSensors(payload: UpdateFloorPlanResourceSensorsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["floorPlanId"] = payload["floorPlanId"];
        data["resourceList"] = payload["resourceList"];
        return request<ResponseSuccess<UpdateFloorPlanResult>>(`/api/floorPlan/resource/updateSensors`, { method, data, params, skipErrorHandler });
    }
    export type DownloadEditSensorsTemplateAPIPayload = {
        floorPlanId: number;
        companyId: number;
    };
    export function downloadEditSensorsTemplate(payload: DownloadEditSensorsTemplateAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["floorPlanId"] = `${payload["floorPlanId"]}`;
        params["companyId"] = `${payload["companyId"]}`;
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/floorPlan/resource/get_template`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type DeleteFloorPlanGatewayAPIPayload = {
        floorPlanId: number;
        gatewayId: string;
    };
    export function deleteFloorPlanGateway(payload: DeleteFloorPlanGatewayAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["floorPlanId"] = payload["floorPlanId"];
        data["gatewayId"] = payload["gatewayId"];
        return request<ResponseSuccess<void>>(`/api/floorPlan/gateway/delete`, { method, data, params, skipErrorHandler });
    }
    export type UpdateFloorPlanGatewayAPIPayload = {
        floorPlanId: number;
        gatewayList: FloorPlanItemPositionToAdd[];
    };
    export function updateFloorPlanGateway(payload: UpdateFloorPlanGatewayAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["floorPlanId"] = payload["floorPlanId"];
        data["gatewayList"] = payload["gatewayList"];
        return request<ResponseSuccess<void>>(`/api/floorPlan/gateway/update`, { method, data, params, skipErrorHandler });
    }
    export type DeleteFloorPlanRelayAPIPayload = {
        floorPlanId: number;
        relayId: string;
    };
    export function deleteFloorPlanRelay(payload: DeleteFloorPlanRelayAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["floorPlanId"] = payload["floorPlanId"];
        data["relayId"] = payload["relayId"];
        return request<ResponseSuccess<void>>(`/api/floorPlan/relay/delete`, { method, data, params, skipErrorHandler });
    }
    export type UpdateFloorPlanRelayAPIPayload = {
        floorPlanId: number;
        relayList: FloorPlanItemPositionToAdd[];
    };
    export function updateFloorPlanRelay(payload: UpdateFloorPlanRelayAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["floorPlanId"] = payload["floorPlanId"];
        data["relayList"] = payload["relayList"];
        return request<ResponseSuccess<void>>(`/api/floorPlan/relay/update`, { method, data, params, skipErrorHandler });
    }
    export type UpdateFloorPlanResourceHandsetAPIPayload = {
        floorPlanId: number;
        resourceList: {
            id: string;
            handsetId: string;
        }[];
    };
    export function updateFloorPlanResourceHandset(payload: UpdateFloorPlanResourceHandsetAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["floorPlanId"] = payload["floorPlanId"];
        data["resourceList"] = payload["resourceList"];
        return request<ResponseSuccess<UpdateFloorPlanResult>>(`/api/floorPlan/resource/updateHandset`, { method, data, params, skipErrorHandler });
    }
}
