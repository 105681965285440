// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'all.token.invalid': '아이디가 만료되었습니다. 다시 로그인하세요',
  'all.lang.chinese': '简体中文',
  'all.lang.english': 'English',
  'all.lang.korean': '한국어',
  'all.remarks': 'Remarks',
  'all.add-remarks': 'remarks',
  'all.serial.number': '일련 번호',
  'all.cancel': '취소',
  'all.save': '저장',
  'all.confirm': '확인',
  'all.reset': '재설정',
  'all.delete': 'Delete',
  'all.edit': 'Edit',
  'all.operating': 'Operate',
  'all.add': 'Add',
  'all.add.success': '추가 성공',
  // 字符限制
  'all.valid.max.long': '최대 길이 {count}',
  'all.valid.min.long': 'Min Length: {count}',
  'all.unit': '개',
  'all.list.number': 'ID',
  'all.online': 'Online',
  'all.offline': 'Offline',
  'all.status': 'Status',
  'all.occupy': 'Occupied',
  'all.vacant': 'Vacant',

  'all.upload-success': 'Upload Successfully',
  'all.uploadFail': 'Fail to upload image',
  'all.time': 'Time',
  'all.minute': '{Min, plural,one{Min} other {Mins}}',
  'all.hour': '{Hour, plural,one{Hour} other {Hours}}',
  'all.unknown': '알 수 없음',
};
