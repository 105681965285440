// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

const smartPower = {
  'space.smart-power.batch': 'Batch Operation',
  'space.smart-power.batch.on': 'Batch Power On',
  'space.smart-power.batch.off': 'Batch Power Off',
  'space.smart-power.batch.confirm-on': 'Are you sure to power on selected units?',
  'space.smart-power.batch.confirm-off': 'Are you sure to power off selected units?',
  'space.smart-power.batch.confirm-ok': 'Confirm',
  'space.smart-power.search-placeholder': 'Device ID / Spot ID',
  'space.smart-power.filter.overload': 'Power Overload',
  'space.smart-power.id': 'Device ID',
  'space.smart-power.network-status': 'Status',
  'space.smart-power.network-status.online': 'Online',
  'space.smart-power.network-status.offline': 'Offline',
  'space.smart-power.spot-id': 'Spot ID',
  'space.smart-power.current-power': 'Current Power',
  'space.smart-power.current-power.overload': 'Overload',
  'space.smart-power.switch-status': 'Switch',
  'space.smart-power.switch-status.on': 'Power On',
  'space.smart-power.switch-status.off': 'Power Off',
  'space.smart-power.remark.add': 'Add Remarks',
  'space.smart-power.subscribe': 'Subscribe Alert',
  'space.smart-power.subscribe.title': 'Subscribe Notification',
  'space.smart-power.subscribe.on-off-hint': 'The system will send device alerts via Email.',
  'space.smart-power.subscribe.on': 'Turn on alerts',
  'space.smart-power.subscribe.off': 'Turn off alerts',
  'space.smart-power.subscribe.receiver-hint': 'Select administrators to send notifications',
  'space.smart-power.total-device': 'Total Devices',
  'space.smart-power.current-power.normal': 'Normal',
  'space.smart-power.current-power.high': 'High',
  'space.smart-power.monitoring.spot-id': 'Spot ID',
  'space.smart-power.monitoring.network-status': 'Device Status',
  'space.smart-power.device-info': 'Device Info',
  'space.smart-power.no-devices': 'No Devices',

  'space.smart-power.overview': 'Overview',
  'space.smart-power.performance-indicatorspower-device': 'Performance indicators for power consumption of device',

  'space.smart-power.analysis.tips-name1': 'Total Power Consumption',
  'space.smart-power.analysis.tips-value1': 'Total energy used within statistical period.',
  'space.smart-power.analysis.tips-name2': 'Daily/Week-on-week/Month-on-month',
  'space.smart-power.analysis.tips-value2':
    'Compares power consumption growth of current period with its numbers for yesterday/last week/last month.',
  'space.smart-power.analysis.tips-name3': 'Peak Power Consumption',
  'space.smart-power.analysis.tips-value3': `By Day: Maximum electricity consumption in a given hour of the day
By Week: Maximum electricity consumption on a day of the week
By Month: Maximum electricity consumption on a day in a month`,
  'space.smart-power.analysis.tips-name4': 'Compare with Yesterday/Last Week/Last Month',
  'space.smart-power.analysis.tips-value4':
    'Compare growth of peak power consumption of current statistical period with last statistical period ',
  'space.smart-power.analysis.tips-name5': 'Workstations Average Daily Power Consumption',
  'space.smart-power.analysis.tips-value5':
    'Total power consumption in current statistical period/Number of workstations that have been used',
  'space.smart-power.analysis.tips-name6': 'Compare with Yesterday/Last Week/Last Month',
  'space.smart-power.analysis.tips-value6':
    'Compare the growth of workstations average daily power consumption of current statistical period with last statistical period  ',
  'space.smart-power.analysis.tips-name7': 'Number of Used Devices',
  'space.smart-power.analysis.tips-value7': 'Number of used devices within statistical period',
  'space.smart-power.analysis.tips-name8': 'Total Number of Devices',
  'space.smart-power.analysis.tips-value8': 'Total number of devices in the company',
  'space.smart-power.analysis.tips-name9': 'Updated',

  'space.smart-power.compare-yesterday': 'Compare with Yesterday',
  'space.smart-power.compare-last-week': 'Compare with Last Week',
  'space.smart-power.compare-last-month': 'Compare with Last Month',
  'space.smart-power.compare-daily': 'Daily',
  'space.smart-power.compare-week-on-week': 'Week-on-week',
  'space.smart-power.compare-month-on-month': 'Month-on-month',

  'space.smart-power.statistical-period': 'Statistical Period',
  'space.smart-power.realtime-date-statistical': 'Realtime Data Statistics Days',
  'space.smart-power.by-day': 'By Day',
  'space.smart-power.by-week': 'By Week',
  'space.smart-power.by-month': 'By Month',
  'space.smart-power.power-consumption-statistics': 'Power Consumption Statistics',
  'space.smart-power.power-used': 'Power Consumption',
  'space.smart-power.power-unit': 'kW⋅h',

  'space.smart-power.pick-period': 'Show historical data in past',
};

export default smartPower;
