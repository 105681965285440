/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BeginHour, DaysFilter, EndHour, MatchType, MinTimeRule, SortBy, SortType } from "@ixam/apis/types/constant";
// @ts-ignore
import { GetStatisticsResponseData, SedentaryRankItem, StandDistributionHourItem, StandDistributionItem, StandDistributionWeekItem, StandRankItem, TrendDataItem, } from "@ixam/apis/types/health_analysis";
export namespace HealthAnalysis {
    export type GetStatisticsAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function getStatistics(payload: GetStatisticsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<GetStatisticsResponseData>>(`/api/healthAnalysis/statistics`, { method, data, params, skipErrorHandler });
    }
    export type GetTrendDataAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function getTrendData(payload: GetTrendDataAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<TrendDataItem[]>>(`/api/healthAnalysis/trend`, { method, data, params, skipErrorHandler });
    }
    export type GetStandDistributionAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function getStandDistribution(payload: GetStandDistributionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<StandDistributionItem[]>>(`/api/healthAnalysis/standDistribution`, { method, data, params, skipErrorHandler });
    }
    export type GetStandDistributionWeekAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function getStandDistributionWeek(payload: GetStandDistributionWeekAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<StandDistributionWeekItem[]>>(`/api/healthAnalysis/standDistributionWeek`, { method, data, params, skipErrorHandler });
    }
    export type GetStandDistributionHourAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function getStandDistributionHour(payload: GetStandDistributionHourAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<StandDistributionHourItem[]>>(`/api/healthAnalysis/standDistributionHour`, { method, data, params, skipErrorHandler });
    }
    export type GetStandingRankAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        sortBy: SortBy;
        sortType: SortType;
        limit?: number;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function getStandingRank(payload: GetStandingRankAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["sortBy"] = payload["sortBy"];
        data["sortType"] = payload["sortType"];
        data["limit"] = payload["limit"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<StandRankItem[]>>(`/api/healthAnalysis/standingRank`, { method, data, params, skipErrorHandler });
    }
    export type GetSedentaryRankAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        sortType: SortType;
        limit?: number;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function getSedentaryRank(payload: GetSedentaryRankAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["sortType"] = payload["sortType"];
        data["limit"] = payload["limit"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<SedentaryRankItem[]>>(`/api/healthAnalysis/sedentaryRank`, { method, data, params, skipErrorHandler });
    }
}
