/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { LOG_MODULE, LOG_OP, OpLogItem } from "@ixam/apis/types/op-log";
// @ts-ignore
import { SortType } from "@ixam/apis/types/constant";
export namespace OpLog {
    export type GetOpLogAPIPayload = {
        companyId?: number;
        uid?: string;
        query?: string;
        offset?: number;
        limit?: number;
        beginDate?: string;
        endDate?: string;
        sortByDate?: SortType;
        type?: LOG_MODULE;
        operation?: LOG_OP;
    };
    export function getOpLog(payload: GetOpLogAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["companyId"] = `${payload["companyId"]}`;
        params["uid"] = `${payload["uid"]}`;
        params["query"] = `${payload["query"]}`;
        params["offset"] = `${payload["offset"]}`;
        params["limit"] = `${payload["limit"]}`;
        params["beginDate"] = `${payload["beginDate"]}`;
        params["endDate"] = `${payload["endDate"]}`;
        params["sortByDate"] = `${payload["sortByDate"]}`;
        params["type"] = `${payload["type"]}`;
        params["operation"] = `${payload["operation"]}`;
        return request<ResponseSuccess<{
            opLogList: OpLogItem[];
            count: number;
        }>>(`/api/partner/opLog`, { method, data, params, skipErrorHandler });
    }
}
