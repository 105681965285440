// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'sensor.spot': 'Spot ID',
  'sensor.in-one-day': 'In 1 day',
  'sensor.within-1-3-days': 'Within 1-3 days',
  'sensor.within-3-7-days': 'Within 3-7 days',
  'sensor.within-7-30-days': 'Within 7-30 days',
  'sensor.>30-days': '>30 days not connected',
  'sensor.list.last-time': 'Last Connected',
  'sensor.list.number-remark': 'Device ID / Spot ID / Remarks',
  'sensor.list.management': 'Device',
};
