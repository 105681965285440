/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { InventoryItem } from "@ixam/apis/types/screen-device-inventory";
export namespace ScreenDeviceInventory {
    export type GetSpaceInventoryListAPIPayload = {};
    export function getSpaceInventoryList(payload: GetSpaceInventoryListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<InventoryItem[]>>(`/api/screenInventorys`, { method, data, params, skipErrorHandler });
    }
    export type AddSpaceInventoryAPIPayload = {
        icon: string;
        cnName: string;
        enName: string;
        redirectUrl?: string | null;
    };
    export function addSpaceInventory(payload: AddSpaceInventoryAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["icon"] = payload["icon"];
        data["cnName"] = payload["cnName"];
        data["enName"] = payload["enName"];
        data["redirectUrl"] = payload["redirectUrl"];
        return request<ResponseSuccess<void>>(`/api/screenInventorys`, { method, data, params, skipErrorHandler });
    }
    export type TopSpaceInventoryAPIPayload = {
        id: number;
    };
    export function topSpaceInventory(payload: TopSpaceInventoryAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<void>>(`/api/screenInventorys/${payload.id}:top`, { method, data, params, skipErrorHandler });
    }
    export type EditSpaceInventoryAPIPayload = {
        id: number;
        icon: string;
        cnName: string;
        enName: string;
        redirectUrl?: string | null;
    };
    export function editSpaceInventory(payload: EditSpaceInventoryAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["icon"] = payload["icon"];
        data["cnName"] = payload["cnName"];
        data["enName"] = payload["enName"];
        data["redirectUrl"] = payload["redirectUrl"];
        return request<ResponseSuccess<void>>(`/api/screenInventorys/${payload.id}`, { method, data, params, skipErrorHandler });
    }
    export type DelSpaceInventoryAPIPayload = {
        id: number;
    };
    export function delSpaceInventory(payload: DelSpaceInventoryAPIPayload, skipErrorHandler?: boolean) {
        const method = "delete";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<void>>(`/api/screenInventorys/${payload.id}`, { method, data, params, skipErrorHandler });
    }
}
