// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { SpotSpaceAnalytics } from '@/services';
import { SpotSpaceAnalyticsData } from '@ixam/apis/types';
import { getIntl, getLocale } from '@umijs/max';
import { message } from 'antd';
import { useCallback, useState } from 'react';
import moment from 'moment';

const { list, get, update } = SpotSpaceAnalytics;

export default () => {
  const [data, setData] = useState<SpotSpaceAnalyticsData[]>([]);
  const getDataList = useCallback(async (payload: SpotSpaceAnalytics.ListAPIPayload) => {
    const response = await list({ ...payload });
    setData(response.data);
  }, []);

  const [statusData, setStatusData] = useState<SpotSpaceAnalyticsData>();
  const getDateStatus = useCallback(async (payload: SpotSpaceAnalytics.GetAPIPayload) => {
    const { formatMessage } = getIntl();
    const res = await get({ ...payload });
    if (!res.data.stat) {
      message.error(
        moment(payload.date)
          .locale(getLocale())
          .format(formatMessage({ id: 'data-marking.error-date-format' })) +
          ' ' +
          formatMessage({ id: 'data-marking.no-use' })
      );
      return;
    }
    setStatusData(res.data);
  }, []);

  const getDataMark = useCallback(async (payload: SpotSpaceAnalytics.UpdateAPIPayload) => {
    await update({ ...payload });
  }, []);

  return { data, getDataList, getDataMark, statusData, setStatusData, getDateStatus };
};
