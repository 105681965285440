// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { Lang, Menu, UserLoginResult } from '@ixam/apis/types';
import { MenuDataItem } from '@ant-design/pro-components';
import { RequestConfig, getIntl, getLocale, history } from '@umijs/max';
import { message } from 'antd';
import ErrorStackParser from 'error-stack-parser';
import G, { API_URL, ErrorType, ICONFONT_URL } from '@/global';
import GlobalFooter from '@/components/GlobalFooter';
import GlobalHeader from '@/components/GlobalHeader';
import React from 'react';
import _ from 'lodash';
import storage from '@/utils/storage';
import umiRequest from 'umi-request';
import type { RunTimeLayoutConfig } from '@umijs/max';

const isDev = process.env.NODE_ENV === 'development';
const LOGIN_PATH = '/user/login';
const RESET_PASSWORD_PATH = '/external/ResetPassword';
const RETRIEVE_PASSWORD_PATH = '/external/RetrievePassword';
// TODO 修改数据库中locale，修改空间分析的url
const formatMenuData = (menu: Menu[]): MenuDataItem[] =>
  menu
    .map((item) => ({ ...item, path: item.path === '/statistics' ? '/analysis' : item.path }))
    .map((item) => ({
      ...item,
      name: `menu${(item.path || '').replaceAll('/', '.')}`,
      locale: `menu${(item.path || '').replaceAll('/', '.')}`,
      icon: 'icon-' + item.icon,
      children: formatMenuData(item.children),
    }));
let logError: ((error: ErrorEvent) => void) | undefined = undefined;
const getLogError = (user?: UserLoginResult) => (error: ErrorEvent) => {
  if (isDev) return;
  const firstError = ErrorStackParser.parse(error.error)[0];
  if (!firstError) return;

  umiRequest('/error', {
    method: 'put',
    data: {
      message: error.message,
      fileName: _.last(firstError.fileName?.split('/')),
      line: firstError.lineNumber || 0,
      column: firstError.columnNumber || 0,
      url: window.location.href,
      menuName: document.title,
      uid: user?.uid || '',
      username: user?.username || '',
    },
  });
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  user?: UserLoginResult;
  sidebarList?: Menu[];
}> {
  const user = storage.user.get();
  const sidebarList = storage.sidebarList.get();
  if (logError) window.removeEventListener('error', logError);
  logError = getLogError(user);
  window.addEventListener('error', logError);
  const path = history.location.pathname;
  // 定义白名单路径
  const WHITELISTED_PATHS = new Set([LOGIN_PATH, RESET_PASSWORD_PATH, RETRIEVE_PASSWORD_PATH]);
  // TODO 如果不是登录页面，前往登录，后续可能有变化
  if (!WHITELISTED_PATHS.has(path) && !user) history.push(LOGIN_PATH);
  return { user, sidebarList };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  const { formatMessage } = getIntl();
  const path = history.location.pathname;
  const hideMenu = ['/project/switch', '/external/RetrievePassword', '/external/ResetPassword'].includes(path);
  return {
    menuRender: hideMenu ? false : undefined,
    menuDataRender: () => formatMenuData(initialState?.sidebarList || []),
    headerRender: () => <GlobalHeader />,
    footerRender: () => <GlobalFooter />,
    pageTitleRender: (props) => formatMessage({ id: `menu${(props.pathname || '').replaceAll('/', '.')}` }),
    layout: 'mix',
    contentWidth: 'Fluid',
    iconfontUrl: ICONFONT_URL,
  };
};

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request: RequestConfig = {
  timeout: 60000,
  baseURL: API_URL,
  errorConfig: {
    // 错误接收及处理
    errorHandler: (error: any, opts: any) => {
      if (opts?.skipErrorHandler) throw error;
      const code: ErrorType = error?.response?.data?.code || '';
      const locale: Lang = getLocale();
      // TODO: @ixam/apis 里有些错误缺少特定语言的错误信息，需要补充
      const localedMsg = (G.errorList[code] as any)?.[locale] || 'error';
      if (code === G.errors.TOKEN_INVALID) {
        // TODO 处理定制页面和分享页面的token过期情况
        storage.clearAll();
        history.push('/user/login');
        message.error(getIntl().formatMessage({ id: 'all.token.invalid' }));
        return;
      }
      message.error(localedMsg);
    },
  },
};
