/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { AddFirmwareItem, AddHardwareItem, AddSnVersionItem, AddTypeOfDevice, EditSnVersionItem, FirmwareItem, FirmwareResult, HardwareVersionItem, SnVersionItem, TypeOfDevice, } from "@ixam/apis/types/firmware";
export namespace Firmware {
    export type GetFirmwareListAPIPayload = {};
    export function getFirmwareList(payload: GetFirmwareListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<FirmwareResult>>(`/api/admin/firmware/list`, { method, data, params, skipErrorHandler });
    }
    export type AddFirmwareAPIPayload = {
        firmware: AddFirmwareItem;
    };
    export function addFirmware(payload: AddFirmwareAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["firmware"] = payload["firmware"];
        return request<ResponseSuccess<void>>(`/api/admin/firmware/add`, { method, data, params, skipErrorHandler });
    }
    export type EditFirmwareAPIPayload = {
        firmware: FirmwareItem;
    };
    export function editFirmware(payload: EditFirmwareAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["firmware"] = payload["firmware"];
        return request<ResponseSuccess<void>>(`/api/admin/firmware/edit`, { method, data, params, skipErrorHandler });
    }
    export type GetDeviceTypeAPIPayload = {};
    export function getDeviceType(payload: GetDeviceTypeAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<TypeOfDevice[]>>(`/api/admin/deviceType/list`, { method, data, params, skipErrorHandler });
    }
    export type AddDeviceTypeAPIPayload = {
        data: AddTypeOfDevice;
    };
    export function addDeviceType(payload: AddDeviceTypeAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/admin/deviceType/add`, { method, data, params, skipErrorHandler });
    }
    export type EditDeviceTypeAPIPayload = {
        data: TypeOfDevice;
    };
    export function editDeviceType(payload: EditDeviceTypeAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/admin/deviceType/edit`, { method, data, params, skipErrorHandler });
    }
    export type DeleteDeviceTypeAPIPayload = {
        id: number;
    };
    export function deleteDeviceType(payload: DeleteDeviceTypeAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["id"] = payload["id"];
        return request<ResponseSuccess<void>>(`/api/admin/deviceType/delete`, { method, data, params, skipErrorHandler });
    }
    export type GetHardwareVersionAPIPayload = {};
    export function getHardwareVersion(payload: GetHardwareVersionAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<HardwareVersionItem[]>>(`/api/admin/hardwareVersion/list`, { method, data, params, skipErrorHandler });
    }
    export type AddHardwareVersionAPIPayload = {
        data: AddHardwareItem;
    };
    export function addHardwareVersion(payload: AddHardwareVersionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/admin/hardwareVersion/add`, { method, data, params, skipErrorHandler });
    }
    export type DeleteHardwareVersionAPIPayload = {
        id: number;
    };
    export function deleteHardwareVersion(payload: DeleteHardwareVersionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["id"] = payload["id"];
        return request<ResponseSuccess<void>>(`/api/admin/hardwareVersion/delete`, { method, data, params, skipErrorHandler });
    }
    export type GetSnVersionAPIPayload = {};
    export function getSnVersion(payload: GetSnVersionAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<SnVersionItem[]>>(`/api/admin/sn/list`, { method, data, params, skipErrorHandler });
    }
    export type AddSnVersionAPIPayload = {
        data: AddSnVersionItem;
    };
    export function addSnVersion(payload: AddSnVersionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/admin/sn/add`, { method, data, params, skipErrorHandler });
    }
    export type EditSnVersionAPIPayload = {
        data: EditSnVersionItem;
    };
    export function editSnVersion(payload: EditSnVersionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/admin/sn/edit`, { method, data, params, skipErrorHandler });
    }
}
