// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/root/src/models/common';
import model_2 from '/root/src/models/upload';
import model_3 from '/root/src/pages/Device/Sensor/model';
import model_4 from '/root/src/pages/Login/model';
import model_5 from '/root/src/pages/Management/Inventory/model';
import model_6 from '/root/src/pages/Management/SmartPower/model';
import model_7 from '/root/src/pages/Management/TagManagement/model';
import model_8 from '/root/src/pages/Password/ChangePassword/model';
import model_9 from '/root/src/pages/Password/RetrievePassword/model';
import model_10 from '/root/src/pages/Statistics/DataMarking/model';
import model_11 from '/root/src/pages/Statistics/SpotRealtimeStatus/model';
import model_12 from '/root/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'common', model: model_1 },
model_2: { namespace: 'upload', model: model_2 },
model_3: { namespace: 'Device.Sensor.model', model: model_3 },
model_4: { namespace: 'Login.model', model: model_4 },
model_5: { namespace: 'Management.Inventory.model', model: model_5 },
model_6: { namespace: 'Management.SmartPower.model', model: model_6 },
model_7: { namespace: 'Management.TagManagement.model', model: model_7 },
model_8: { namespace: 'Password.ChangePassword.model', model: model_8 },
model_9: { namespace: 'Password.RetrievePassword.model', model: model_9 },
model_10: { namespace: 'Statistics.DataMarking.model', model: model_10 },
model_11: { namespace: 'Statistics.SpotRealtimeStatus.model', model: model_11 },
model_12: { namespace: '@@initialState', model: model_12 },
} as const
