// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { MatchType, SmartPower, SmartPowerStatOverview, StatisticsType } from '@ixam/apis/types';
import { SmartPowerAnalysis, SvcSmartPower } from '@/services';
import { SmartPowerGetSubscriptionsResultResultScopedOfUser } from '@ixam/apis/types/_scoped/_return/smart-power';
import { useCallback, useState } from 'react';

const { getSmartPowerOverview, getSmartPowerStatistics } = SmartPowerAnalysis;
const { setSubscriptions, edit, bulkSetSwitchStatus, list, getSubscriptions } = SvcSmartPower;

export const PowerOverloadValue = 1500;

export type FilterOptions = {
  statPeriod: StatisticsType;
  floorPlanId?: number;
  restrictTags?: number[];
  matchType?: MatchType;
  somedayInPeriod: string;
};

export type SmartPowerOverview = {
  statPeriod: StatisticsType;
  somedayInPeriod: string;
} & SmartPowerStatOverview;

export type SmartPowerStatisticsType = {
  statPeriod: StatisticsType;
  somedayInPeriod: string;
  data: { [key: string]: number };
};

export type SmartPowerSubscription = SmartPowerGetSubscriptionsResultResultScopedOfUser;

const getDecimals = (data: string) => {
  if (data !== '--') return Number((Number(data) * 100).toFixed(1)).toString();
  else return data;
};

export default () => {
  const [smartPowerLoading, setSmartPowerLoading] = useState<boolean>(false);
  const [smartPowerList, setSmartPowerList] = useState<SmartPower[]>([]);
  const getSmartPowerList = useCallback(async (data: SvcSmartPower.ListAPIPayload) => {
    try {
      setSmartPowerLoading(true);
      const response = await list(data);
      setSmartPowerList(response.data);
    } finally {
      setSmartPowerLoading(false);
    }
  }, []);

  const [subscribeInfo, setSubscribeInfo] = useState<SmartPowerSubscription>({ on: false, receivers: [] });
  const getSmartPowerSubscribeInfo = useCallback(async (data: SvcSmartPower.GetSubscriptionsAPIPayload) => {
    const response = await getSubscriptions(data);
    setSubscribeInfo(response.data);
  }, []);
  const updateSmartPowerSubscribeInfo = useCallback(
    async (data: SvcSmartPower.SetSubscriptionsAPIPayload) => {
      await setSubscriptions(data);
      await getSmartPowerSubscribeInfo({});
    },
    [getSmartPowerSubscribeInfo]
  );
  const editRemark = useCallback(
    async (data: SvcSmartPower.EditAPIPayload) => {
      try {
        setSmartPowerLoading(true);
        await edit(data);
        await getSmartPowerList({});
      } finally {
        setSmartPowerLoading(false);
      }
    },
    [getSmartPowerList]
  );
  const bulkSetSwitch = useCallback(
    async (data: SvcSmartPower.BulkSetSwitchStatusAPIPayload) => {
      try {
        setSmartPowerLoading(true);
        await bulkSetSwitchStatus(data);
        await getSmartPowerList({});
      } finally {
        setSmartPowerLoading(false);
      }
    },
    [getSmartPowerList]
  );

  const [smartOverview, setSmartOverview] = useState<SmartPowerOverview>({
    statPeriod: StatisticsType.WEEKLY,
    somedayInPeriod: '',
    totalPowerConsumption: { value: 0, lastPeriodCompare: '0' }, // 总用电量
    peakPowerConsumption: { value: 0, lastPeriodCompare: '0' }, // 高峰用电量
    resourceAvaPowerConsumption: { value: 0, lastPeriodCompare: '0' }, // 工位平均用电量
    amountOfUsedSmartPower: 0, // 使用过的设备数
    amountOfSmartPower: 0, // 设备总数
    updatedAt: '0', // 更新日期
    statisticsDateFrom: new Date().toString(), // 统计开始时间，'YYYY-MM-DD'
    statisticsDateTo: new Date().toString(), // 统计结束时间，'YYYY-MM-DD'
    statisticsDays: 0, // 实时数据统计天数
  });
  const getOverview = useCallback(
    async (data: SmartPowerAnalysis.GetSmartPowerOverviewAPIPayload) => {
      const response = (await getSmartPowerOverview(data)).data;
      response.totalPowerConsumption.lastPeriodCompare = getDecimals(response.totalPowerConsumption.lastPeriodCompare);
      response.peakPowerConsumption.lastPeriodCompare = getDecimals(response.peakPowerConsumption.lastPeriodCompare);
      response.resourceAvaPowerConsumption.lastPeriodCompare = getDecimals(
        response.resourceAvaPowerConsumption.lastPeriodCompare
      );
      setSmartOverview({
        ...smartOverview,
        ...response,
        statPeriod: data.statPeriod,
        somedayInPeriod: data.somedayInPeriod,
      });
    },
    [smartOverview]
  );

  const [smartPowerStatistics, setSmartPowerStatistics] = useState<SmartPowerStatisticsType>({
    statPeriod: StatisticsType.WEEKLY,
    somedayInPeriod: '',
    data: {},
  });

  const getPowerStatistics = useCallback(
    async (data: SmartPowerAnalysis.GetSmartPowerStatisticsAPIPayload) => {
      const response = await getSmartPowerStatistics(data);
      setSmartPowerStatistics({
        ...smartPowerStatistics,
        ...response.data,
        statPeriod: data.statPeriod,
        somedayInPeriod: data.somedayInPeriod,
      });
    },
    [smartPowerStatistics]
  );

  const [filterOptions, setFilterOptions] = useState<FilterOptions>();
  const saveFilterOptions = useCallback(
    async (data: FilterOptions) => {
      setFilterOptions({ ...filterOptions, ...data });
    },
    [filterOptions]
  );

  return {
    smartPowerLoading,
    smartPowerList,
    getSmartPowerList,
    subscribeInfo,
    getSmartPowerSubscribeInfo,
    updateSmartPowerSubscribeInfo,
    editRemark,
    bulkSetSwitch,
    smartOverview,
    getOverview,
    smartPowerStatistics,
    getPowerStatistics,
    filterOptions,
    saveFilterOptions,
  };
};
