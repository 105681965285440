/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { RealtimeBookedInfo, RealtimeData, RealtimeFloorPlan, RealtimeFloorPlanListItem, RealtimeGatewayData, RealtimeRelayData, RealtimeStatusAndBookedStatus, } from "@ixam/apis/types/realtime-status";
export namespace RealtimeStatus {
    export type GetRealtimeFloorPlanListAPIPayload = {
        companyId?: number;
    };
    export function getRealtimeFloorPlanList(payload: GetRealtimeFloorPlanListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["companyId"] = `${payload["companyId"]}`;
        return request<ResponseSuccess<{
            floorPlanList: RealtimeFloorPlan[];
        }>>(`/api/realtime_status/list`, { method, data, params, skipErrorHandler });
    }
    export type GetRealtimeStatusAPIPayload = {
        id: number;
        companyId?: number;
    };
    export function getRealtimeStatus(payload: GetRealtimeStatusAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["id"] = `${payload["id"]}`;
        params["companyId"] = `${payload["companyId"]}`;
        return request<ResponseSuccess<{
            resources: RealtimeData[];
            gateways: RealtimeGatewayData[];
            relays: RealtimeRelayData[];
        }>>(`/api/realtime_status/data`, { method, data, params, skipErrorHandler });
    }
    export type RealtimeStatusSearchSpotAPIPayload = {
        query: string;
        companyId?: number;
    };
    export function realtimeStatusSearchSpot(payload: RealtimeStatusSearchSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["query"] = `${payload["query"]}`;
        params["companyId"] = `${payload["companyId"]}`;
        return request<ResponseSuccess<RealtimeFloorPlanListItem[]>>(`/api/realtime_status/search_spot`, { method, data, params, skipErrorHandler });
    }
    export type RealtimeStatusBulkSearchSpotAPIPayload = {
        query: string[];
        floorPlanId: number;
    };
    export function realtimeStatusBulkSearchSpot(payload: RealtimeStatusBulkSearchSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["query"] = payload["query"];
        data["floorPlanId"] = payload["floorPlanId"];
        return request<ResponseSuccess<{
            currentFloorPlanCount: number;
            otherFloorPlanCount: number;
            notFoundCount: number;
        }>>(`/api/realtime_status/bulk_search_spot`, { method, data, params, skipErrorHandler });
    }
    export type RealtimeStatusBulkEditSpotAPIPayload = {
        spotList: {
            id: string;
            name: string;
        }[];
        floorPlanId: number;
    };
    export function realtimeStatusBulkEditSpot(payload: RealtimeStatusBulkEditSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["spotList"] = payload["spotList"];
        data["floorPlanId"] = payload["floorPlanId"];
        return request<ResponseSuccess<void>>(`/api/realtime_status/bulk_edit_spot`, { method, data, params, skipErrorHandler });
    }
    export type GetSpotRealtimeStatusAndBookedStatusAPIPayload = {
        floorPlanId: number;
        startTime: number;
        endTime: number;
    };
    export function getSpotRealtimeStatusAndBookedStatus(payload: GetSpotRealtimeStatusAndBookedStatusAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["floorPlanId"] = `${payload["floorPlanId"]}`;
        params["startTime"] = `${payload["startTime"]}`;
        params["endTime"] = `${payload["endTime"]}`;
        return request<ResponseSuccess<{
            resources: RealtimeStatusAndBookedStatus[];
            bookedInfo: RealtimeBookedInfo;
        }>>(`/api/realtime_status/realtimeAndBooked`, { method, data, params, skipErrorHandler });
    }
}
