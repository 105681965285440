/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { AftersaleWechatLoginResult, CaptchaResponseData, LoginResult, Menu, MobileLoginPayload, MobileLoginResult, ShareLoginResponse, } from "@ixam/apis/types/login";
// @ts-ignore
import { UserRole } from "@ixam/apis/types/perms";
export namespace Login {
    export type GetLoginKeyAPIPayload = {};
    export function getLoginKey(payload: GetLoginKeyAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<{
            key: string;
        }>>(`/api/users/login_key`, { method, data, params, skipErrorHandler });
    }
    export type LoginAPIPayload = {
        userAccount: string;
        password: string;
        role?: UserRole;
        encrypted?: boolean;
        phonePrefix?: string;
        code?: string;
        captchaId?: string;
    };
    export function login(payload: LoginAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["userAccount"] = payload["userAccount"];
        data["password"] = payload["password"];
        data["role"] = payload["role"];
        data["encrypted"] = payload["encrypted"];
        data["phonePrefix"] = payload["phonePrefix"];
        data["code"] = payload["code"];
        data["captchaId"] = payload["captchaId"];
        return request<ResponseSuccess<LoginResult>>(`/api/users/login`, { method, data, params, skipErrorHandler });
    }
    export type MobileLoginAPIPayload = {
        payload: MobileLoginPayload;
        code: number;
    };
    export function mobileLogin(payload: MobileLoginAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["payload"] = payload["payload"];
        data["code"] = payload["code"];
        return request<ResponseSuccess<LoginResult>>(`/api/users/mobile/login`, { method, data, params, skipErrorHandler });
    }
    export type WechatLoginAPIPayload = {
        auth_code: string;
    };
    export function wechatLogin(payload: WechatLoginAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["auth_code"] = payload["auth_code"];
        return request<ResponseSuccess<(LoginResult & {
            isBindUser: true;
        }) | {
            token: string;
            isBindUser: false;
        }>>(`/api/users/wechat/login`, { method, data, params, skipErrorHandler });
    }
    export type BindUserAPIPayload = {
        token: string;
        userAccount: string;
        phonePrefix: string | null | undefined;
        code: number;
    };
    export function bindUser(payload: BindUserAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["token"] = payload["token"];
        data["userAccount"] = payload["userAccount"];
        data["phonePrefix"] = payload["phonePrefix"];
        data["code"] = payload["code"];
        return request<ResponseSuccess<LoginResult>>(`/api/users/wechat/bind`, { method, data, params, skipErrorHandler });
    }
    export type LogoutAPIPayload = {};
    export function logout(payload: LogoutAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<void>>(`/api/users/logout`, { method, data, params, skipErrorHandler });
    }
    export type GetCaptchaAPIPayload = {};
    export function getCaptcha(payload: GetCaptchaAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<CaptchaResponseData>>(`/api/users/security/code`, { method, data, params, skipErrorHandler });
    }
    export type ChangeTokenAPIPayload = {
        platform: UserRole;
        token: string;
    };
    export function changeToken(payload: ChangeTokenAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["platform"] = payload["platform"];
        data["token"] = payload["token"];
        return request<ResponseSuccess<LoginResult>>(`/api/change/token`, { method, data, params, skipErrorHandler });
    }
    export type GetSidebarListAPIPayload = {};
    export function getSidebarList(payload: GetSidebarListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<Menu[]>>(`/api/sidebar/list`, { method, data, params, skipErrorHandler });
    }
    export type ShareLoginAPIPayload = {
        id: string;
    };
    export function shareLogin(payload: ShareLoginAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["id"] = payload["id"];
        return request<ResponseSuccess<ShareLoginResponse>>(`/api/share/login`, { method, data, params, skipErrorHandler });
    }
    export type AftersaleLoginAPIPayload = {
        auth_code: string;
    };
    export function aftersaleLogin(payload: AftersaleLoginAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["auth_code"] = payload["auth_code"];
        return request<ResponseSuccess<AftersaleWechatLoginResult>>(`/api/after_sale/wechat_login`, { method, data, params, skipErrorHandler });
    }
    export type LoginWithTeamsAPIPayload = {
        code: string;
    };
    export function loginWithTeams(payload: LoginWithTeamsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["code"] = payload["code"];
        return request<ResponseSuccess<LoginResult>>(`/api/users/loginWithTeams`, { method, data, params, skipErrorHandler });
    }
    export type UpdateUserShowTeamsNoticeAPIPayload = {};
    export function updateUserShowTeamsNotice(payload: UpdateUserShowTeamsNoticeAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<void>>(`/api/users/showTeamsNotice`, { method, data, params, skipErrorHandler });
    }
    export type ReservationMobileLoginAPIPayload = {
        payload: MobileLoginPayload;
        code: number;
    };
    export function reservationMobileLogin(payload: ReservationMobileLoginAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["payload"] = payload["payload"];
        data["code"] = payload["code"];
        return request<ResponseSuccess<MobileLoginResult>>(`/api/users/reservation/mobileLogin`, { method, data, params, skipErrorHandler });
    }
    export type WeComLoginAPIPayload = {
        code: string;
    };
    export function weComLogin(payload: WeComLoginAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["code"] = payload["code"];
        return request<ResponseSuccess<{
            loginResult: MobileLoginResult;
            isBindUser: true;
        } | {
            token: string;
            isBindUser: false;
        }>>(`/api/users/weCom/login`, { method, data, params, skipErrorHandler });
    }
    export type WeComBindUserAPIPayload = {
        token: string;
        code: number;
        userAccount: string;
        phonePrefix: string | null | undefined;
    };
    export function weComBindUser(payload: WeComBindUserAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["token"] = payload["token"];
        data["code"] = payload["code"];
        data["userAccount"] = payload["userAccount"];
        data["phonePrefix"] = payload["phonePrefix"];
        return request<ResponseSuccess<MobileLoginResult>>(`/api/users/weCom/bind`, { method, data, params, skipErrorHandler });
    }
    export type UpdateTokenAPIPayload = {};
    export function updateToken(payload: UpdateTokenAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<MobileLoginResult>>(`/api/users/updateToken`, { method, data, params, skipErrorHandler });
    }
    export type DingTalkLoginAPIPayload = {
        code: string;
    };
    export function dingTalkLogin(payload: DingTalkLoginAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["code"] = payload["code"];
        return request<ResponseSuccess<{
            loginResult: MobileLoginResult;
            isBindUser: true;
        } | {
            token: string;
            isBindUser: false;
        }>>(`/api/users/dingTalk/login`, { method, data, params, skipErrorHandler });
    }
    export type DingTalkBindUserAPIPayload = {
        token: string;
        code: number;
        userAccount: string;
        phonePrefix: string | null | undefined;
    };
    export function dingTalkBindUser(payload: DingTalkBindUserAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["token"] = payload["token"];
        data["code"] = payload["code"];
        data["userAccount"] = payload["userAccount"];
        data["phonePrefix"] = payload["phonePrefix"];
        return request<ResponseSuccess<MobileLoginResult>>(`/api/users/dingTalk/bind`, { method, data, params, skipErrorHandler });
    }
}
