/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { AdminEditRelayItem, PartnerEditRelayItem, PartnerRelayInfo, RelayInfo } from "@ixam/apis/types/relay";
export namespace Relay {
    export type GetRelayListAPIPayload = {};
    export function getRelayList(payload: GetRelayListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<RelayInfo[]>>(`/api/relay/list`, { method, data, params, skipErrorHandler });
    }
    export type AddRelayAPIPayload = {
        data: AdminEditRelayItem;
    };
    export function addRelay(payload: AddRelayAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/relay/add`, { method, data, params, skipErrorHandler });
    }
    export type EditRelayAPIPayload = {
        data: AdminEditRelayItem;
    };
    export function editRelay(payload: EditRelayAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/relay/edit`, { method, data, params, skipErrorHandler });
    }
    export type DeleteRelayAPIPayload = {
        id: string;
    };
    export function deleteRelay(payload: DeleteRelayAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["id"] = payload["id"];
        return request<ResponseSuccess<void>>(`/api/relay/delete`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerRelayListAPIPayload = {};
    export function getPartnerRelayList(payload: GetPartnerRelayListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<PartnerRelayInfo[]>>(`/api/partner/relay/list`, { method, data, params, skipErrorHandler });
    }
    export type EditPartnerRelayAPIPayload = {
        data: PartnerEditRelayItem;
    };
    export function editPartnerRelay(payload: EditPartnerRelayAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/partner/relay/edit`, { method, data, params, skipErrorHandler });
    }
}
