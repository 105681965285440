// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 选择项目
  'switch-project.description':
    'This account exists in multiple projects at the same time, you can choose the project you need to enter.',
  'switch-project.all': 'All Projects',
  'switch-project.ongoing': 'Ongoing Projects',
  'switch-project.paused': 'Paused Projects',
  // Header
  'header.menu.account-info': 'AccountInfo',
  'header.menu.switch-company': 'Switch Project',
  'header.menu.to-partner': 'Partner Portal',
  'header.menu.to-admin': 'Admin Portal',
  'header.menu.change-password': 'Change Password',
  'header.menu.logout': 'Log Out',
};
