/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { FloorPlanItem } from "@ixam/apis/types/floor-plan";
// @ts-ignore
import { FreeBookDashboardSpotData, FreeBookSubscription, FreeSpotBookReturnItem, SingleSpotBookResult, } from "@ixam/apis/types/spot-book-free-version";
export namespace SpotBookFreeVersion {
    export type GetSingleSpotBookRecordAPIPayload = {
        uuid: string;
    };
    export function getSingleSpotBookRecord(payload: GetSingleSpotBookRecordAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["uuid"] = `${payload["uuid"]}`;
        return request<ResponseSuccess<SingleSpotBookResult>>(`/api/freeVersion/spot/info`, { method, data, params, skipErrorHandler });
    }
    export type BookSpotFreeAPIPayload = {
        id: string;
        uuid: string;
        userName: string;
        startTime: number;
        endTime: number;
    };
    export function bookSpotFree(payload: BookSpotFreeAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["id"] = payload["id"];
        data["uuid"] = payload["uuid"];
        data["userName"] = payload["userName"];
        data["startTime"] = payload["startTime"];
        data["endTime"] = payload["endTime"];
        return request<ResponseSuccess<FreeSpotBookReturnItem>>(`/api/freeVersion/spot/book`, { method, data, params, skipErrorHandler });
    }
    export type SpotBookGetWechatNicknameAPIPayload = {
        auth_code: string;
    };
    export function spotBookGetWechatNickname(payload: SpotBookGetWechatNicknameAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["auth_code"] = payload["auth_code"];
        return request<ResponseSuccess<{
            nickname: string;
        }>>(`/api/freeVersion/spot/wechat_nickname`, { method, data, params, skipErrorHandler });
    }
    export type GetFreeBookDashboardDataAPIPayload = {
        id: number;
    };
    export function getFreeBookDashboardData(payload: GetFreeBookDashboardDataAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["id"] = `${payload["id"]}`;
        return request<ResponseSuccess<FreeBookDashboardSpotData[]>>(`/api/space/freeBookDashboard/data`, { method, data, params, skipErrorHandler });
    }
    export type FreeBookDashboardSearchSpotAPIPayload = {
        query: string;
    };
    export function freeBookDashboardSearchSpot(payload: FreeBookDashboardSearchSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["query"] = `${payload["query"]}`;
        return request<ResponseSuccess<FloorPlanItem[]>>(`/api/space/freeBookDashboard/search_spot`, { method, data, params, skipErrorHandler });
    }
    export type GetFreeBookSubscriptionsAPIPayload = {};
    export function getFreeBookSubscriptions(payload: GetFreeBookSubscriptionsAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<FreeBookSubscription>>(`/api/space/freeBookDashboard/subscribe`, { method, data, params, skipErrorHandler });
    }
    export type UpdateFreeBookSubscriptionAPIPayload = {
        receivers: string[];
        subscribed: boolean;
    };
    export function updateFreeBookSubscription(payload: UpdateFreeBookSubscriptionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["receivers"] = payload["receivers"];
        data["subscribed"] = payload["subscribed"];
        return request<ResponseSuccess<void>>(`/api/space/freeBookDashboard/subscribe`, { method, data, params, skipErrorHandler });
    }
    export type GetBookMonthlyReportAPIPayload = {
        id: string;
    };
    export function getBookMonthlyReport(payload: GetBookMonthlyReportAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["id"] = `${payload["id"]}`;
        return request<ResponseSuccess<{
            data: {
                day: number;
                times: number;
                duration: number;
            }[];
            date: string;
        }>>(`/api/space/freeBookDashboard/monthlyReport`, { method, data, params, skipErrorHandler });
    }
}
