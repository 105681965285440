/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { EInkBaseStationItem, EInkTemplate, IEInkBaseStation } from "@ixam/apis/types/e-ink-base-station";
// @ts-ignore
import { PartnerPerms, UserRole } from "@ixam/apis/types/perms";
export namespace EInkBaseStation {
    export type GetEInkBaseStationListAPIPayload = {};
    export function getEInkBaseStationList(payload: GetEInkBaseStationListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<EInkBaseStationItem[]>>(`/api/admin/eInkBaseStation/list`, { method, data, params, skipErrorHandler });
    }
    export type AddEInkBaseStationAPIPayload = {
        data: IEInkBaseStation;
    };
    export function addEInkBaseStation(payload: AddEInkBaseStationAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/admin/eInkBaseStation/add`, { method, data, params, skipErrorHandler });
    }
    export type EditEInkBaseStationAPIPayload = {
        id: string;
        remark?: string;
        isDel?: boolean;
    };
    export function editEInkBaseStation(payload: EditEInkBaseStationAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["id"] = payload["id"];
        data["remark"] = payload["remark"];
        data["isDel"] = payload["isDel"];
        return request<ResponseSuccess<void>>(`/api/admin/eInkBaseStation/edit`, { method, data, params, skipErrorHandler });
    }
    export type GetEInkTemplateAPIPayload = {};
    export function getEInkTemplate(payload: GetEInkTemplateAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<EInkTemplate[]>>(`/api/partner/-/eInkTemplate`, { method, data, params, skipErrorHandler });
    }
}
