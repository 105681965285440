// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 标签管理
  'tag.department': 'Department',
  'tag.area': 'Area',
  'tag.other': 'Else',
  'tag.tagType': 'Tag Category',
  'tag.tagName': 'Tag Name',
  'tag.addTag': 'Click "Add" to add a new tag',
  'tag.clear': 'Clear',
  'tag.addSuccess': 'Succeed',
  'tag.editSuccess': 'Succeed',
  'tag.editFail.repeat': 'Failed. Already have Tag with the same name in this category.',
  'tag.editFail.fail': 'Failed',
  'tag.editFail.typeError': 'Some Spots with this Tag already have tags in the same category.',
  'tag.edit.force':
    'Some Spots with this Tag already have Department/Area Tag. If change the category of this Tag, will delete this Tag from those Spots mentioned. This operation cannot be recovered.',
  'tag.delSuccess': 'Succeed',
  'tag.create': 'Add',
  'tag.deleteWarnTit': 'Notice',
  'tag.deleteWarnContent': 'The Tag cannot be recovered after deletion. Are you sure you want to continue?',
  'tag.clearWarnTit': 'Notice',
  'tag.clearWarnContent':
    'All Tags in this category cannot be recovered after cleared. Are you sure you want to continue?',
  'tag.editModalTit': 'Notice',
  'tag.createTag': 'Add a Tag',
  'tag.new-tag': 'New Tag',
  'tag.add-new-tag': 'Add new tag',
  'tag.name': 'Tag',
  'tag.enter-content': 'Enter tag content',
  'tag.enter-name': 'Please enter a tag name',
  'tag.choose-type': 'Please choose tag type',
};
