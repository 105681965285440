// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { ProjectSensorInfo } from '@ixam/apis/types';
import { Sensor } from '@/services/sensor';
import { useCallback, useState } from 'react';

const { getProjectSensorList: getSensorList } = Sensor;

export default () => {
  const [sensorList, setSensorList] = useState<ProjectSensorInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const getProjectSensorList = useCallback(async (payload: Sensor.GetProjectSensorListAPIPayload) => {
    try {
      setLoading(true);
      const data = (await getSensorList(payload)).data;
      setSensorList(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  return { sensorList, getProjectSensorList, loading };
};
