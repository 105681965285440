/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { SpacePerms, UserRole } from "@ixam/apis/types/perms";
// @ts-ignore
import { SpotSpaceAnalyticsData } from "@ixam/apis/types/spot-space-analytics";
export namespace SpotSpaceAnalytics {
    export type ListAPIPayload = {
        date: string;
    };
    export function list(payload: ListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["date"] = `${payload["date"]}`;
        return request<ResponseSuccess<SpotSpaceAnalyticsData[]>>(`/api/spotSpaceAnalyticsData`, { method, data, params, skipErrorHandler });
    }
    export type GetAPIPayload = {
        spotId: string;
        date: string;
    };
    export function get(payload: GetAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<SpotSpaceAnalyticsData>>(`/api/spotSpaceAnalyticsData/${payload.spotId}/${payload.date}`, { method, data, params, skipErrorHandler });
    }
    export type UpdateAPIPayload = {
        spotId: string;
        date: string;
        valid: 1 | 0;
        remark?: string;
    };
    export function update(payload: UpdateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["valid"] = payload["valid"];
        data["remark"] = payload["remark"];
        return request<ResponseSuccess<void>>(`/api/spotSpaceAnalyticsData/${payload.spotId}/${payload.date}`, { method, data, params, skipErrorHandler });
    }
}
