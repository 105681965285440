/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { ExternalScreenDeviceBookItem, ScreenDeviceBaseInfo, ScreenDeviceBookItem, ScreenDeviceUserSetting, TodayCanteenMenu, } from "@ixam/apis/types/screen-device";
// @ts-ignore
import { F2TicketType } from "@ixam/apis/types/constant";
// @ts-ignore
import { InventoryItem } from "@ixam/apis/types/screen-device-inventory";
export namespace ScreenDevice {
    export type ScreenDeviceLoginAPIPayload = {
        mac: string;
        noncestr: string;
        timestamp: number;
        sig: string;
    };
    export function screenDeviceLogin(payload: ScreenDeviceLoginAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["mac"] = payload["mac"];
        data["noncestr"] = payload["noncestr"];
        data["timestamp"] = payload["timestamp"];
        data["sig"] = payload["sig"];
        return request<ResponseSuccess<{
            token: string;
        }>>(`/api/screenDevice/login`, { method, data, params, skipErrorHandler });
    }
    export type GetScreenDeviceBaseInfoAPIPayload = {};
    export function getScreenDeviceBaseInfo(payload: GetScreenDeviceBaseInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<ScreenDeviceBaseInfo>>(`/api/screenDevice/info`, { method, data, params, skipErrorHandler });
    }
    export type GetScreenDeviceBookListAPIPayload = {
        spotId: string;
        beginTime: number;
        endTime: number;
    };
    export function getScreenDeviceBookList(payload: GetScreenDeviceBookListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["spotId"] = `${payload["spotId"]}`;
        params["beginTime"] = `${payload["beginTime"]}`;
        params["endTime"] = `${payload["endTime"]}`;
        return request<ResponseSuccess<ScreenDeviceBookItem[]>>(`/api/screenDevice/bookList`, { method, data, params, skipErrorHandler });
    }
    export type GetUserSettingAPIPayload = {
        bookId: string;
        spotId: string;
        userId: string;
    };
    export function getUserSetting(payload: GetUserSettingAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["bookId"] = `${payload["bookId"]}`;
        params["spotId"] = `${payload["spotId"]}`;
        params["userId"] = `${payload["userId"]}`;
        return request<ResponseSuccess<{
            settings: ScreenDeviceUserSetting;
            isSetted: boolean;
        }>>(`/api/screenDevice/userSetting`, { method, data, params, skipErrorHandler });
    }
    export type SetUserPreferenceAPIPayload = {
        bookId: string;
        spotId: string;
        userId: string;
        data: ScreenDeviceUserSetting;
    };
    export function setUserPreference(payload: SetUserPreferenceAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["bookId"] = payload["bookId"];
        data["spotId"] = payload["spotId"];
        data["userId"] = payload["userId"];
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/screenDevice/setUserPreference`, { method, data, params, skipErrorHandler });
    }
    export type UpdateDeviceStatusAPIPayload = {
        data: ExternalScreenDeviceBookItem;
    };
    export function updateDeviceStatus(payload: UpdateDeviceStatusAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/screenDevice/updateStatus`, { method, data, params, skipErrorHandler });
    }
    export type UpdatePreDeviceStatusAPIPayload = {
        data: ExternalScreenDeviceBookItem;
    };
    export function updatePreDeviceStatus(payload: UpdatePreDeviceStatusAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/pre/screenDevice/updateStatus`, { method, data, params, skipErrorHandler });
    }
    export type GetTodayCanteenMenuAPIPayload = {
        spotId: string;
    };
    export function getTodayCanteenMenu(payload: GetTodayCanteenMenuAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["spotId"] = `${payload["spotId"]}`;
        return request<ResponseSuccess<TodayCanteenMenu>>(`/api/screenDevice/todayCanteenMenu`, { method, data, params, skipErrorHandler });
    }
    export type GetInventoryListAPIPayload = {
        spotId: string;
    };
    export function getInventoryList(payload: GetInventoryListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["spotId"] = `${payload["spotId"]}`;
        return request<ResponseSuccess<InventoryItem[]>>(`/api/screenDevice/inventoryList`, { method, data, params, skipErrorHandler });
    }
    export type SendDeviceTicketAPIPayload = {
        spotId: string;
        ticketTitle: F2TicketType;
        ticketDetail: string;
    };
    export function sendDeviceTicket(payload: SendDeviceTicketAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["spotId"] = payload["spotId"];
        data["ticketTitle"] = payload["ticketTitle"];
        data["ticketDetail"] = payload["ticketDetail"];
        return request<ResponseSuccess<void>>(`/api/screenDevice/ticket`, { method, data, params, skipErrorHandler });
    }
}
