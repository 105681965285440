/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BaseUserInfo } from "@ixam/apis/types/user";
// @ts-ignore
import { FileType } from "@ixam/apis/types/_types";
// @ts-ignore
import { Menu } from "@ixam/apis/types/login";
// @ts-ignore
import { PartnerUserToAddPartner, PartnerUserToUpdatePartner, ProjectAdminListItem } from "@ixam/apis/types/partner-user";
export namespace PartnerUser {
    export type AddPartnerUserInPartnerAPIPayload = {
        user: PartnerUserToAddPartner;
    };
    export function addPartnerUserInPartner(payload: AddPartnerUserInPartnerAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["user"] = payload["user"];
        return request<ResponseSuccess<void>>(`/api/partner/users`, { method, data, params, skipErrorHandler });
    }
    export type UpdatePartnerUserAPIPayload = {
        user: PartnerUserToUpdatePartner;
    };
    export function updatePartnerUser(payload: UpdatePartnerUserAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["user"] = payload["user"];
        return request<ResponseSuccess<void>>(`/api/partner/users/update`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerProjectListAPIPayload = {};
    export function getPartnerProjectList(payload: GetPartnerProjectListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<ProjectAdminListItem[]>>(`/api/partner/users/getCompanyAdminList`, { method, data, params, skipErrorHandler });
    }
    export type SetProjectAdminAPIPayload = {
        uid: string;
        projectIds: number[];
    };
    export function setProjectAdmin(payload: SetProjectAdminAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["uid"] = payload["uid"];
        data["projectIds"] = payload["projectIds"];
        return request<ResponseSuccess<void>>(`/api/partner/users/setCompanyAdmin`, { method, data, params, skipErrorHandler });
    }
    export type ImportUsersAPIPayload = {
        file: File;
        force?: boolean;
    };
    export function importUsers(payload: ImportUsersAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data = new FormData();
        data.append("token", token);
        data.append("file", payload["file"]);
        data.append("force", payload["force"]);
        return request<ResponseSuccess<{
            total_count: number;
            success_count: number;
        }>>(`/api/partner/users/import`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerUserPermissionListAPIPayload = {};
    export function getPartnerUserPermissionList(payload: GetPartnerUserPermissionListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<Menu[]>>(`/api/partner/users/getUserPartnerPermission`, { method, data, params, skipErrorHandler });
    }
    export type SetPartnerUserPermissionAPIPayload = {
        uids: string[];
        permissions: {
            name: string;
            isDelete: boolean;
        }[];
    };
    export function setPartnerUserPermission(payload: SetPartnerUserPermissionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["uids"] = payload["uids"];
        data["permissions"] = payload["permissions"];
        return request<ResponseSuccess<void>>(`/api/partner/users/setPermission`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerBaseUserListAPIPayload = {};
    export function getPartnerBaseUserList(payload: GetPartnerBaseUserListAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<BaseUserInfo[]>>(`/api/partner/users/getPartnerBaseUserList`, { method, data, params, skipErrorHandler });
    }
}
