/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { SpotBookItem, SpotBookItemOfFloorPlan, UserSpotBookItem } from "@ixam/apis/types/digi-spot-book";
export namespace DigiSpotBook {
    export type GetSpotBookRecordAPIPayload = {
        startTime: number;
        endTime: number;
    };
    export function getSpotBookRecord(payload: GetSpotBookRecordAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["startTime"] = `${payload["startTime"]}`;
        params["endTime"] = `${payload["endTime"]}`;
        return request<ResponseSuccess<SpotBookItem[]>>(`/api/digiSpot/manage/bookList`, { method, data, params, skipErrorHandler });
    }
    export type CancelSpotBookRecordAPIPayload = {
        bookId: number;
    };
    export function cancelSpotBookRecord(payload: CancelSpotBookRecordAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["bookId"] = payload["bookId"];
        return request<ResponseSuccess<void>>(`/api/digiSpot/manage/cancelBook`, { method, data, params, skipErrorHandler });
    }
    export type GetUserSpotBookRecordAPIPayload = {};
    export function getUserSpotBookRecord(payload: GetUserSpotBookRecordAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<UserSpotBookItem[]>>(`/api/digiSpot/user/bookList`, { method, data, params, skipErrorHandler });
    }
    export type GetAvailableSpotAPIPayload = {
        floorPlanId: number;
        startTime: number;
        endTime: number;
    };
    export function getAvailableSpot(payload: GetAvailableSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["floorPlanId"] = `${payload["floorPlanId"]}`;
        params["startTime"] = `${payload["startTime"]}`;
        params["endTime"] = `${payload["endTime"]}`;
        return request<ResponseSuccess<SpotBookItemOfFloorPlan[]>>(`/api/digiSpot/user/availableSpot`, { method, data, params, skipErrorHandler });
    }
    export type BookSpotAPIPayload = {
        resourceId: string;
        startTime: number;
        endTime: number;
    };
    export function bookSpot(payload: BookSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["resourceId"] = payload["resourceId"];
        data["startTime"] = payload["startTime"];
        data["endTime"] = payload["endTime"];
        return request<ResponseSuccess<UserSpotBookItem>>(`/api/digiSpot/user/book`, { method, data, params, skipErrorHandler });
    }
    export type CancelBookSpotAPIPayload = {
        bookId: number;
    };
    export function cancelBookSpot(payload: CancelBookSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["bookId"] = payload["bookId"];
        return request<ResponseSuccess<void>>(`/api/digiSpot/user/cancel`, { method, data, params, skipErrorHandler });
    }
    export type CheckInSpotAPIPayload = {
        bookId: number;
    };
    export function checkInSpot(payload: CheckInSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["bookId"] = payload["bookId"];
        return request<ResponseSuccess<void>>(`/api/digiSpot/user/check_in`, { method, data, params, skipErrorHandler });
    }
    export type CheckOutSpotAPIPayload = {
        bookId: number;
    };
    export function checkOutSpot(payload: CheckOutSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["bookId"] = payload["bookId"];
        return request<ResponseSuccess<void>>(`/api/digiSpot/user/check_out`, { method, data, params, skipErrorHandler });
    }
    export type GetSpotInfoAPIPayload = {
        uuid: string;
        startTime: number;
        endTime: number;
    };
    export function getSpotInfo(payload: GetSpotInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["uuid"] = `${payload["uuid"]}`;
        params["startTime"] = `${payload["startTime"]}`;
        params["endTime"] = `${payload["endTime"]}`;
        return request<ResponseSuccess<SpotBookItem>>(`/api/digiSpot/user/spotInfo`, { method, data, params, skipErrorHandler });
    }
}
