// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import all from './zh-CN/all';
import changePassword from './zh-CN/changePassword';
import common from './zh-CN/common';
import dataMarking from './zh-CN/dataMarking';
import floorPlan from './zh-CN/floorPlan';
import inventory from './zh-CN/inventory';
import login from './zh-CN/login';
import menu from './zh-CN/menu';
import resetPassword from './zh-CN/resetPassword';
import sensor from './zh-CN/sensor';
import smartPower from './zh-CN/smartPower';
import spotRealtimeStatus from './zh-CN/spotRealtimeStatus';
import tag from './zh-CN/tag';

export default {
  ...all,
  ...common,
  ...login,
  ...menu,
  ...smartPower,
  ...changePassword,
  ...sensor,
  ...resetPassword,
  ...inventory,
  ...tag,
  ...dataMarking,
  ...spotRealtimeStatus,
  ...floorPlan,
};
