/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { Menu } from "@ixam/apis/types/login";
// @ts-ignore
import { PartnerInfo, PartnerToAdd, PartnerToUpdate, PartnerWithAdmin, PartnerWithAdminAndState, } from "@ixam/apis/types/partner";
// @ts-ignore
import { PartnerUserToUpdatePartner } from "@ixam/apis/types/partner-user";
// @ts-ignore
import { UserInfo } from "@ixam/apis/types/user";
export namespace Partner {
    export type GetPartnerListAPIPayload = {
        query?: string;
        offset?: number;
        limit?: number;
    };
    export function getPartnerList(payload: GetPartnerListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["query"] = `${payload["query"]}`;
        params["offset"] = `${payload["offset"]}`;
        params["limit"] = `${payload["limit"]}`;
        return request<ResponseSuccess<{
            list: PartnerWithAdminAndState[];
            total: number;
        }>>(`/api/admin/partner/list`, { method, data, params, skipErrorHandler });
    }
    export type AddPartnerAPIPayload = {
        partner: PartnerToAdd;
    };
    export function addPartner(payload: AddPartnerAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["partner"] = payload["partner"];
        return request<ResponseSuccess<PartnerWithAdmin>>(`/api/admin/partner/add`, { method, data, params, skipErrorHandler });
    }
    export type UpdatePartnerAPIPayload = {
        partner: PartnerToUpdate;
    };
    export function updatePartner(payload: UpdatePartnerAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["partner"] = payload["partner"];
        return request<ResponseSuccess<number>>(`/api/admin/partner/update`, { method, data, params, skipErrorHandler });
    }
    export type ResetPartnerPasswordAPIPayload = {
        partnerId: number;
    };
    export function resetPartnerPassword(payload: ResetPartnerPasswordAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["partnerId"] = payload["partnerId"];
        return request<ResponseSuccess<string>>(`/api/admin/partner/resetPassword`, { method, data, params, skipErrorHandler });
    }
    export type GetPermissionListAPIPayload = {
        partnerId: number;
    };
    export function getPermissionList(payload: GetPermissionListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["partnerId"] = `${payload["partnerId"]}`;
        return request<ResponseSuccess<Menu[]>>(`/api/admin/partner/permissionList`, { method, data, params, skipErrorHandler });
    }
    export type GetSpacePermissionListAPIPayload = {
        partnerId: number;
    };
    export function getSpacePermissionList(payload: GetSpacePermissionListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["partnerId"] = `${payload["partnerId"]}`;
        return request<ResponseSuccess<Menu[]>>(`/api/admin/partner/projectPermissionList`, { method, data, params, skipErrorHandler });
    }
    export type UpdatePermissionAPIPayload = {
        partnerId: number;
        services: {
            permission: string;
            choose: boolean;
        }[];
    };
    export function updatePermission(payload: UpdatePermissionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["partnerId"] = payload["partnerId"];
        data["services"] = payload["services"];
        return request<ResponseSuccess<void>>(`/api/admin/partner/updatePartnerPermission`, { method, data, params, skipErrorHandler });
    }
    export type UpdateSpacePermissionAPIPayload = {
        partnerId: number;
        services: {
            permission: string;
            choose: boolean;
        }[];
    };
    export function updateSpacePermission(payload: UpdateSpacePermissionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["partnerId"] = payload["partnerId"];
        data["services"] = payload["services"];
        return request<ResponseSuccess<void>>(`/api/admin/partner/updateProjectPermission`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerUserListAPIPayload = {
        partnerId: number;
        offset?: number;
        limit?: number;
        query?: string;
    };
    export function getPartnerUserList(payload: GetPartnerUserListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["partnerId"] = `${payload["partnerId"]}`;
        params["offset"] = `${payload["offset"]}`;
        params["limit"] = `${payload["limit"]}`;
        params["query"] = `${payload["query"]}`;
        return request<ResponseSuccess<{
            list: UserInfo[];
            total: number;
        }>>(`/api/admin/partner/userList`, { method, data, params, skipErrorHandler });
    }
    export type AddPartnerUserAPIPayload = {
        name: string;
        partnerId: number;
        phone: string;
        phonePrefix: string;
        email?: string;
        avatar?: string;
        position?: string;
        remark?: string;
    };
    export function addPartnerUser(payload: AddPartnerUserAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["name"] = payload["name"];
        data["partnerId"] = payload["partnerId"];
        data["phone"] = payload["phone"];
        data["phonePrefix"] = payload["phonePrefix"];
        data["email"] = payload["email"];
        data["avatar"] = payload["avatar"];
        data["position"] = payload["position"];
        data["remark"] = payload["remark"];
        return request<ResponseSuccess<void>>(`/api/admin/partner/addUser`, { method, data, params, skipErrorHandler });
    }
    export type ChangePartnerOwnerAPIPayload = {
        partnerId: number;
        uid: string;
    };
    export function changePartnerOwner(payload: ChangePartnerOwnerAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["partnerId"] = payload["partnerId"];
        data["uid"] = payload["uid"];
        return request<ResponseSuccess<void>>(`/api/admin/partner/changeOwner`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerInfoAPIPayload = {
        partnerId: number;
    };
    export function getPartnerInfo(payload: GetPartnerInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<PartnerInfo | PartnerWithAdmin>>(`/api/partner/partners/${payload.partnerId}`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerUserInfoAPIPayload = {
        userId: string;
    };
    export function getPartnerUserInfo(payload: GetPartnerUserInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<UserInfo>>(`/api/partner/users/${payload.userId}`, { method, data, params, skipErrorHandler });
    }
    export type UpdatePartnerInfoAPIPayload = {
        partnerId: number;
        partnerName: string;
        logo?: string;
        address?: string;
        website?: string;
        language?: string;
    };
    export function updatePartnerInfo(payload: UpdatePartnerInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["partnerId"] = payload["partnerId"];
        data["partnerName"] = payload["partnerName"];
        data["logo"] = payload["logo"];
        data["address"] = payload["address"];
        data["website"] = payload["website"];
        data["language"] = payload["language"];
        return request<ResponseSuccess<void>>(`/api/partner/partners/partnerInfo`, { method, data, params, skipErrorHandler });
    }
    export type UpdatePartnerUserInfoAPIPayload = {
        partnerId: number;
        userToUpdate: PartnerUserToUpdatePartner;
    };
    export function updatePartnerUserInfo(payload: UpdatePartnerUserInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["partnerId"] = payload["partnerId"];
        data["userToUpdate"] = payload["userToUpdate"];
        return request<ResponseSuccess<void>>(`/api/partner/users/userInfo`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerInUserListAPIPayload = {};
    export function getPartnerInUserList(payload: GetPartnerInUserListAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<PartnerInfo[]>>(`/api/partner/users/partners`, { method, data, params, skipErrorHandler });
    }
    export type UsersChangePartnerAPIPayload = {
        partnerId: number;
    };
    export function usersChangePartner(payload: UsersChangePartnerAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["partnerId"] = payload["partnerId"];
        return request<ResponseSuccess<{
            token: string;
            isPartnerOwner: boolean;
        }>>(`/api/partner/users/changePartner`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerHomeDataAPIPayload = {};
    export function getPartnerHomeData(payload: GetPartnerHomeDataAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<{
            ongoingProjects: number;
            pausedProjects: number;
            inUseResource: number;
            notInUseResource: number;
            deploymentSensors: number;
            notDeploymentSensors: number;
            permissions: string[];
            partnerInfo: {
                partnerName: string;
                partnerFullName: string;
                logo?: string;
            };
        }>>(`/api/partner/home`, { method, data, params, skipErrorHandler });
    }
}
