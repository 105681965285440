// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 登录
  'login.title': 'Login to 9am',
  'login.image': 'Background Picture',
  'login.image.backchart': 'backchart_en.png',
  'login.mobile.placeholder': 'Mobile Number',
  'login.mobile.required': 'Please enter Mobile Number!',
  'login.mobile.valid': 'Please enter a valid mobile number!',
  'login.mail.placeholder': 'Email Address',
  'login.mail.required': 'Please enter Email Address!',
  'login.mail.valid': 'Please enter a valid email address!',
  'login.password.placeholder': 'Password',
  'login.password.required': 'Please enter the Password',
  'login.password.valid': 'Format Error',
  'login.captcha.placeholder': 'Captcha',
  'login.captcha.required': 'Please enter Captcha!',
  'login.captcha.another': 'Change Another One',
  'login.type.mail': 'Email',
  'login.type.mobile': 'Mobile',
  'login.find.password': 'Find Password',
  'login.submit': 'Login',
  'login.to-partner': 'Partner Portal',
  'login.count.3-more-chances': 'The account will be frozen for 24 hours after three consecutive wrong entries.',
  'login.account.frozen': 'You account has been locked. Please try log in after {time}',
};
