/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { PartnerCustomerAdded, PartnerCustomerInfo, PartnerCustomerToAdd, PartnerCustomerToUpdate, PartnerUserList, } from "@ixam/apis/types/partner-customer";
export namespace PartnerCustomer {
    export type GetPartnerCustomerListAPIPayload = {
        query?: string;
        filterParam?: object;
        offset?: number;
        limit?: number;
    };
    export function getPartnerCustomerList(payload: GetPartnerCustomerListAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["query"] = payload["query"];
        data["filterParam"] = payload["filterParam"];
        data["offset"] = payload["offset"];
        data["limit"] = payload["limit"];
        return request<ResponseSuccess<{
            customerList: PartnerCustomerInfo[];
            count: number;
        }>>(`/api/partner/customer/list`, { method, data, params, skipErrorHandler });
    }
    export type GetPersonListAPIPayload = {
        query?: string;
    };
    export function getPersonList(payload: GetPersonListAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["query"] = payload["query"];
        return request<ResponseSuccess<PartnerUserList>>(`/api/partner/users/list`, { method, data, params, skipErrorHandler });
    }
    export type AddPartnerCustomerAPIPayload = {
        customer: PartnerCustomerToAdd;
    };
    export function addPartnerCustomer(payload: AddPartnerCustomerAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["customer"] = payload["customer"];
        return request<ResponseSuccess<PartnerCustomerAdded>>(`/api/partner/customer/add`, { method, data, params, skipErrorHandler });
    }
    export type EditPartnerCustomerAPIPayload = {
        customer: PartnerCustomerToUpdate;
    };
    export function editPartnerCustomer(payload: EditPartnerCustomerAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["customer"] = payload["customer"];
        return request<ResponseSuccess<void>>(`/api/partner/customer/edit`, { method, data, params, skipErrorHandler });
    }
}
