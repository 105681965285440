/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { AdminHomeOverviewData } from "@ixam/apis/types/admin";
export namespace Admin {
    export type GetAdminHomeOverviewAPIPayload = {};
    export function getAdminHomeOverview(payload: GetAdminHomeOverviewAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<AdminHomeOverviewData>>(`/api/admin/home`, { method, data, params, skipErrorHandler });
    }
}
