/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { SmartPower, SmartPowerSwitchStatus, SmartPowerUserSubscription } from "@ixam/apis/types/smart-power";
// @ts-ignore
import { SpacePerms, UserRole } from "@ixam/apis/types/perms";
// @ts-ignore
import { SmartPowerGetSubscriptionsResultResultScoped } from "@ixam/apis/types/_return/smart-power";
// @ts-ignore
import { SmartPowerGetSubscriptionsResultResultScopedOfUser } from "@ixam/apis/types/_scoped/_return/smart-power";
// @ts-ignore
import { SmartPowerGetSubscriptionsResultResultScopedOfPartner } from "@ixam/apis/types/_scoped/_return/smart-power";
// @ts-ignore
import { SmartPowerGetSubscriptionsResultResultScopedOfAdmin } from "@ixam/apis/types/_scoped/_return/smart-power";
export namespace SvcSmartPower {
    export type ListAPIPayload = {};
    export function list(payload: ListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<SmartPower[]>>(`/api/smartPowers`, { method, data, params, skipErrorHandler });
    }
    export type GetSubscriptionsAPIPayload = {};
    function _getSubscriptions(payload: GetSubscriptionsAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<any>>(`/api/smartPowers/-/subscriptions`, { method, data, params, skipErrorHandler });
    }
    // UserRole.USER,UserRole.PARTNER,UserRole.ADMIN
    export function getSubscriptionsWithScope(role: UserRole.USER): (payload: GetSubscriptionsAPIPayload) => Promise<ResponseSuccess<SmartPowerGetSubscriptionsResultResultScopedOfUser>>;
    export function getSubscriptionsWithScope(role: UserRole.PARTNER): (payload: GetSubscriptionsAPIPayload) => Promise<ResponseSuccess<SmartPowerGetSubscriptionsResultResultScopedOfPartner>>;
    export function getSubscriptionsWithScope(role: UserRole.ADMIN): (payload: GetSubscriptionsAPIPayload) => Promise<ResponseSuccess<SmartPowerGetSubscriptionsResultResultScopedOfAdmin>>;
    export function getSubscriptionsWithScope(role: any) {
        return (payload: any) => _getSubscriptions({ role, ...payload });
    }
    export const getSubscriptions = getSubscriptionsWithScope(UserRole.USER);
    export type SetSubscriptionsAPIPayload = {
        on: boolean;
        receivers: string[];
    };
    export function setSubscriptions(payload: SetSubscriptionsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["on"] = payload["on"];
        data["receivers"] = payload["receivers"];
        return request<ResponseSuccess<void>>(`/api/smartPowers/-/subscriptions`, { method, data, params, skipErrorHandler });
    }
    export type EditAPIPayload = {
        id: string;
        value: Partial<SmartPower>;
    };
    export function edit(payload: EditAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["value"] = payload["value"];
        return request<ResponseSuccess<void>>(`/api/smartPowers/${payload.id}`, { method, data, params, skipErrorHandler });
    }
    export type BulkSetSwitchStatusAPIPayload = {
        ids: string[];
        switchStatus: SmartPowerSwitchStatus;
    };
    export function bulkSetSwitchStatus(payload: BulkSetSwitchStatusAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["switchStatus"] = payload["switchStatus"];
        return request<ResponseSuccess<void>>(`/api/smartPowers`, { method, data, params, skipErrorHandler });
    }
}
