// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'menu.project.switch': '选择项目',
  'menu.home': '首页',
  'menu.statistics.realtime': '实时工位图',
  'menu.statistics.workstation': '实时平面图',
  'menu.statistics.dashboard': '数字化大屏',
  'menu.desk': '数字化工位',
  'menu.management.lift': '数字升降工位',
  'menu.statistics.liftAnalysis': '数字工位概况',
  'menu.statistics.healthAnalysis': '办公健康分析',
  'menu.spotBook': '工位预约',
  'menu.statistics.spotBook': '工位预约',
  'menu.management.spotBook': '工位预约记录',
  'menu.statistics.spotReserveAnalytics': '工位预约报告',
  'menu.statistics.freeBookDashboard': '预约实时大屏',
  'menu.management.spaceSpotAssign': '工位分配',
  'menu.management.spaceAsset': '资产管理',
  'menu.management.smartPower': '能源管理',
  'menu.management.batchControl': '高度批量调节',
  'menu.management.inventory': '资源位管理',
  'menu.analysis': '空间分析',
  'menu.statistics.overview': '空间概况',
  'menu.statistics.deskAnalytics': '工位分析',
  'menu.statistics.spaceUsage': '工位概况',
  'menu.statistics.departmentAnalytics': '按部门统计',
  'menu.statistics.regionAnalytics': '按区域统计',
  'menu.statistics.heatmap': '点位热力图',
  'menu.statistics.useRate.heatmap': '空间热力图',
  'menu.statistics.meetintRoom': '会议室分析',
  'menu.statistics.meeting': '会议室概况',
  'menu.statistics.meetingRoomCount': '会议室人数分析',
  'menu.general': '通用',
  'menu.statistics.status': '点位实时状态',
  'menu.statistics.spotStatus': '点位实时状态（扩展版）',
  'menu.statistics.data': '数据标记',
  'menu.device.spot': '点位管理',
  'menu.management.tagManagement': '标签管理',
  'menu.device.sensor': '设备管理',
  'menu.management.person': '用户管理',
  'menu.developer': '开发者配置',
  'menu.management.smartPowerDemo': 'Demo演示',
  'menu.management.smartPowerDeviceManagement': '设备管理',
  'menu.management.smartPowerPowerMonitoring': '实时功率监控',
  'menu.management.smartPowerEnergyAnalysis': '能耗分析',
  'menu.management.smartPower.deviceManagement': '设备管理',
  'menu.management.smartPower.powerMonitoring': '实时功率监控',
  'menu.management.smartPower.energyAnalysis': '能耗分析',
  'menu.changeAccountInfo': '账号信息',
  'menu.changePassword': '修改密码',
  'menu.reset.password': '【9am】找回密码',
  'menu.external.RetrievePassword': '【9am】找回密码',
  'menu.external.ResetPassword': '【9am】设置新密码',
  'menu.statistics.data-marking': '数据标记',
  'menu.statistics.spotRealtimeStatus': '实时工位状态',
};
