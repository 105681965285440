// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'reset.password.lose': 'This link is invalid or expired.',
  'reset.password.continue': 'Continue',
  'reset.password.connect-us':
    'If you have any questions, please contact us for free, we will reply you as soon as possible.',
  'reset.password.phone': 'Phone',
  'reset.password.verify': 'Verification',
  'reset.password.save-success': 'Save Successfully',
  'reset.password.save-fail': 'Failed to Save',
  'reset.password.set-new-password': 'Set New Password',
  'reset.password.set-new-password-start': 'Set new password and start using it.',
  'reset.password.go-login': 'Go to login',
  'reset.password.null':
    'If you can not visit space management system, please input your registered E-mail address. The system will send you detailed account information later.',
  'reset.password.email-address': 'E-mail',
  'reset.password.email-input': 'Please input E-mail address',
  'reset.password.send': 'Send',
  'reset.password.send-email-success': 'Send Successfully',
  'reset.password.send-email-message-one': 'We have sent to your registered E-mail address',
  'reset.password.send-email-message-two': 'We have sent a password getting back E-mail to you,please check it.',
  'reset.password.send-email-none': "Haven't received the mail?",
  'reset.password.send-again': 'Re-send',
  'reset.password.email-error-format': '이메일 주소 형식 오류',
};
