/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BeginHour, DaysFilter, EndHour, MatchType, MinTimeRule, SortBy, SortType } from "@ixam/apis/types/constant";
// @ts-ignore
import { LiftHourlyItem, LiftRankItem, LiftStandRankItem, LiftStatistics, LiftTrendItem, LiftWeeklyItem, } from "@ixam/apis/types/lift-analysis";
export namespace LiftAnalysis {
    export type LiftingStatisticsAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function liftingStatistics(payload: LiftingStatisticsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<LiftStatistics>>(`/api/health_new/lifting_statistics`, { method, data, params, skipErrorHandler });
    }
    export type GetLiftingTrendAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function getLiftingTrend(payload: GetLiftingTrendAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<LiftTrendItem[]>>(`/api/health_new/lifting_trend`, { method, data, params, skipErrorHandler });
    }
    export type LiftingWeekAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function liftingWeek(payload: LiftingWeekAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<LiftWeeklyItem[]>>(`/api/health_new/lifting_week`, { method, data, params, skipErrorHandler });
    }
    export type LiftingHourAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function liftingHour(payload: LiftingHourAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<LiftHourlyItem[]>>(`/api/health_new/lifting_hour`, { method, data, params, skipErrorHandler });
    }
    export type LiftingRankAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        type: SortType;
        limit?: number;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function liftingRank(payload: LiftingRankAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["type"] = payload["type"];
        data["limit"] = payload["limit"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<LiftRankItem[]>>(`/api/health_new/lifting_rank`, { method, data, params, skipErrorHandler });
    }
    export type StandingRankAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        sortBy: SortBy;
        type: SortType;
        limit?: number;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function standingRank(payload: StandingRankAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["sortBy"] = payload["sortBy"];
        data["type"] = payload["type"];
        data["limit"] = payload["limit"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<LiftStandRankItem[]>>(`/api/health_new/standing_rank`, { method, data, params, skipErrorHandler });
    }
}
