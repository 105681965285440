/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { AppVersion, CountryRegionItem, isEmail } from "@ixam/apis/types/common";
// @ts-ignore
import { AppVersionType, Lang, RegionItem } from "@ixam/apis/types/constant";
// @ts-ignore
import { UserRole } from "@ixam/apis/types/perms";
export namespace Common {
    export type GetDivisionInfoAPIPayload = {};
    export function getDivisionInfo(payload: GetDivisionInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<RegionItem[]>>(`/api/get_division_info`, { method, data, params, skipErrorHandler });
    }
    export type GetCountryCodeAPIPayload = {};
    export function getCountryCode(payload: GetCountryCodeAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<CountryRegionItem[]>>(`/api/users/service_region`, { method, data, params, skipErrorHandler });
    }
    export type GetQiniuTokenAPIPayload = {};
    export function getQiniuToken(payload: GetQiniuTokenAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<string>>(`/api/users/qiniuToken`, { method, data, params, skipErrorHandler });
    }
    export type GetSecurityQiniuTokenAPIPayload = {
        key: string;
    };
    export function getSecurityQiniuToken(payload: GetSecurityQiniuTokenAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["key"] = `${payload["key"]}`;
        return request<ResponseSuccess<string>>(`/api/users/securityQiniuToken`, { method, data, params, skipErrorHandler });
    }
    export type SendEmailAPIPayload = {
        email: string;
        lang?: Lang;
        role?: UserRole;
    };
    export function sendEmail(payload: SendEmailAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["email"] = payload["email"];
        data["lang"] = payload["lang"];
        data["role"] = payload["role"];
        return request<ResponseSuccess<void>>(`/api/email`, { method, data, params, skipErrorHandler });
    }
    export type ResetPasswordAPIPayload = {
        id: string;
        password: string;
    };
    export function resetPassword(payload: ResetPasswordAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["id"] = payload["id"];
        data["password"] = payload["password"];
        return request<ResponseSuccess<void>>(`/api/password`, { method, data, params, skipErrorHandler });
    }
    export type SendSmsAPIPayload = {
        phone: string;
        phonePrefix?: string;
    };
    export function sendSms(payload: SendSmsAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["phone"] = payload["phone"];
        data["phonePrefix"] = payload["phonePrefix"];
        return request<ResponseSuccess<string>>(`/api/users/sendSms`, { method, data, params, skipErrorHandler });
    }
    export type GetAppVersionAPIPayload = {
        type: AppVersionType;
    };
    export function getAppVersion(payload: GetAppVersionAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<AppVersion>>(`/api/digi/version/${payload.type}`, { method, data, params, skipErrorHandler });
    }
}
