// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'inventory.number': 'Number',
  'inventory.name': 'Name',
  'inventory.url': 'Redirect URL',
  'inventory.add': 'Add',
  'inventory.operation': 'Operation',
  'inventory.edit': 'Edit',
  'inventory.delete': 'Delete',
  'inventory.top': 'Top',
  'inventory.add.inventory.item': 'Add Inventory Item',
  'inventory.edit.inventory.item': 'Edit Inventory Item',
  'inventory.icon': 'Icon',
  'inventory.icon.size': 'A square icon is recommended. Only PNG, JPG and JPEG files maximum 2MB are accepted.',
  'inventory.cn.name': 'Chinese Name',
  'inventory.us.name': 'English Name',
  'inventory.upload.icon': 'Please upload icon',
  'inventory.enter.cn.name': 'Please enter Chinese name',
  'inventory.enter.us.name': 'Please enter English name',
  'inventory.cn.characters': 'Names shorter than 8 Chinese characters recommended',
  'inventory.us.letters': 'Names shorter than 12 letters recommended',
  'inventory.enter.CANTEENMENU': `Today's Menu: Please enter "CANTEENMENU"`,
  'inventory.enter.CLEANING': 'Janitor Service: Please enter "CLEANING"',
  'inventory.enter.REPAIRING': 'Request Service: Please enter "REPAIRING"',
  'inventory.delete.confirm': 'Are you sure to delete this inventory item?',
  'inventory.warning': 'Maximum file size: 2MB',
};
