/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BeginHour, DaysFilter, EndHour, MinTimeRule, QueryType } from "@ixam/apis/types/constant";
// @ts-ignore
import { FilterType } from "@ixam/apis/types/filter";
export namespace Filter {
    export type AddAPIPayload = {
        pageId: number;
        filterName: string;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: QueryType;
        workdayType: DaysFilter;
        minTimeRule?: MinTimeRule;
    };
    export function add(payload: AddAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["pageId"] = payload["pageId"];
        data["filterName"] = payload["filterName"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        return request<ResponseSuccess<FilterType>>(`/api/filters`, { method, data, params, skipErrorHandler });
    }
    export type ListAPIPayload = {
        pageId: number;
    };
    export function list(payload: ListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["pageId"] = `${payload["pageId"]}`;
        return request<ResponseSuccess<FilterType[]>>(`/api/filters`, { method, data, params, skipErrorHandler });
    }
    export type SetDefaultAPIPayload = {
        filterId: number;
        pageId: number;
    };
    export function setDefault(payload: SetDefaultAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["pageId"] = payload["pageId"];
        return request<ResponseSuccess<void>>(`/api/filters/${payload.filterId}:setDefault`, { method, data, params, skipErrorHandler });
    }
    export type UpdateAPIPayload = {
        filterId: number;
        pageId: number;
        filterName: string;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: QueryType;
        workdayType: DaysFilter;
        minTimeRule?: MinTimeRule;
    };
    export function update(payload: UpdateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["pageId"] = payload["pageId"];
        data["filterName"] = payload["filterName"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        return request<ResponseSuccess<void>>(`/api/filters/${payload.filterId}`, { method, data, params, skipErrorHandler });
    }
    export type DeletesAPIPayload = {
        filterId: number;
        pageId: number;
    };
    export function deletes(payload: DeletesAPIPayload, skipErrorHandler?: boolean) {
        const method = "delete";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["pageId"] = `${payload["pageId"]}`;
        return request<ResponseSuccess<void>>(`/api/filters/${payload.filterId}`, { method, data, params, skipErrorHandler });
    }
}
