// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { User } from '@/services';
import { history } from '@umijs/max';
import { useCallback, useState } from 'react';

const { changePassword } = User;

export default () => {
  const [loading, setLoading] = useState(false);
  const changePasswords = useCallback(async (payload: User.ChangePasswordAPIPayload) => {
    try {
      setLoading(true);
      await changePassword({ ...payload });
      history.push('/user/login');
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);
  return { loading, changePasswords };
};
