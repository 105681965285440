/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { IProject, PartnerProjectListItem, Project, ProjectOwner, ProjectPreference, ProjectStatus, ProjectWithPref, } from "@ixam/apis/types/partner-project";
export namespace PartnerProject {
    export type AddPartnerProjectAPIPayload = {
        project: IProject;
        administrators: string[];
    };
    export function addPartnerProject(payload: AddPartnerProjectAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["project"] = payload["project"];
        data["administrators"] = payload["administrators"];
        return request<ResponseSuccess<ProjectWithPref>>(`/api/partners/-/projects`, { method, data, params, skipErrorHandler });
    }
    export type UpdateProjectPermissionAPIPayload = {
        id: number;
        services: {
            permission: string;
            choose: boolean;
        }[];
    };
    export function updateProjectPermission(payload: UpdateProjectPermissionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["services"] = payload["services"];
        return request<ResponseSuccess<void>>(`/api/partners/-/projects/${payload.id}/permissions`, { method, data, params, skipErrorHandler });
    }
    export type DeleteProjectAPIPayload = {
        id: number;
    };
    export function deleteProject(payload: DeleteProjectAPIPayload, skipErrorHandler?: boolean) {
        const method = "delete";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<void>>(`/api/partners/-/projects/${payload.id}`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerProjectAPIPayload = {};
    export function getPartnerProject(payload: GetPartnerProjectAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<PartnerProjectListItem[]>>(`/api/partner/-/projects`, { method, data, params, skipErrorHandler });
    }
    export type GetProjectByIdAPIPayload = {
        id: number;
    };
    export function getProjectById(payload: GetProjectByIdAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<ProjectWithPref & {
            administrators: string[];
        }>>(`/api/partners/-/projects/${payload.id}`, { method, data, params, skipErrorHandler });
    }
    export type SyncProjectAPIPayload = {
        id: number;
    };
    export function syncProject(payload: SyncProjectAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<void>>(`/api/partners/-/projects/${payload.id}:sync`, { method, data, params, skipErrorHandler });
    }
    export type StartOrPauseProjectAPIPayload = {
        id: number;
        status: ProjectStatus;
    };
    export function startOrPauseProject(payload: StartOrPauseProjectAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["status"] = payload["status"];
        return request<ResponseSuccess<void>>(`/api/partners/-/projects/${payload.id}:switch`, { method, data, params, skipErrorHandler });
    }
    export type UpdatePartnerProjectAPIPayload = {
        id: number;
        project: Partial<Project>;
        administrators: string[];
    };
    export function updatePartnerProject(payload: UpdatePartnerProjectAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["project"] = payload["project"];
        data["administrators"] = payload["administrators"];
        return request<ResponseSuccess<void>>(`/api/partners/-/projects/${payload.id}`, { method, data, params, skipErrorHandler });
    }
    export type SetPreferenceAPIPayload = {
        id: number;
        preference: ProjectPreference;
    };
    export function setPreference(payload: SetPreferenceAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["preference"] = payload["preference"];
        return request<ResponseSuccess<void>>(`/api/projects/${payload.id}/preferences`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerProjectOwnerListAPIPayload = {
        id: number;
    };
    export function getPartnerProjectOwnerList(payload: GetPartnerProjectOwnerListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<ProjectOwner[]>>(`/api/projects/${payload.id}/users`, { method, data, params, skipErrorHandler });
    }
}
