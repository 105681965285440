/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BaseCustomer, BasePartner, ProjectInfo, ProjectListItem } from "@ixam/apis/types/project";
// @ts-ignore
import { Scope } from "@ixam/apis/types/common";
export namespace Project {
    export type ChangePartnerAPIPayload = {
        projectId: number;
        partnerId: number;
        customerId: number;
        keepGateways: boolean;
        keepSensors: boolean;
        keepRelays: boolean;
        keepHandsets: boolean;
    };
    export function changePartner(payload: ChangePartnerAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["projectId"] = payload["projectId"];
        data["partnerId"] = payload["partnerId"];
        data["customerId"] = payload["customerId"];
        data["keepGateways"] = payload["keepGateways"];
        data["keepSensors"] = payload["keepSensors"];
        data["keepRelays"] = payload["keepRelays"];
        data["keepHandsets"] = payload["keepHandsets"];
        return request<ResponseSuccess<void>>(`/api/admin/project/changePartner`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerListAllAPIPayload = {};
    export function getPartnerListAll(payload: GetPartnerListAllAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<BasePartner[]>>(`/api/admin/partner/listAll`, { method, data, params, skipErrorHandler });
    }
    export type GetCustomerListAllAPIPayload = {};
    export function getCustomerListAll(payload: GetCustomerListAllAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<BaseCustomer[]>>(`/api/admin/customer/listAll`, { method, data, params, skipErrorHandler });
    }
    export type GetUsersProjectsAPIPayload = {};
    export function getUsersProjects(payload: GetUsersProjectsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<ProjectInfo[]>>(`/api/users/projects`, { method, data, params, skipErrorHandler });
    }
    export type UsersChangeProjectAPIPayload = {
        projectId: number;
    };
    export function usersChangeProject(payload: UsersChangeProjectAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["projectId"] = payload["projectId"];
        return request<ResponseSuccess<{
            token: string;
            isCompanyOwner: boolean;
        }>>(`/api/users/changeProject`, { method, data, params, skipErrorHandler });
    }
    export type ListAPIPayload = {
        scope: Scope;
    };
    export function list(payload: ListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["scope"] = `${payload["scope"]}`;
        return request<ResponseSuccess<ProjectListItem[]>>(`/api/project/list`, { method, data, params, skipErrorHandler });
    }
}
