// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 登录
  'login.title': '登录-9am智能办公',
  'login.image': '背景图片',
  'login.image.backchart': 'backchart.png',
  'login.mobile.placeholder': '手机号码',
  'login.mobile.required': '请输入手机号码！',
  'login.mobile.valid': '手机号码格式错误！',
  'login.mail.placeholder': '邮箱地址',
  'login.mail.required': '请输入邮箱地址！',
  'login.mail.valid': '邮箱地址格式错误！',
  'login.password.placeholder': '密码',
  'login.password.required': '请输入密码',
  'login.password.valid': '密码格式错误',
  'login.captcha.placeholder': '验证码',
  'login.captcha.required': '请输入验证码！',
  'login.captcha.another': '看不清，换一张',
  'login.type.mail': '邮箱登录',
  'login.type.mobile': '手机登录',
  'login.find.password': '找回密码',
  'login.submit': '登录',
  'login.to-partner': '合作伙伴平台',
  'login.count.3-more-chances': '还有3次输入机会，连续输错三次将冻结24小时',
  'login.account.frozen': '账号已被冻结，请{time}后重新登录',
};
