// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'data-marking.title': '数据标记',
  'data-marking.spot-name': '点位名称',
  'data-marking.sensor-number': '设备编号',
  'data-marking.offline-hour': '离线时长',
  'data-marking.result': '结果',
  'data-marking.marking': '标记',
  'data-marking.query': '点位编号/点位名称/设备编号',
  'data-marking.marked-successfully': '标记成功',
  'data-marking.marking-success': '根据实际使用情况判断数据是否正确，正确标记',
  'data-marking.marking-error': '错误标记为',
  'data-marking.station-number': '工位编号',
  'data-marking.typical-abnormal-situation-1': '典型异常情况一：',
  'data-marking.typical-abnormal-situation-1-description':
    '某段时间内没在工位前，且确认没有其他人占用工位，但在状态栏显示为占用状态',
  'data-marking.typical-abnormal-situation-2': '典型异常情况二：',
  'data-marking.typical-abnormal-situation-2-description': '某段时间在工位前，但在状态栏上显示为空闲状态',
  'data-marking.typical-abnormal-situation-3': '典型异常情况三：',
  'data-marking.typical-abnormal-situation-3-description': '设备没有通电，但在状态栏上显示为占用或者空闲状态',
  'data-marking.marking-warning': '当前数据未进行有效标记，请确认后再次点击保存',
  'data-marking.no-use': '无使用记录，请选择其他日期',
  'data-marking.human-count': '人数',
  'data-marking.spot-id': '点位编号',
  'data-marking.occupancy-duration': '占用时长',
  'data-marking.free.time': '空闲时长',
  'data-marking.error-date-format': 'YYYY年MM月DD日',
  'data-marking.tooltip-width-situation': '120px',
  'data-marking.tooltip-width-situation-description': '300px',
};
