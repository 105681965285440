// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 选择项目
  'switch-project.description': '本账号同时存在于多个项目中，请选择需要进入的项目',
  'switch-project.all': '所有项目',
  'switch-project.ongoing': '进行中的项目',
  'switch-project.paused': '暂停中的项目',
  // Header
  'header.menu.account-info': '账号信息',
  'header.menu.switch-company': '切换项目',
  'header.menu.to-partner': '合作伙伴平台',
  'header.menu.to-admin': '系统管理平台',
  'header.menu.change-password': '修改密码',
  'header.menu.logout': '退出登录',

  // 平面图管理
  'floor-plan.workplace': '职场',
  'floor-plan.floor': '楼层',
  'floor-plan.area': '区域',
  'floor-plan.coverage': '覆盖面积',
  'floor-plan.add.area-label': '区域',
  'floor-plan.add.coverage-label': '覆盖面积',
  'floor-plan.select': '选择平面图',
  'floor-plan.edit.expand': '展开全部',
  'floor-plan.edit.collapse': '收起',
  'floor-plan.select.available-space': '选择可利用空间',
  'floor-plan.book-not-found-all': '在全部平面图上均未找到该工位。',
  'floor-plan.book-not-found-current': '当前平面图未找到该工位，但在平面图{floorPlans}找到了该工位。',
  'floor-plan.book-found-all': '当前平面图已找到该工位，同时在平面图{floorPlans}也找到了该工位。',
  'floor-plan.book-found-current': '当前平面图已找到该工位。',
  'floor-plan.book-point': '、',
};
