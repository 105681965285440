// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { IntlFormatters } from 'react-intl';
import { UserLoginResult } from '@ixam/apis/types';
import storage from './storage';

export function getCurrentUser(): UserLoginResult | undefined {
  return storage.user.get();
}

export function getToken(): string {
  const user = getCurrentUser();
  return user ? user.token : '';
}

/**
 * 跳转到指定链接
 * @param url 跳转的链接
 * @param type 跳转的目标窗口类型
 */
export function goto(url: string, type?: string) {
  const a = document.createElement('a');
  type && a.setAttribute('target', type);
  a.setAttribute('href', url);
  a.click();
}

/**
 * 格式化小时分数数据
 * @param time 时间
 * @param formatMessage
 */
export function formatTime(time: number, formatMessage: IntlFormatters['formatMessage']) {
  const hours = Math.floor(time / 60);
  const hourStr = hours === 0 ? '' : hours + formatMessage({ id: 'all.hour' }, { Hour: hours });
  const minutes = time % 60;
  const minuteStr = minutes === 0 && hours > 0 ? '' : minutes + formatMessage({ id: 'all.minute' }, { Min: minutes });
  return `${hourStr}${minuteStr}`;
}

export function emitResize() {
  const evt = new Event('resize', {
    bubbles: true, // 事件是否可以冒泡
    cancelable: false, // 事件是否可以被取消
  });
  window.dispatchEvent(evt);
}
