// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { IMAGE_URL } from '@/global';
import { history } from '@umijs/max';
import { useModel } from '@umijs/max';
import React, { useMemo } from 'react';
import RightContent from './components/RightContent';
import SelectLang from '@/components/SelectLang';
import styles from './index.less';

const defaultHeaderSettings = {
  headerLogo: `${IMAGE_URL}space_logo.png`,
  headerColor: '171F47',
};

const GlobalHeader: React.FC = () => {
  const { initialState } = useModel('@@initialState');
  const user = useMemo(() => initialState?.user, [initialState]);
  const noUserPath = ['/external/RetrievePassword'];

  return (
    <div
      className={styles.header}
      style={{ backgroundColor: `#${user?.headerColor || defaultHeaderSettings.headerColor}` }}
    >
      <img className={styles.logo} src={user?.headerLogo || defaultHeaderSettings.headerLogo} alt="logo" />
      {noUserPath.includes(history.location.pathname) || !user ? (
        <SelectLang style={{ marginRight: 30, color: '#fff' }} />
      ) : (
        <RightContent user={user} />
      )}
    </div>
  );
};

export default GlobalHeader;
