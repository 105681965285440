// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'spot-realtime-status.floor': 'Current Floor',
  'spot-realtime-status.area': 'Current Area',
  'spot-realtime-status.spot-id': 'Spot ID',
  'spot-realtime-status.none': 'None',
  'spot-realtime-status.not-found-all': 'No workstations found in all floor plans.',
  'spot-realtime-status.not-found-current':
    'No workstations found in current floor plan, but found in floor plan {floorPlans}.',
  'spot-realtime-status.found-all':
    'Workstations found in current floor plan, and also found in floor plan {floorPlans}.',
  'spot-realtime-status.found-current': 'Workstation found in current floor plan.',
  'spot-realtime-status.all-spot': 'All',
  'spot-realtime-status.personal-spot': 'Personal',
  'spot-realtime-status.shared-spot': 'Shared',
  'spot-realtime-status.assigned-personal-spot': 'Assigned',
  'spot-realtime-status.unassigned-personal-spot': 'Not Assigned',
  'spot-realtime-status.assigned-shared-spot': 'Booked',
  'spot-realtime-status.unassigned-shared-spot': 'Available',
  'spot-realtime-status.search-department-employee': 'Search Employees',
  'spot-realtime-status.name': 'Name',
  'spot-realtime-status.department': 'Department',
  'spot-realtime-status.email': 'E-mail',
  'spot-realtime-status.used-rate': 'Occupancy Rate',
};
