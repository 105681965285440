// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import all from './en-US/all';
import changePassword from './en-US/changePassword';
import common from './en-US/common';
import dataMarking from './en-US/dataMarking';
import floorPlan from './en-US/floorPlan';
import inventory from './en-US/inventory';
import login from './en-US/login';
import menu from './en-US/menu';
import resetPassword from './en-US/resetPassword';
import sensor from './en-US/sensor';
import smartPower from './en-US/smartPower';
import spotRealtimeStatus from './en-US/spotRealtimeStatus';
import tag from './en-US/tag';

export default {
  ...all,
  ...common,
  ...login,
  ...menu,
  ...smartPower,
  ...changePassword,
  ...sensor,
  ...resetPassword,
  ...inventory,
  ...tag,
  ...dataMarking,
  ...spotRealtimeStatus,
  ...floorPlan,
};
