/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { DayDataResponse, RangeDataResponse, ShareDataResponse, bindPhoneResponse } from "@ixam/apis/types/health";
// @ts-ignore
import { DigiRangeDataType } from "@ixam/apis/types/digi";
// @ts-ignore
import { isDate } from "@ixam/apis/types/common";
export namespace Health {
    export type DigiBindPhoneAPIPayload = {
        encryptedData: string;
        iv: string;
    };
    export function digiBindPhone(payload: DigiBindPhoneAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["encryptedData"] = payload["encryptedData"];
        data["iv"] = payload["iv"];
        return request<ResponseSuccess<bindPhoneResponse>>(`/api/health_data/digi_bind_phone`, { method, data, params, skipErrorHandler });
    }
    export type DigiUnbindPhoneAPIPayload = {};
    export function digiUnbindPhone(payload: DigiUnbindPhoneAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<void>>(`/api/health_data/digi_unbind_phone`, { method, data, params, skipErrorHandler });
    }
    export type MiniProgramDayDataAPIPayload = {
        uuid: string;
        date: string;
    };
    export function miniProgramDayData(payload: MiniProgramDayDataAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["uuid"] = `${payload["uuid"]}`;
        params["date"] = `${payload["date"]}`;
        return request<ResponseSuccess<DayDataResponse>>(`/api/health_data/mini_program_day_data`, { method, data, params, skipErrorHandler });
    }
    export type MiniProgramRangeDataAPIPayload = {
        uuid: string;
        date: string;
        type: DigiRangeDataType;
    };
    export function miniProgramRangeData(payload: MiniProgramRangeDataAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["uuid"] = `${payload["uuid"]}`;
        params["date"] = `${payload["date"]}`;
        params["type"] = `${payload["type"]}`;
        return request<ResponseSuccess<RangeDataResponse>>(`/api/health_data/mini_program_range_data`, { method, data, params, skipErrorHandler });
    }
    export type MiniProgramShareDataAPIPayload = {
        shareId: string;
    };
    export function miniProgramShareData(payload: MiniProgramShareDataAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["shareId"] = `${payload["shareId"]}`;
        return request<ResponseSuccess<ShareDataResponse>>(`/api/health_data/mini_program_share_data`, { method, data, params, skipErrorHandler });
    }
    export type GetUuidAPIPayload = {
        deviceToken: string;
    };
    export function getUuid(payload: GetUuidAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["deviceToken"] = `${payload["deviceToken"]}`;
        return request<ResponseSuccess<{
            uuid: string;
        }>>(`/api/health_data/get_uuid_by_device_token`, { method, data, params, skipErrorHandler });
    }
    export type UpdateResourceAPIPayload = {
        uuid?: string;
    };
    export function updateResource(payload: UpdateResourceAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["uuid"] = payload["uuid"];
        return request<ResponseSuccess<string | null>>(`/api/health_data/update_resource`, { method, data, params, skipErrorHandler });
    }
    export type GetOfficialAccountAPIPayload = {};
    export function getOfficialAccount(payload: GetOfficialAccountAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<{
            followingOfficialAccount: boolean;
        }>>(`/api/health_data/get_official_account`, { method, data, params, skipErrorHandler });
    }
}
