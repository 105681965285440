// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { CDN_ROOT_URL, IS_ON_PREMISES } from '@/global';
import { Common } from '@/services';
import { CompressOptions, IUploader, UploadTypes, initUploader } from '@ixam/upload-plugin';
import { useCallback, useMemo } from 'react';

const { getSecurityQiniuToken } = Common;

type UploadImagePayload = { file: File; url: string; options?: CompressOptions };
type UploadFilePayload = { file: File; url: string };

export default () => {
  const uploader: IUploader | undefined = useMemo(() => {
    const type = IS_ON_PREMISES ? UploadTypes.WebDAV : UploadTypes.Qiniu;
    if (type === UploadTypes.Qiniu) {
      const tokenGetter = async (payload: { key: string }) => (await getSecurityQiniuToken(payload)).data;
      return initUploader({ type, options: tokenGetter });
    } else if (type === UploadTypes.WebDAV) {
      return initUploader({ type, options: CDN_ROOT_URL });
    }
  }, []);

  const image = useCallback(
    async (payload: UploadImagePayload) => {
      const { file, url, options } = payload;
      return await uploader?.image(file, url, options);
    },
    [uploader]
  );

  const file = useCallback(
    async (payload: UploadFilePayload) => {
      const { file, url } = payload;
      return await uploader?.file(file, url);
    },
    [uploader]
  );

  return { uploader, image, file };
};
