// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import all from './ko-KR/all';
import changePassword from './ko-KR/changePassword';
import common from './ko-KR/common';
import dataMarking from './ko-KR/dataMarking';
import floorPlan from './ko-KR/floorPlan';
import inventory from './ko-KR/inventory';
import login from './ko-KR/login';
import menu from './ko-KR/menu';
import resetPassword from './ko-KR/resetPassword';
import sensor from './ko-KR/sensor';
import smartPower from './ko-KR/smartPower';
import spotRealtimeStatus from './ko-KR/spotRealtimeStatus';
import tag from './ko-KR/tag';

export default {
  ...all,
  ...common,
  ...login,
  ...menu,
  ...smartPower,
  ...changePassword,
  ...sensor,
  ...resetPassword,
  ...inventory,
  ...tag,
  ...dataMarking,
  ...spotRealtimeStatus,
  ...floorPlan,
};
