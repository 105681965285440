/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BTMeshNetItem, BTMeshNetToEdit, ConfigNetworkResponse, MeshNetDevice } from "@ixam/apis/types/bt_mesh_net";
export namespace BtMeshNet {
    export type GetAdminBtMeshNetListAPIPayload = {
        query?: string;
    };
    export function getAdminBtMeshNetList(payload: GetAdminBtMeshNetListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["query"] = `${payload["query"]}`;
        return request<ResponseSuccess<{
            total: number;
            list: BTMeshNetItem[];
        }>>(`/api/admin/bt_mesh_net`, { method, data, params, skipErrorHandler });
    }
    export type AddBtMeshNetAdminAPIPayload = {
        name: string;
        remark?: string;
    };
    export function addBtMeshNetAdmin(payload: AddBtMeshNetAdminAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["name"] = payload["name"];
        data["remark"] = payload["remark"];
        return request<ResponseSuccess<void>>(`/api/admin/bt_mesh_net`, { method, data, params, skipErrorHandler });
    }
    export type EditBtMeshNetAdminAPIPayload = {
        data: BTMeshNetToEdit;
    };
    export function editBtMeshNetAdmin(payload: EditBtMeshNetAdminAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/admin/bt_mesh_net`, { method, data, params, skipErrorHandler });
    }
    export type ConfigNetworkAPIPayload = {
        btMeshNetId: number;
        database: string;
        nodes: string;
    };
    export function configNetwork(payload: ConfigNetworkAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["btMeshNetId"] = payload["btMeshNetId"];
        data["database"] = payload["database"];
        data["nodes"] = payload["nodes"];
        return request<ResponseSuccess<ConfigNetworkResponse>>(`/api/admin/bt_mesh_net/config`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerBtMeshNetListAPIPayload = {};
    export function getPartnerBtMeshNetList(payload: GetPartnerBtMeshNetListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<BTMeshNetItem[]>>(`/api/partner/bt_mesh_net`, { method, data, params, skipErrorHandler });
    }
    export type GetAdminMeshNetDevicesAPIPayload = {
        id: number;
        query?: string;
    };
    export function getAdminMeshNetDevices(payload: GetAdminMeshNetDevicesAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["id"] = `${payload["id"]}`;
        params["query"] = `${payload["query"]}`;
        return request<ResponseSuccess<{
            total: number;
            list: MeshNetDevice[];
        }>>(`/api/admin/bt_mesh_net/:id/devices`, { method, data, params, skipErrorHandler });
    }
}
