/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { DigiBindResult, DigiBindType, DigiBindWechatResult, DigiDataType, DigiFeedbackType, DigiHeightUnit, DigiLoginResponse, DigiOfflineDataItem, DigiReminderSwitch, DigiReminderType, DigiReportData, FirmwareVersionInfo, } from "@ixam/apis/types/digi";
// @ts-ignore
import { UserGender } from "@ixam/apis/types/constant";
export namespace Digi {
    export type PostDigiOfflineDataAPIPayload = {
        data: DigiOfflineDataItem[];
    };
    export function postDigiOfflineData(payload: PostDigiOfflineDataAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/digi/update_data`, { method, data, params, skipErrorHandler });
    }
    export type GetFirmwareVersionAPIPayload = {
        sn: string;
        firmwareIds: string;
    };
    export function getFirmwareVersion(payload: GetFirmwareVersionAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["sn"] = `${payload["sn"]}`;
        params["firmwareIds"] = `${payload["firmwareIds"]}`;
        return request<ResponseSuccess<FirmwareVersionInfo[]>>(`/api/digi/firmware_version`, { method, data, params, skipErrorHandler });
    }
    export type SendDigiFeedbackAPIPayload = {
        description: string;
        imageUrls: string[];
        contact: string;
        type: DigiFeedbackType;
    };
    export function sendDigiFeedback(payload: SendDigiFeedbackAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["description"] = payload["description"];
        data["imageUrls"] = payload["imageUrls"];
        data["contact"] = payload["contact"];
        data["type"] = payload["type"];
        return request<ResponseSuccess<boolean>>(`/api/digi/feedback`, { method, data, params, skipErrorHandler });
    }
    export type BindDigiAppleAccountAPIPayload = {
        code: string;
    };
    export function bindDigiAppleAccount(payload: BindDigiAppleAccountAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["code"] = payload["code"];
        return request<ResponseSuccess<boolean>>(`/api/digi/appleAccount`, { method, data, params, skipErrorHandler });
    }
    export type UnbindDigiAppleAccountAPIPayload = {};
    export function unbindDigiAppleAccount(payload: UnbindDigiAppleAccountAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<void>>(`/api/digi/appleAccount/delete`, { method, data, params, skipErrorHandler });
    }
    export type LoginDigiWithAppleAPIPayload = {
        code: string;
    };
    export function loginDigiWithApple(payload: LoginDigiWithAppleAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["code"] = payload["code"];
        return request<ResponseSuccess<DigiLoginResponse>>(`/api/digi/loginWithApple`, { method, data, params, skipErrorHandler });
    }
    export type GetDigiReportDataAPIPayload = {
        date: string;
        type: DigiDataType;
    };
    export function getDigiReportData(payload: GetDigiReportDataAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["date"] = `${payload["date"]}`;
        params["type"] = `${payload["type"]}`;
        return request<ResponseSuccess<DigiReportData>>(`/api/digi/data`, { method, data, params, skipErrorHandler });
    }
    export type BindSpotAPIPayload = {
        type: DigiBindType;
        value: string;
        force?: boolean;
    };
    export function bindSpot(payload: BindSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["type"] = payload["type"];
        data["value"] = payload["value"];
        data["force"] = payload["force"];
        return request<ResponseSuccess<DigiBindResult>>(`/api/digi/bind_spot`, { method, data, params, skipErrorHandler });
    }
    export type UnbindSpotAPIPayload = {};
    export function unbindSpot(payload: UnbindSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<void>>(`/api/digi/unbind_spot`, { method, data, params, skipErrorHandler });
    }
    export type GetDigiUserInfoAPIPayload = {};
    export function getDigiUserInfo(payload: GetDigiUserInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<DigiLoginResponse>>(`/api/digi/user`, { method, data, params, skipErrorHandler });
    }
    export type UpdateDigiSettingsAPIPayload = {
        standingHeight?: number;
        sittingHeight?: number;
        reminderSwitch?: DigiReminderSwitch;
        reminderTypes?: DigiReminderType[];
        reminderInterval?: number;
        heightUnit?: DigiHeightUnit;
    };
    export function updateDigiSettings(payload: UpdateDigiSettingsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["standingHeight"] = payload["standingHeight"];
        data["sittingHeight"] = payload["sittingHeight"];
        data["reminderSwitch"] = payload["reminderSwitch"];
        data["reminderTypes"] = payload["reminderTypes"];
        data["reminderInterval"] = payload["reminderInterval"];
        data["heightUnit"] = payload["heightUnit"];
        return request<ResponseSuccess<void>>(`/api/digi/settings`, { method, data, params, skipErrorHandler });
    }
    export type UpdateDigiUserInfoAPIPayload = {
        name?: string;
        gender?: UserGender;
        avatar?: string;
        height?: number;
        weight?: number;
    };
    export function updateDigiUserInfo(payload: UpdateDigiUserInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["name"] = payload["name"];
        data["gender"] = payload["gender"];
        data["avatar"] = payload["avatar"];
        data["height"] = payload["height"];
        data["weight"] = payload["weight"];
        return request<ResponseSuccess<void>>(`/api/digi/user_info`, { method, data, params, skipErrorHandler });
    }
    export type LoginDigiWithWechatAPIPayload = {
        auth_code: string;
    };
    export function loginDigiWithWechat(payload: LoginDigiWithWechatAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["auth_code"] = payload["auth_code"];
        return request<ResponseSuccess<DigiLoginResponse>>(`/api/digi/loginWithWechat`, { method, data, params, skipErrorHandler });
    }
    export type BindWechatAPIPayload = {
        auth_code: string;
    };
    export function bindWechat(payload: BindWechatAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["auth_code"] = payload["auth_code"];
        return request<ResponseSuccess<DigiBindWechatResult>>(`/api/digi/bindWechat`, { method, data, params, skipErrorHandler });
    }
    export type UnbindWechatAPIPayload = {};
    export function unbindWechat(payload: UnbindWechatAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<void>>(`/api/digi/unbindWechat`, { method, data, params, skipErrorHandler });
    }
    export type BindDigiPhoneAPIPayload = {
        phone: string;
        phonePrefix: string;
        code: string;
        token: string;
    };
    export function bindDigiPhone(payload: BindDigiPhoneAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["phone"] = payload["phone"];
        data["phonePrefix"] = payload["phonePrefix"];
        data["code"] = payload["code"];
        data["token"] = payload["token"];
        return request<ResponseSuccess<DigiLoginResponse>>(`/api/digi/bindPhone`, { method, data, params, skipErrorHandler });
    }
    export type LoginDigiAPIPayload = {
        phone: string;
        phonePrefix: string;
        code: string;
    };
    export function loginDigi(payload: LoginDigiAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["phone"] = payload["phone"];
        data["phonePrefix"] = payload["phonePrefix"];
        data["code"] = payload["code"];
        return request<ResponseSuccess<DigiLoginResponse>>(`/api/digi/login`, { method, data, params, skipErrorHandler });
    }
}
