// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 修改密码
  'change.two.message': '两次密码输入不一致，请重新输入！',
  'change.password': '修改密码',
  'change.original.password': '原始密码',
  'change.original.password-text': '请输入原始密码',
  'change.original.password-message': '密码格式错误',
  'change.new.password': '新密码',
  'change.new.password.text': '请输入新密码',
  'change.confirm.new-password': '确认新密码',
  'change.confirm.new-password-text': '请再次输入新密码',
  'change.certain': '确认修改',
};
