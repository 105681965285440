// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { Tag } from '@/services';
import { TagItem } from '@ixam/apis/types';
import { useCallback, useState } from 'react';

const { add, deletes, list, update } = Tag;

export default () => {
  const [tagList, setTagList] = useState<TagItem[]>([]);

  const getTagList = useCallback(async (payload: Tag.ListAPIPayload) => {
    const data = (await list({ ...payload })).data;
    setTagList(data);
  }, []);

  const createTag = useCallback(async (payload: Tag.AddAPIPayload) => {
    await add({ ...payload });
  }, []);

  const delTag = useCallback(async (payload: Tag.DeletesAPIPayload) => {
    await deletes({ ...payload });
  }, []);

  const editTag = useCallback(async (payload: Tag.UpdateAPIPayload) => {
    await update({ ...payload });
  }, []);

  return { tagList, getTagList, createTag, delTag, editTag };
};
