// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'menu.project.switch': 'Select Project',
  'menu.home': 'Home',
  'menu.statistics.realtime': 'Real-time Floor Plan',
  'menu.statistics.workstation': 'Real-time Layout',
  'menu.statistics.dashboard': 'Visualization',
  'menu.desk': '디지털 워크스테이션',
  'menu.management.lift': 'Standing Desk Analytics',
  'menu.statistics.liftAnalysis': 'Standing Desk Overview',
  'menu.statistics.healthAnalysis': 'Wellness Analytics',
  'menu.spotBook': '작업 공간 예약',
  'menu.statistics.spotBook': '작업 공간 예약',
  'menu.management.spotBook': '작업 공간 예약 기록',
  'menu.statistics.spotReserveAnalytics': '작업 공간 예약 보고서',
  'menu.statistics.freeBookDashboard': 'Realtime Booking Dashboard',
  'menu.management.spaceSpotAssign': 'Desk Assignment',
  'menu.management.spaceAsset': 'Asset Management',
  'menu.management.smartPower': 'Energy Management',
  'menu.management.batchControl': 'Bulk Height Adjust',
  'menu.management.inventory': 'Inventory Management',
  'menu.analysis': 'Space Analysis',
  'menu.statistics.overview': 'Overview',
  'menu.statistics.deskAnalytics': 'Desk Analytics',
  'menu.statistics.spaceUsage': 'Desk',
  'menu.statistics.departmentAnalytics': 'Desk by Departments',
  'menu.statistics.regionAnalytics': 'Desk by Areas',
  'menu.statistics.heatmap': 'Heat Map by Spot',
  'menu.statistics.useRate.heatmap': 'Heat Map by Area',
  'menu.statistics.meetintRoom': 'Meeting Room Analytics',
  'menu.statistics.meeting': 'Meeting Room Overview',
  'menu.statistics.meetingRoomCount': 'Headcount Analytics',
  'menu.general': 'General',
  'menu.statistics.status': 'Spot Real-time Status',
  'menu.statistics.spotStatus': 'Spot Real-time Status (Extended)',
  'menu.statistics.data': 'Data Marking',
  'menu.device.spot': 'Spot Management',
  'menu.management.tagManagement': 'Tag Management',
  'menu.device.sensor': 'Device Management',
  'menu.management.person': 'User Management',
  'menu.developer': 'Developer Configuration',
  'menu.management.smartPowerDemo': 'Demo',
  'menu.management.smartPowerDeviceManagement': 'Device Management',
  'menu.management.smartPowerPowerMonitoring': 'Realtime Power Monitoring',
  'menu.management.smartPowerEnergyAnalysis': 'Energy Analysis',
  'menu.management.smartPower.deviceManagement': 'Device Management',
  'menu.management.smartPower.powerMonitoring': 'Realtime Power Monitoring',
  'menu.management.smartPower.energyAnalysis': 'Energy Analysis',
  'menu.changeAccountInfo': 'Change AccountInfo',
  'menu.changePassword': 'Change Password',
  'menu.reset.password': '【9am】Find Password',
  'menu.external.RetrievePassword': '【9am】Find Password',
  'menu.external.ResetPassword': '【9am】Set New Password',
  'menu.statistics.data-marking': 'Data Marking',
  'menu.statistics.spotRealtimeStatus': 'Real-time Workstation Status',
};
