/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { Lang } from "@ixam/apis/types/constant";
// @ts-ignore
import { ProductNewsInfo } from "@ixam/apis/types/product-news";
export namespace ProductNews {
    export type GetProductNewsListAPIPayload = {
        lang: Lang;
    };
    export function getProductNewsList(payload: GetProductNewsListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = undefined;
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["lang"] = `${payload["lang"]}`;
        return request<ResponseSuccess<ProductNewsInfo[]>>(`/api/productNews/list`, { method, data, params, skipErrorHandler });
    }
}
