/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { AdminHomeRankData, AdminHomeTrendData } from "@ixam/apis/types/admin";
// @ts-ignore
import { OfflineTime } from "@ixam/apis/types/constant";
// @ts-ignore
import { SpacePerms, UserRole } from "@ixam/apis/types/perms";
// @ts-ignore
import { SpotStatusListItem } from "@ixam/apis/types/spot";
export namespace Spot {
    export type GetAdminHomeTrendAPIPayload = {
        offlineTime: OfflineTime;
    };
    export function getAdminHomeTrend(payload: GetAdminHomeTrendAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["offlineTime"] = `${payload["offlineTime"]}`;
        return request<ResponseSuccess<AdminHomeTrendData>>(`/api/admin/home/trend`, { method, data, params, skipErrorHandler });
    }
    export type GetAdminHomeRankAPIPayload = {
        offlineTime: OfflineTime;
    };
    export function getAdminHomeRank(payload: GetAdminHomeRankAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["offlineTime"] = `${payload["offlineTime"]}`;
        return request<ResponseSuccess<AdminHomeRankData>>(`/api/admin/home/rank`, { method, data, params, skipErrorHandler });
    }
    export type AllocateSpotsAPIPayload = {
        companyId: number;
        spotIds: string[];
    };
    export function allocateSpots(payload: AllocateSpotsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["companyId"] = payload["companyId"];
        data["spotIds"] = payload["spotIds"];
        return request<ResponseSuccess<void>>(`/api/admin/resource/change_project`, { method, data, params, skipErrorHandler });
    }
    export type GetSpotStatusDataAPIPayload = {
        companyId?: number;
    };
    export function getSpotStatusData(payload: GetSpotStatusDataAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["companyId"] = payload["companyId"];
        return request<ResponseSuccess<SpotStatusListItem[]>>(`/api/resource/status`, { method, data, params, skipErrorHandler });
    }
    export type SetSpotUpgradableAPIPayload = {
        spotIds: string[];
        upgradable: boolean;
    };
    export function setSpotUpgradable(payload: SetSpotUpgradableAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["spotIds"] = payload["spotIds"];
        data["upgradable"] = payload["upgradable"];
        return request<ResponseSuccess<void>>(`/api/admin/resource/upgradable`, { method, data, params, skipErrorHandler });
    }
    export type BatchAssignTagsToSpotsAPIPayload = {
        data: {
            spotId: string;
            tagIds: number[];
        }[];
    };
    export function batchAssignTagsToSpots(payload: BatchAssignTagsToSpotsAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["data"] = payload["data"];
        return request<ResponseSuccess<void>>(`/api/spots/-/tags:batchAssign`, { method, data, params, skipErrorHandler });
    }
}
