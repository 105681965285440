/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { CompanyBaseItem, CompanyStatisticTime } from "@ixam/apis/types/company";
// @ts-ignore
import { Menu, MobileLoginResult } from "@ixam/apis/types/login";
// @ts-ignore
import { Project } from "@ixam/apis/types/partner-project";
// @ts-ignore
import { SpacePerms, UserRole } from "@ixam/apis/types/perms";
export namespace Company {
    export type GetStatisticTimeAPIPayload = {};
    export function getStatisticTime(payload: GetStatisticTimeAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<CompanyStatisticTime>>(`/api/company/statisticTime`, { method, data, params, skipErrorHandler });
    }
    export type PermissionListAPIPayload = {
        companyId: number;
    };
    export function permissionList(payload: PermissionListAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["companyId"] = payload["companyId"];
        return request<ResponseSuccess<Menu[]>>(`/api/permissions/list`, { method, data, params, skipErrorHandler });
    }
    export type GetDigiUserCompanysAPIPayload = {};
    export function getDigiUserCompanys(payload: GetDigiUserCompanysAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<CompanyBaseItem[]>>(`/api/digi/user/companys`, { method, data, params, skipErrorHandler });
    }
    export type SwitchProjectAPIPayload = {
        projectId: number;
    };
    export function switchProject(payload: SwitchProjectAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["projectId"] = payload["projectId"];
        return request<ResponseSuccess<{
            token: string;
        }>>(`/api/digi/user/switchProject`, { method, data, params, skipErrorHandler });
    }
    export type ChangeProjectIdAPIPayload = {
        projectId: number;
    };
    export function changeProjectId(payload: ChangeProjectIdAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["projectId"] = payload["projectId"];
        return request<ResponseSuccess<MobileLoginResult>>(`/api/reservation/user/changeProjectId`, { method, data, params, skipErrorHandler });
    }
    export type GetUserProjectsAPIPayload = {};
    export function getUserProjects(payload: GetUserProjectsAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<CompanyBaseItem[]>>(`/api/reservation/user/projects`, { method, data, params, skipErrorHandler });
    }
    export type UpdateAPIPayload = {
        settings: Partial<Project>;
    };
    export function update(payload: UpdateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["settings"] = payload["settings"];
        return request<ResponseSuccess<void>>(`/api/projects`, { method, data, params, skipErrorHandler });
    }
    export type GetAPIPayload = {};
    export function get(payload: GetAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<Project>>(`/api/projects`, { method, data, params, skipErrorHandler });
    }
}
