// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 平面图管理
  'floor-plan.workplace': '직장',
  'floor-plan.floor': '층수',
  'floor-plan.area': '영역',
  'floor-plan.coverage': '커버 면적',
  'floor-plan.add.area-label': 'Area',
  'floor-plan.add.coverage-label': 'Covered Area',
  'floor-plan.select': 'Choose a Floor Plan',
  'floor-plan.edit.expand': 'Expand',
  'floor-plan.edit.collapse': 'Evict',
  'floor-plan.select.available-space': 'Type of Spots',
  'floor-plan.book-not-found-all': '모든 평면도에서 이 작업 공간을 찾을 수 없습니다',
  'floor-plan.book-not-found-current': '워크스테이션은 현재 요금제에는 없지만 플랜 {floorPlans}에는 있습니다',
  'floor-plan.book-found-all': '워크스테이션은 현재 평면도 및 평면도 {floorPlans}에서도 찾을 수 있습니다',
  'floor-plan.book-found-current': '현재 평면도에서 이 작업 공간을 찾았습니다',
  'floor-plan.book-point': ',',
};
