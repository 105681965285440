/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { SpacePerms, UserRole } from "@ixam/apis/types/perms";
// @ts-ignore
import { TagItem } from "@ixam/apis/types/tag";
// @ts-ignore
import { TagType } from "@ixam/apis/types/constant";
export namespace Tag {
    export type ListAPIPayload = {};
    export function list(payload: ListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<TagItem[]>>(`/api/tags`, { method, data, params, skipErrorHandler });
    }
    export type DeletesAPIPayload = {
        tagIds: number[];
    };
    export function deletes(payload: DeletesAPIPayload, skipErrorHandler?: boolean) {
        const method = "delete";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["tagIds"] = payload["tagIds"].map((item) => `${item}`);
        return request<ResponseSuccess<void>>(`/api/tags`, { method, data, params, skipErrorHandler });
    }
    export type AddAPIPayload = {
        tagList: {
            tagName: string;
            tagType: TagType;
        }[];
    };
    export function add(payload: AddAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["tagList"] = payload["tagList"];
        return request<ResponseSuccess<void>>(`/api/tags`, { method, data, params, skipErrorHandler });
    }
    export type UpdateAPIPayload = {
        tagId: number;
        tagName: string;
        tagType: TagType;
        force?: boolean;
    };
    export function update(payload: UpdateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["tagId"] = payload["tagId"];
        data["tagName"] = payload["tagName"];
        data["tagType"] = payload["tagType"];
        data["force"] = payload["force"];
        return request<ResponseSuccess<void>>(`/api/tags`, { method, data, params, skipErrorHandler });
    }
}
