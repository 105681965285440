// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 登录
  'login.title': '로그인-9am 인텔리전트 사무',
  'login.image': 'Background Picture',
  'login.image.backchart': 'backchart_ko.png',
  'login.mobile.placeholder': '핸드폰 번호',
  'login.mobile.required': '핸드폰 번호를 입력하세요',
  'login.mobile.valid': '핸드폰 번호 형식 오류',
  'login.mail.placeholder': '이메일 주소',
  'login.mail.required': '이메일 주소를 입력하세요',
  'login.mail.valid': '이메일 주소 형식 오류',
  'login.password.placeholder': '패스워드',
  'login.password.required': '패스워드를 입력하세요',
  'login.password.valid': '잘못된 비밀번호 형식',
  'login.captcha.placeholder': '인증 코드',
  'login.captcha.required': '인증 코드를 입력하세요',
  'login.captcha.another': '잘 보이지 않으니 바꾸세요',
  'login.type.mail': '이메일 로그인',
  'login.type.mobile': '휴대폰 로그인',
  'login.find.password': '비밀번호 찾기',
  'login.submit': '로그인',
  'login.to-partner': '파트너 플랫폼',
  'login.count.3-more-chances':
    '응모 기회가 3번 더 있으며, 연속으로 세 번 잘못 입력하면 24시간 동안 응모가 정지됩니다.',
  'login.account.frozen': '계정이 정지되었습니다, {time} 후에 다시 로그인하세요',
};
