/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { FileType } from "@ixam/apis/types/_types";
// @ts-ignore
import { FloorPlanItem } from "@ixam/apis/types/floor-plan";
// @ts-ignore
import { Lang } from "@ixam/apis/types/constant";
// @ts-ignore
import { SpaceSpotAssignItem } from "@ixam/apis/types/space-spot-assign";
export namespace SpaceSpotAssign {
    export type GetSpotAssignListAPIPayload = {
        floorPlanId: number;
    };
    export function getSpotAssignList(payload: GetSpotAssignListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["floorPlanId"] = `${payload["floorPlanId"]}`;
        return request<ResponseSuccess<SpaceSpotAssignItem[]>>(`/api/space/spotAssign/list`, { method, data, params, skipErrorHandler });
    }
    export type SingleSpotAssignAPIPayload = {
        floorPlanId: number;
        spotId: string;
        assigneeId: string | null;
        deskInfo: string | null;
        employeeInfo: string | null;
        remark?: string;
    };
    export function singleSpotAssign(payload: SingleSpotAssignAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["floorPlanId"] = payload["floorPlanId"];
        data["spotId"] = payload["spotId"];
        data["assigneeId"] = payload["assigneeId"];
        data["deskInfo"] = payload["deskInfo"];
        data["employeeInfo"] = payload["employeeInfo"];
        data["remark"] = payload["remark"];
        return request<ResponseSuccess<void>>(`/api/space/spotAssign/single`, { method, data, params, skipErrorHandler });
    }
    export type DownloadSpotAssignTemplateAPIPayload = {
        floorPlanId: number;
        lang: Lang;
    };
    export function downloadSpotAssignTemplate(payload: DownloadSpotAssignTemplateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["floorPlanId"] = payload["floorPlanId"];
        data["lang"] = payload["lang"];
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/space/spotAssign/get_template`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type BatchSpotAssignAPIPayload = {
        floorPlanId: number;
        file: File;
    };
    export function batchSpotAssign(payload: BatchSpotAssignAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data = new FormData();
        data.append("token", token);
        data.append("floorPlanId", payload["floorPlanId"]);
        data.append("file", payload["file"]);
        return request<ResponseSuccess<{
            total_count: number;
            success_count: number;
        }>>(`/api/space/spotAssign/batch`, { method, data, params, skipErrorHandler });
    }
    export type SpaceSpotAssignSearchAPIPayload = {
        param: string;
    };
    export function spaceSpotAssignSearch(payload: SpaceSpotAssignSearchAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["param"] = `${payload["param"]}`;
        return request<ResponseSuccess<FloorPlanItem[]>>(`/api/space/spotAssign/search`, { method, data, params, skipErrorHandler });
    }
}
