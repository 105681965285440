// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 平面图管理
  'floor-plan.workplace': 'Building',
  'floor-plan.floor': 'Floor',
  'floor-plan.area': 'Area',
  'floor-plan.coverage': 'Covered Area',
  'floor-plan.add.area-label': 'Area',
  'floor-plan.add.coverage-label': 'Covered Area',
  'floor-plan.select': 'Choose a Floor Plan',
  'floor-plan.edit.expand': 'Expand',
  'floor-plan.edit.collapse': 'Evict',
  'floor-plan.select.available-space': 'Type of Spots',
  'floor-plan.book-not-found-all': 'No workstations found in all floor plans.',
  'floor-plan.book-not-found-current':
    'No workstations found in current floor plan, but found in floor plan {floorPlans}.',
  'floor-plan.book-found-all': 'Workstations found in current floor plan, and also found in floor plan {floorPlans}.',
  'floor-plan.book-found-current': 'Workstation found in current floor plan.',
  'floor-plan.book-point': ',',
};
