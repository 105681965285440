// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 标签管理
  'tag.department': '部门',
  'tag.area': '区域',
  'tag.other': '其他',
  'tag.tagType': '标签类别',
  'tag.tagName': '标签名称',
  'tag.addTag': '点击创建，添加标签',
  'tag.clear': '清空',
  'tag.addSuccess': '创建成功',
  'tag.editSuccess': '编辑成功',
  'tag.editFail.repeat': '修改失败，该类别已存在相同名称的标签',
  'tag.editFail.fail': '修改失败',
  'tag.editFail.typeError': '拥有该标签的部分点位已拥有相同类别标签',
  'tag.edit.force':
    '拥有该标签的部分点位已有部门/区域标签，如果修改该标签类别，将删除这些点位的该标签，本操作将无法撤销',
  'tag.delSuccess': '删除成功',
  'tag.create': '创建',
  'tag.deleteWarnTit': '删除提示',
  'tag.deleteWarnContent': '标签删除后将不可恢复，确定要删除吗？',
  'tag.clearWarnTit': '清空提示',
  'tag.clearWarnContent': '批量清空后将不可恢复，确定要清空吗？',
  'tag.editModalTit': '信息修改',
  'tag.createTag': '创建标签',
  'tag.new-tag': '新标签',
  'tag.add-new-tag': '添加新标签',
  'tag.name': '标签',
  'tag.enter-content': '输入标签内容',
  'tag.enter-name': '请输入标签名',
  'tag.choose-type': '请选择标签类别',
};
