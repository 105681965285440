/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { AdminSensorInfo, ImportSensorResult, PartnerSensorInfo, ProjectSensorInfo, SensorOfflineSubscribeInfo, SensorPowerLevelInfo, SensorPowerSubscription, SensorStatItem, SensorStatRankItem, SensorStatTrendItem, SensorType, SupplyVoltageItem, } from "@ixam/apis/types/sensor";
// @ts-ignore
import { FileType } from "@ixam/apis/types/_types";
// @ts-ignore
import { QueryType, SensorStatStatus } from "@ixam/apis/types/constant";
export namespace Sensor {
    export type BatchAssignAPIPayload = {
        sensorTags: string[];
        partnerId?: number;
    };
    export function batchAssign(payload: BatchAssignAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["sensorTags"] = payload["sensorTags"];
        data["partnerId"] = payload["partnerId"];
        return request<ResponseSuccess<void>>(`/api/admin/sensor/batch_assign`, { method, data, params, skipErrorHandler });
    }
    export type RemarkAPIPayload = {
        sensorId: string;
        text: string;
    };
    export function remark(payload: RemarkAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["text"] = payload["text"];
        return request<ResponseSuccess<void>>(`/api/sensor/remark/${payload.sensorId}`, { method, data, params, skipErrorHandler });
    }
    export type GetAdminSensorListAPIPayload = {
        query?: string;
    };
    export function getAdminSensorList(payload: GetAdminSensorListAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["query"] = payload["query"];
        return request<ResponseSuccess<AdminSensorInfo[]>>(`/api/admin/sensor/list`, { method, data, params, skipErrorHandler });
    }
    export type SensorBatchImportAPIPayload = {
        file: File;
    };
    export function sensorBatchImport(payload: SensorBatchImportAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data = new FormData();
        data.append("token", token);
        data.append("file", payload["file"]);
        return request<ResponseSuccess<ImportSensorResult>>(`/api/admin/sensor/import`, { method, data, params, skipErrorHandler });
    }
    export type ForceImportAndDownloadErrorListAPIPayload = {
        file: File;
    };
    export function forceImportAndDownloadErrorList(payload: ForceImportAndDownloadErrorListAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data = new FormData();
        data.append("token", token);
        data.append("file", payload["file"]);
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/admin/sensor/downloadErrorList`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type AddSensorsAPIPayload = {
        sensors: {
            tag: string;
            id: string;
            mac?: string;
        }[];
        partnerId?: number;
        remark?: string;
    };
    export function addSensors(payload: AddSensorsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["sensors"] = payload["sensors"];
        data["partnerId"] = payload["partnerId"];
        data["remark"] = payload["remark"];
        return request<ResponseSuccess<void>>(`/api/admin/sensor/bulk_create`, { method, data, params, skipErrorHandler });
    }
    export type GetSensorStatisticsAPIPayload = {
        sensorStatStatus: SensorStatStatus;
        projectIds?: number[];
    };
    export function getSensorStatistics(payload: GetSensorStatisticsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["sensorStatStatus"] = payload["sensorStatStatus"];
        data["projectIds"] = payload["projectIds"];
        return request<ResponseSuccess<SensorStatItem>>(`/api/sensor_stat/statistics`, { method, data, params, skipErrorHandler });
    }
    export type GetSensorStatTrendAPIPayload = {
        sensorStatStatus: SensorStatStatus;
        dateType: QueryType;
        projectIds?: number[];
    };
    export function getSensorStatTrend(payload: GetSensorStatTrendAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["sensorStatStatus"] = payload["sensorStatStatus"];
        data["dateType"] = payload["dateType"];
        data["projectIds"] = payload["projectIds"];
        return request<ResponseSuccess<SensorStatTrendItem[]>>(`/api/sensor_stat/trend`, { method, data, params, skipErrorHandler });
    }
    export type GetSensorOfflineDurationRankAPIPayload = {
        sensorStatStatus: SensorStatStatus;
        dateType: QueryType;
        projectIds?: number[];
    };
    export function getSensorOfflineDurationRank(payload: GetSensorOfflineDurationRankAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["sensorStatStatus"] = payload["sensorStatStatus"];
        data["dateType"] = payload["dateType"];
        data["projectIds"] = payload["projectIds"];
        return request<ResponseSuccess<SensorStatRankItem[]>>(`/api/sensor_stat/offline_duration_rank`, { method, data, params, skipErrorHandler });
    }
    export type DeleteSensorsAPIPayload = {
        sensorIds: string[];
    };
    export function deleteSensors(payload: DeleteSensorsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["sensorIds"] = payload["sensorIds"];
        return request<ResponseSuccess<void>>(`/api/admin/sensor/delete`, { method, data, params, skipErrorHandler });
    }
    export type GetPartnerSensorListAPIPayload = {
        query?: string;
    };
    export function getPartnerSensorList(payload: GetPartnerSensorListAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["query"] = payload["query"];
        return request<ResponseSuccess<PartnerSensorInfo[]>>(`/api/partner/sensor/list`, { method, data, params, skipErrorHandler });
    }
    export type GetSensorOfflineSubscribeInfoAPIPayload = {};
    export function getSensorOfflineSubscribeInfo(payload: GetSensorOfflineSubscribeInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<SensorOfflineSubscribeInfo>>(`/api/partner/sensor/offline/subscribe`, { method, data, params, skipErrorHandler });
    }
    export type SubscribeSensorOfflineAPIPayload = {
        receivers: string[];
        subscribed: boolean;
        periods: string[];
    };
    export function subscribeSensorOffline(payload: SubscribeSensorOfflineAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["receivers"] = payload["receivers"];
        data["subscribed"] = payload["subscribed"];
        data["periods"] = payload["periods"];
        return request<ResponseSuccess<void>>(`/api/partner/sensor/offline/subscribe`, { method, data, params, skipErrorHandler });
    }
    export type GetSensorPowerAPIPayload = {
        types: SensorType[];
        inService: boolean;
        query?: string;
        companyIds?: number[];
    };
    export function getSensorPower(payload: GetSensorPowerAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["types"] = payload["types"];
        data["inService"] = payload["inService"];
        data["query"] = payload["query"];
        data["companyIds"] = payload["companyIds"];
        return request<ResponseSuccess<SensorPowerLevelInfo[]>>(`/api/partner/sensor/power`, { method, data, params, skipErrorHandler });
    }
    export type GetSensorPowerSubscriptionsAPIPayload = {};
    export function getSensorPowerSubscriptions(payload: GetSensorPowerSubscriptionsAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<SensorPowerSubscription>>(`/api/partner/sensor/power/subscribe`, { method, data, params, skipErrorHandler });
    }
    export type UpdateSensorPowerSubscriptionAPIPayload = {
        receivers: string[];
        subscribed: boolean;
    };
    export function updateSensorPowerSubscription(payload: UpdateSensorPowerSubscriptionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["receivers"] = payload["receivers"];
        data["subscribed"] = payload["subscribed"];
        return request<ResponseSuccess<void>>(`/api/partner/sensor/power/subscribe`, { method, data, params, skipErrorHandler });
    }
    export type GetProjectSensorListAPIPayload = {
        query?: string;
    };
    export function getProjectSensorList(payload: GetProjectSensorListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["query"] = `${payload["query"]}`;
        return request<ResponseSuccess<ProjectSensorInfo[]>>(`/api/sensors`, { method, data, params, skipErrorHandler });
    }
    export type GetSensorSupplyVoltageAPIPayload = {
        sensorId: string;
        startTimestamp: number;
        endTimestamp: number;
    };
    export function getSensorSupplyVoltage(payload: GetSensorSupplyVoltageAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["sensorId"] = `${payload["sensorId"]}`;
        params["startTimestamp"] = `${payload["startTimestamp"]}`;
        params["endTimestamp"] = `${payload["endTimestamp"]}`;
        return request<ResponseSuccess<SupplyVoltageItem[]>>(`/api/sensor/supplyVoltage`, { method, data, params, skipErrorHandler });
    }
}
