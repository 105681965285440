// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'data-marking.title': 'Data Marking',
  'data-marking.spot-name': 'Spot Name',
  'data-marking.sensor-number': 'Device Code',
  'data-marking.offline-hour': 'Offline Hours',
  'data-marking.result': 'Result',
  'data-marking.marking': 'Marking',
  'data-marking.query': 'Spot ID / Spot Name / Device ID',
  'data-marking.marked-successfully': 'Marked successfully',
  'data-marking.marking-success': 'Determine whether the data is correct according to actual usage.Correct mark',
  'data-marking.marking-error': 'The error is marked as',
  'data-marking.station-number': 'Desk ID',
  'data-marking.typical-abnormal-situation-1': 'Typical abnormal situation 1:',
  'data-marking.typical-abnormal-situation-1-description':
    'It is not in front of the station for a certain period of time, and it is confirmed that no other person occupies the station, but it is displayed as occupied status in the status bar.',
  'data-marking.typical-abnormal-situation-2': 'Typical abnormal situation 2:',
  'data-marking.typical-abnormal-situation-2-description':
    'Some time before the station, but shown as idle on the status bar',
  'data-marking.typical-abnormal-situation-3': 'Typical abnormal situation 3:',
  'data-marking.typical-abnormal-situation-3-description':
    'The device is not powered but is shown as occupied or idle on the status bar',
  'data-marking.marking-warning': 'The current data is not marked effectively, please confirm and click save again',
  'data-marking.no-use': 'no use record, please choose another date.',
  'data-marking.human-count': 'Number',
  'data-marking.spot-id': 'Spot ID',
  'data-marking.occupancy-duration': 'Occupancy Rate',
  'data-marking.free.time': 'Free Hours',
  'data-marking.error-date-format': 'MMMM Do YYYY',
  'data-marking.tooltip-width-situation': '190px',
  'data-marking.tooltip-width-situation-description': '230px',
};
