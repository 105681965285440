/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BookResult, SpotBookItemOfFloorPlan, UserSpotBookItem } from "@ixam/apis/types/digi-spot-book";
// @ts-ignore
import { DaysFilter, Lang } from "@ixam/apis/types/constant";
// @ts-ignore
import { FileType } from "@ixam/apis/types/_types";
// @ts-ignore
import { FloorPlanItem } from "@ixam/apis/types/floor-plan";
// @ts-ignore
import { MobileLoginResult } from "@ixam/apis/types/login";
// @ts-ignore
import { SpacePerms, UserRole } from "@ixam/apis/types/perms";
export namespace SpaceSpotBook {
    export type GetSpaceSpotBookRecordAPIPayload = {
        floorPlanId: number;
        startTime: number;
        endTime: number;
    };
    export function getSpaceSpotBookRecord(payload: GetSpaceSpotBookRecordAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["floorPlanId"] = `${payload["floorPlanId"]}`;
        params["startTime"] = `${payload["startTime"]}`;
        params["endTime"] = `${payload["endTime"]}`;
        return request<ResponseSuccess<SpotBookItemOfFloorPlan[]>>(`/api/space/spot/floorPlanBookList`, { method, data, params, skipErrorHandler });
    }
    export type GetSpaceUserSpotBookListAPIPayload = {};
    export function getSpaceUserSpotBookList(payload: GetSpaceUserSpotBookListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<UserSpotBookItem[]>>(`/api/space/spot/userBookList`, { method, data, params, skipErrorHandler });
    }
    export type SpaceBookSpotAPIPayload = {
        spotId: string;
        startTime: number;
        endTime: number;
        isOnsite?: boolean;
    };
    export function spaceBookSpot(payload: SpaceBookSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["spotId"] = payload["spotId"];
        data["startTime"] = payload["startTime"];
        data["endTime"] = payload["endTime"];
        data["isOnsite"] = payload["isOnsite"];
        return request<ResponseSuccess<UserSpotBookItem>>(`/api/space/spot/userBookSpot`, { method, data, params, skipErrorHandler });
    }
    export type SpaceCancelBookSpotAPIPayload = {
        bookId: number;
    };
    export function spaceCancelBookSpot(payload: SpaceCancelBookSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["bookId"] = payload["bookId"];
        return request<ResponseSuccess<void>>(`/api/space/spot/userCancelBook`, { method, data, params, skipErrorHandler });
    }
    export type SpaceCheckInSpotAPIPayload = {
        bookId: number;
    };
    export function spaceCheckInSpot(payload: SpaceCheckInSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["bookId"] = payload["bookId"];
        return request<ResponseSuccess<void>>(`/api/space/spot/userCheckIn`, { method, data, params, skipErrorHandler });
    }
    export type SpaceCheckOutSpotAPIPayload = {
        bookId: number;
    };
    export function spaceCheckOutSpot(payload: SpaceCheckOutSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["bookId"] = payload["bookId"];
        return request<ResponseSuccess<void>>(`/api/space/spot/userCheckOut`, { method, data, params, skipErrorHandler });
    }
    export type SpaceSearchSpotAPIPayload = {
        param: string;
        startTime?: number;
        endTime?: number;
    };
    export function spaceSearchSpot(payload: SpaceSearchSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["param"] = `${payload["param"]}`;
        params["startTime"] = `${payload["startTime"]}`;
        params["endTime"] = `${payload["endTime"]}`;
        return request<ResponseSuccess<FloorPlanItem[]>>(`/api/space/spot/search`, { method, data, params, skipErrorHandler });
    }
    export type SpotBookListAPIPayload = {
        spotId: string;
        startTime: number;
        endTime: number;
    };
    export function spotBookList(payload: SpotBookListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["spotId"] = `${payload["spotId"]}`;
        params["startTime"] = `${payload["startTime"]}`;
        params["endTime"] = `${payload["endTime"]}`;
        return request<ResponseSuccess<BookResult[]>>(`/api/spot/reservation/list`, { method, data, params, skipErrorHandler });
    }
    export type ExportReservationAPIPayload = {
        startTimeStr: string;
        endTimeStr: string;
        workdayType: DaysFilter;
        lang: Lang;
    };
    export function exportReservation(payload: ExportReservationAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["startTimeStr"] = payload["startTimeStr"];
        data["endTimeStr"] = payload["endTimeStr"];
        data["workdayType"] = payload["workdayType"];
        data["lang"] = payload["lang"];
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/spot/reservation/export`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type SpotReservationsAPIPayload = {
        uuid: string;
    };
    export function spotReservations(payload: SpotReservationsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        return request<ResponseSuccess<{
            spotId: string;
            companyName: string;
            floorPlanName: string;
            reservation?: BookResult;
            loginResult: MobileLoginResult;
        }>>(`/api/spots/${payload.uuid}/reservations`, { method, data, params, skipErrorHandler });
    }
    export type SearchReversionDashboardAPIPayload = {
        assigneeId: string;
    };
    export function searchReversionDashboard(payload: SearchReversionDashboardAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["assigneeId"] = `${payload["assigneeId"]}`;
        return request<ResponseSuccess<FloorPlanItem[]>>(`/api/spot/-/dashboard:search`, { method, data, params, skipErrorHandler });
    }
    export type FuzzySearchReversionDashboardAPIPayload = {
        query: string;
    };
    export function fuzzySearchReversionDashboard(payload: FuzzySearchReversionDashboardAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["query"] = `${payload["query"]}`;
        return request<ResponseSuccess<FloorPlanItem[]>>(`/api/spot/-/dashboard:fuzzySearch`, { method, data, params, skipErrorHandler });
    }
}
