// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'spot-realtime-status.floor': '当前楼层',
  'spot-realtime-status.area': '当前区域',
  'spot-realtime-status.spot-id': '工位编号',
  'spot-realtime-status.none': '无',
  'spot-realtime-status.not-found-all': '在全部平面图上均未找到该工位。',
  'spot-realtime-status.not-found-current': '当前平面图未找到该工位，但在平面图{floorPlans}找到了该工位。',
  'spot-realtime-status.found-all': '当前平面图已找到该工位，同时在平面图{floorPlans}也找到了该工位。',
  'spot-realtime-status.found-current': '当前平面图已找到该工位。',
  'spot-realtime-status.all-spot': '全部工位',
  'spot-realtime-status.personal-spot': '固定工位',
  'spot-realtime-status.shared-spot': '共享工位',
  'spot-realtime-status.assigned-personal-spot': '已分配固定工位',
  'spot-realtime-status.unassigned-personal-spot': '未分配固定工位',
  'spot-realtime-status.assigned-shared-spot': '已预约共享工位',
  'spot-realtime-status.unassigned-shared-spot': '未预约共享工位',
  'spot-realtime-status.search-department-employee': '查找部门员工',
  'spot-realtime-status.name': '姓名',
  'spot-realtime-status.department': '部门',
  'spot-realtime-status.email': '邮箱',
  'spot-realtime-status.used-rate': '实时使用率',
};
