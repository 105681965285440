// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'sensor.spot': '所属点位',
  'sensor.in-one-day': '1天内',
  'sensor.within-1-3-days': '1-3天内',
  'sensor.within-3-7-days': '3-7天内',
  'sensor.within-7-30-days': '7-30天内',
  'sensor.>30-days': '>30天未联网',
  'sensor.list.last-time': '最后联网时间',
  'sensor.list.number-remark': '设备编号/点位编号/备注',
  'sensor.list.management': '设备管理',
};
