// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 修改密码
  'change.two.message': 'Passwords are  not matched, please type in again',
  'change.password': 'Change Password',
  'change.original.password': 'Original Password',
  'change.original.password-text': 'Please type in the original password',
  'change.original.password-message': 'Incorrect password format',
  'change.new.password': 'New Password',
  'change.new.password.text': 'Please enter a new password',
  'change.confirm.new-password': 'Confirm the password',
  'change.confirm.new-password-text': 'Please enter new password again',
  'change.certain': 'Confirm',
};
