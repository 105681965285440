// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { InventoryItem } from '@ixam/apis/types';
import { ScreenDeviceInventory } from '@/services';
import { useCallback, useState } from 'react';

const { addSpaceInventory, delSpaceInventory, editSpaceInventory, getSpaceInventoryList, topSpaceInventory } =
  ScreenDeviceInventory;

export default () => {
  const [loading, setLoading] = useState(false);
  const [inventoryList, setInventoryList] = useState<InventoryItem[]>([]);

  const getInventoryList = useCallback(async (payload: ScreenDeviceInventory.GetSpaceInventoryListAPIPayload) => {
    try {
      setLoading(true);
      const list = (await getSpaceInventoryList({ ...payload })).data;
      setInventoryList(list);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  const addInventory = useCallback(async (payload: ScreenDeviceInventory.AddSpaceInventoryAPIPayload) => {
    await addSpaceInventory({ ...payload });
  }, []);

  const editInventory = useCallback(async (payload: ScreenDeviceInventory.EditSpaceInventoryAPIPayload) => {
    await editSpaceInventory({ ...payload });
  }, []);

  const deleteInventory = useCallback(async (payload: ScreenDeviceInventory.DelSpaceInventoryAPIPayload) => {
    await delSpaceInventory({ ...payload });
  }, []);

  const topInventory = useCallback(async (payload: ScreenDeviceInventory.TopSpaceInventoryAPIPayload) => {
    await topSpaceInventory({ ...payload });
  }, []);

  return { loading, inventoryList, getInventoryList, addInventory, editInventory, deleteInventory, topInventory };
};
