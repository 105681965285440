/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { RealtimeData } from "@ixam/apis/types/realtime-status";
export namespace Desk {
    export type GetDeskRealtimeStatusAPIPayload = {
        floorPlanId: number;
    };
    export function getDeskRealtimeStatus(payload: GetDeskRealtimeStatusAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["floorPlanId"] = `${payload["floorPlanId"]}`;
        return request<ResponseSuccess<{
            resources: RealtimeData[];
        }>>(`/api/desk/realtime_status/data`, { method, data, params, skipErrorHandler });
    }
    export type SetBatchDeskHeightAPIPayload = {
        resourceIds: string[];
        height: number;
        floorPlanId: number;
    };
    export function setBatchDeskHeight(payload: SetBatchDeskHeightAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["resourceIds"] = payload["resourceIds"];
        data["height"] = payload["height"];
        data["floorPlanId"] = payload["floorPlanId"];
        return request<ResponseSuccess<void>>(`/api/desk/batch/height`, { method, data, params, skipErrorHandler });
    }
}
