// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  'all.token.invalid': 'Your identity has expired. Please log in again',
  'all.lang.chinese': '简体中文',
  'all.lang.english': 'English',
  'all.lang.korean': '한국어',
  'all.remarks': 'Remarks',
  'all.add-remarks': 'remarks',
  'all.serial.number': 'No.',
  'all.cancel': 'Cancel',
  'all.save': 'Save',
  'all.confirm': 'Confirm',
  'all.reset': 'Reset',
  'all.delete': 'Delete',
  'all.edit': 'Edit',
  'all.operating': 'Operate',
  'all.add': 'Add',
  'all.add.success': 'Successfully Added',
  // 字符限制
  'all.valid.max.long': 'Max Length: {count}',
  'all.valid.min.long': 'Min Length: {count}',
  'all.unit': ' ',
  'all.list.number': 'ID',
  'all.online': 'Online',
  'all.offline': 'Offline',
  'all.status': 'Status',
  'all.occupy': 'Occupied',
  'all.vacant': 'Vacant',

  'all.upload-success': 'Upload Successfully',
  'all.uploadFail': 'Fail to upload image',
  'all.time': 'Time',
  'all.minute': '{Min, plural,one{Min} other {Mins}}',
  'all.hour': '{Hour, plural,one{Hour} other {Hours}}',
  'all.unknown': 'Unknown',
};
