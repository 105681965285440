/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { MatchType, StatisticsType } from "@ixam/apis/types/constant";
// @ts-ignore
import { SmartPowerStatOverview } from "@ixam/apis/types/smart-power-analysis";
export namespace SmartPowerAnalysis {
    export type GetSmartPowerOverviewAPIPayload = {
        statPeriod: StatisticsType;
        somedayInPeriod: string;
        floorPlanId?: number;
        restrictTags?: number[];
        matchType?: MatchType;
    };
    export function getSmartPowerOverview(payload: GetSmartPowerOverviewAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["statPeriod"] = payload["statPeriod"];
        data["somedayInPeriod"] = payload["somedayInPeriod"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["restrictTags"] = payload["restrictTags"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<SmartPowerStatOverview>>(`/api/smartPowers/-/overview`, { method, data, params, skipErrorHandler });
    }
    export type GetSmartPowerStatisticsAPIPayload = {
        statPeriod: StatisticsType;
        somedayInPeriod: string;
        floorPlanId?: number;
        restrictTags?: number[];
        matchType?: MatchType;
    };
    export function getSmartPowerStatistics(payload: GetSmartPowerStatisticsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["statPeriod"] = payload["statPeriod"];
        data["somedayInPeriod"] = payload["somedayInPeriod"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["restrictTags"] = payload["restrictTags"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<{
            [key: string]: number;
        }>>(`/api/smartPowers/-/stat`, { method, data, params, skipErrorHandler });
    }
}
