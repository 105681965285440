// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { Common } from '@/services';
import { useCallback, useState } from 'react';

const { resetPassword: resetThePassword, sendEmail: sendEmailToUser } = Common;

export default () => {
  const sendEmail = useCallback(async (payload: Common.SendEmailAPIPayload) => {
    await sendEmailToUser({ ...payload });
  }, []);

  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const resetPassword = useCallback(async (payload: Common.ResetPasswordAPIPayload) => {
    try {
      setResetPasswordLoading(true);
      await resetThePassword({ ...payload });
    } catch (e) {
    } finally {
      setResetPasswordLoading(false);
    }
  }, []);
  return { sendEmail, resetPasswordLoading, resetPassword };
};
