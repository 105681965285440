// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { Lang, ProjectInfo, UserLoginResult, UserRole } from '@ixam/apis/types';
import { Login, Project } from '@/services';
import { getIntl, getLocale, history } from '@umijs/max';
import { message } from 'antd';
import { useCallback, useState } from 'react';
import G, { ErrorType } from '@/global';
import _ from 'lodash';
import moment from 'moment';
import storage from '@/utils/storage';

const { login: loginRequest, getCaptcha, getLoginKey, getSidebarList, logout: logoutRequest } = Login;
const { usersChangeProject, getUsersProjects } = Project;

const LOGIN_SHOW_CAPTCHA_ERROR_COUNT = 5;
const LOGIN_FROZEN_ERROR_COUNT = 10;
type EmailFormData = { email: string; password: string; code?: string };
type MobileFormData = { mobile: [string, string]; password: string; code?: string };
export type LoginFormData = EmailFormData & MobileFormData;

export default () => {
  // 验证码
  const [captchaData, setCaptchaData] = useState({ captchaId: '', svg: '' });
  const getCaptchaData = useCallback(async () => {
    const data = (await getCaptcha({})).data;
    setCaptchaData(data);
  }, []);
  // 错误次数
  const [errorCount, setErrorCount] = useState(0);
  const [hasAlerted3MoreChances, setHasAlerted3MoreChances] = useState(false);

  const refreshUser = useCallback(async (userInfo: UserLoginResult, checkMultiple: boolean) => {
    storage.user.save(userInfo);

    const sidebarList = (await getSidebarList({})).data;
    storage.sidebarList.save(sidebarList);

    if (userInfo.multipleCompany && checkMultiple && !userInfo.isEnterProjectByDefault) {
      window.location.href = '/project/switch';
      return;
    }

    let children = sidebarList;
    while (children[0] && children[0].children && children[0].children.length) {
      children = children[0].children;
    }

    if (_.isEmpty(children)) {
      window.location.href = '/exception/403';
      return;
    }

    window.location.href = children[0].path;
  }, []);

  const [loginLoading, setLoginLoading] = useState(false);
  const login = useCallback(
    async (data: LoginFormData) => {
      try {
        setLoginLoading(true);
        const publicKey = (await getLoginKey({}, true)).data.key;
        const encryptedPassword = await encryptPassword(publicKey, data.password);
        const payload: Login.LoginAPIPayload = {
          password: encryptedPassword,
          encrypted: true,
          code: data.code,
          userAccount: data.mobile ? data.mobile[1] : data.email,
          role: UserRole.USER,
        };
        if (data.mobile) payload.phonePrefix = data.mobile[0];
        if (errorCount >= LOGIN_SHOW_CAPTCHA_ERROR_COUNT) payload.captchaId = captchaData.captchaId;
        const userInfo = (await loginRequest(payload, true)).data as UserLoginResult;
        setErrorCount(0);
        if (userInfo.language && !localStorage.getItem('umi_locale'))
          localStorage.setItem('umi_locale', userInfo.language);
        await refreshUser(userInfo, true);
      } catch (error: any) {
        const { formatMessage } = getIntl();
        const code: ErrorType = error?.response?.data?.code;
        const errorCount = error?.response?.data?.data?.errorCount;
        const expireTime = error?.response?.data?.data?.expireTime;
        // errorCount > 5
        // but refresh the browser
        // get code
        // no error display
        if (code === G.errors.CODE_CANNOT_BE_FOUND) {
          // empty
        }
        // errorCount
        // remain three more chances
        // only alert one time
        // for example, alerted, but the user has a wrong captcha, errorCount is still 7, do not alert
        else if (errorCount === LOGIN_FROZEN_ERROR_COUNT - 3 && !hasAlerted3MoreChances) {
          setHasAlerted3MoreChances(true);
          message.error(formatMessage({ id: 'login.count.3-more-chances' }), 5);
        }
        // account is frozen
        // tell the specific time
        else if (code === G.errors.ACCOUNT_HAS_BEEN_FROZEN) {
          message.error(
            formatMessage({ id: 'login.account.frozen' }, { time: moment.duration(expireTime!, 'seconds').humanize() }),
            5
          );
        }
        // default error
        // by error code
        // account error
        // password erro
        // captcha error
        else {
          const locale: Lang = getLocale();
          const errMessage = G.errorList[code];
          message.error((errMessage as any)?.[locale] || 'error');
        }
        if (!errorCount) return;
        setErrorCount(errorCount);
        if (errorCount >= LOGIN_SHOW_CAPTCHA_ERROR_COUNT && errorCount <= LOGIN_FROZEN_ERROR_COUNT)
          await getCaptchaData();
      } finally {
        setLoginLoading(false);
      }
    },
    [captchaData, errorCount, refreshUser, hasAlerted3MoreChances, getCaptchaData]
  );

  const [userProjects, setUserProjects] = useState<ProjectInfo[]>([]);
  const getUserProjects = useCallback(async () => {
    const data = (await getUsersProjects({})).data;
    setUserProjects(data);
  }, []);
  const userChangeProject = useCallback(
    async (project: ProjectInfo) => {
      const { companyId, companyName } = project;
      const { language, headerLogo, headerColor, footerLogo, footerName, footerCopyright, footerUrl } = project;
      const projectPref = { language, headerLogo, headerColor, footerLogo, footerName, footerCopyright, footerUrl };
      const data = (await usersChangeProject({ projectId: companyId })).data;

      const prevUserInfo = storage.user.get();
      const userInfo: UserLoginResult = { ...prevUserInfo!, companyId, companyName, ...projectPref, ...data };
      await refreshUser(userInfo, false);
    },
    [refreshUser]
  );

  const logout = useCallback(async () => {
    const user = storage.user.get();
    if (user) await logoutRequest({});
    storage.clearAll();
    history.push('/user/login');
  }, []);

  return {
    captchaData,
    getCaptchaData,
    errorCount,
    login,
    loginLoading,
    logout,
    userProjects,
    getUserProjects,
    userChangeProject,
  };
};

async function encryptPassword(publicKeyStr: string, password: string) {
  const publicKey = await window.crypto.subtle.importKey(
    'spki',
    bufferFromBase64(publicKeyStr),
    { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
    false,
    ['encrypt']
  );

  const plain = `${+new Date()}${password}`;
  const encrypted = await window.crypto.subtle.encrypt({ name: 'RSA-OAEP' }, publicKey, bufferFromStr(plain));
  return bufferToBase64(encrypted);
}

/**
 * 将 ArrayBuffer 转成 base64 字符串
 */
const bufferToBase64 = (buffer: ArrayBuffer): string => {
  const binary = String.fromCharCode(...new Uint8Array(buffer));
  return btoa(binary);
};

/**
 * 将 base64 字符串转成 ArrayBuffer
 */
const bufferFromBase64 = (base64: string): ArrayBuffer => {
  const binary = atob(base64);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return bytes.buffer;
};

/**
 * 将字符串转成 ArrayBuffer
 */
const bufferFromStr = (str: string): ArrayBuffer => {
  const buffer = new ArrayBuffer(str.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < str.length; i++) {
    view[i] = str.charCodeAt(i);
  }
  return buffer;
};
