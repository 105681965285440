// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { IMAGE_URL } from '@/global';
import { useModel } from '@umijs/max';
import React, { useMemo } from 'react';
import moment from 'moment';
import styles from './index.less';

const defaultFooterSettings = {
  footerLogo: `${IMAGE_URL}favicon.png`,
  footerName: '9am',
  footerCopyright: `Copyright©${moment().year()} 9am Inc.`,
  footerUrl: 'http://9amtech.com/',
};

const GlobalFooter: React.FC = () => {
  const { initialState } = useModel('@@initialState');
  const user = useMemo(() => initialState?.user || defaultFooterSettings, [initialState]);

  return (
    <div className={styles.globalFooter}>
      <div className={styles.links}>
        <a target="_blank" href={user.footerUrl || ''} rel="noreferrer">
          {user.footerLogo && <img src={user.footerLogo} alt="pic" />}
          <span>{user.footerName || ''}</span>
        </a>
      </div>
      <div>{user.footerCopyright || ''}</div>
    </div>
  );
};

export default GlobalFooter;
