/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { AdminListItem } from "@ixam/apis/types/authority";
// @ts-ignore
import { Menu } from "@ixam/apis/types/login";
// @ts-ignore
import { isEmail } from "@ixam/apis/types/common";
export namespace Authority {
    export type GetAdminListAPIPayload = {};
    export function getAdminList(payload: GetAdminListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<AdminListItem[]>>(`/api/admin/manager/list`, { method, data, params, skipErrorHandler });
    }
    export type GetAdminPermissionListAPIPayload = {};
    export function getAdminPermissionList(payload: GetAdminPermissionListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<Menu[]>>(`/api/admin/manager/permissionList`, { method, data, params, skipErrorHandler });
    }
    export type AdminUpdateAPIPayload = {
        uid: string;
        permissionList: {
            permission: string;
            choose: boolean;
        }[];
    };
    export function adminUpdate(payload: AdminUpdateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["uid"] = payload["uid"];
        data["permissionList"] = payload["permissionList"];
        return request<ResponseSuccess<void>>(`/api/admin/manager/update`, { method, data, params, skipErrorHandler });
    }
    export type AdminSearchAPIPayload = {
        query: string;
    };
    export function adminSearch(payload: AdminSearchAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["query"] = `${payload["query"]}`;
        return request<ResponseSuccess<AdminListItem[]>>(`/api/admin/manager/search`, { method, data, params, skipErrorHandler });
    }
    export type AdminDeleteAPIPayload = {
        uid: string;
    };
    export function adminDelete(payload: AdminDeleteAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["uid"] = payload["uid"];
        return request<ResponseSuccess<void>>(`/api/admin/manager/delete`, { method, data, params, skipErrorHandler });
    }
    export type AdminAddUserAPIPayload = {
        email: string;
        name: string;
        phone: string;
        phonePrefix: string;
        imageUrl?: string;
    };
    export function adminAddUser(payload: AdminAddUserAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["email"] = payload["email"];
        data["name"] = payload["name"];
        data["phone"] = payload["phone"];
        data["phonePrefix"] = payload["phonePrefix"];
        data["imageUrl"] = payload["imageUrl"];
        return request<ResponseSuccess<void>>(`/api/admin/manager/addUser`, { method, data, params, skipErrorHandler });
    }
}
