/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { BeginHour, DaysFilter, EndHour, MatchType, MinTimeRule } from "@ixam/apis/types/constant";
// @ts-ignore
import { StationStatistics, StationTrend, StationUsageInDayItem, StationUsageInWeekItem, StationUsageRateDistributionItem, StationUseDurationRankItem, StationVacancyDaysDistributionItem, StationVacancyDaysRankItem, } from "@ixam/apis/types/station-analysis";
export namespace StationAnalysis {
    export type StatisticsAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function statistics(payload: StatisticsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<StationStatistics>>(`/api/station/statistics`, { method, data, params, skipErrorHandler });
    }
    export type GetStationAnalysisTrendAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function getStationAnalysisTrend(payload: GetStationAnalysisTrendAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<StationTrend>>(`/api/station/trend`, { method, data, params, skipErrorHandler });
    }
    export type GetCoverDaysProportionAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function getCoverDaysProportion(payload: GetCoverDaysProportionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<StationTrend>>(`/api/station/coverDaysProportion`, { method, data, params, skipErrorHandler });
    }
    export type UsageRateDistributionAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function usageRateDistribution(payload: UsageRateDistributionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<{
            list: StationUsageRateDistributionItem[];
        }>>(`/api/station/usage_rate_distribution`, { method, data, params, skipErrorHandler });
    }
    export type UsageInWeekAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function usageInWeek(payload: UsageInWeekAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<{
            list: StationUsageInWeekItem[];
        }>>(`/api/station/usage_in_week`, { method, data, params, skipErrorHandler });
    }
    export type UsageInDayAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function usageInDay(payload: UsageInDayAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<{
            list: StationUsageInDayItem[];
        }>>(`/api/station/usage_in_day`, { method, data, params, skipErrorHandler });
    }
    export type VacancyDaysDistributionAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        limit?: number;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function vacancyDaysDistribution(payload: VacancyDaysDistributionAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["limit"] = payload["limit"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<{
            list: StationVacancyDaysDistributionItem[];
        }>>(`/api/station/vacancy_days_distribution`, { method, data, params, skipErrorHandler });
    }
    export type VacancyDaysRankAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        limit?: number;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function vacancyDaysRank(payload: VacancyDaysRankAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["limit"] = payload["limit"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<{
            list: StationVacancyDaysRankItem[];
        }>>(`/api/station/vacancy_days_rank`, { method, data, params, skipErrorHandler });
    }
    export type UseDurationRankAPIPayload = {
        workdayType: DaysFilter;
        minTimeRule: MinTimeRule;
        beginHour: BeginHour;
        endHour: EndHour;
        dateType: string;
        limit?: number;
        restrictTags?: number[];
        floorPlanId?: number;
        matchType?: MatchType;
    };
    export function useDurationRank(payload: UseDurationRankAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["workdayType"] = payload["workdayType"];
        data["minTimeRule"] = payload["minTimeRule"];
        data["beginHour"] = payload["beginHour"];
        data["endHour"] = payload["endHour"];
        data["dateType"] = payload["dateType"];
        data["limit"] = payload["limit"];
        data["restrictTags"] = payload["restrictTags"];
        data["floorPlanId"] = payload["floorPlanId"];
        data["matchType"] = payload["matchType"];
        return request<ResponseSuccess<{
            list: StationUseDurationRankItem[];
        }>>(`/api/station/use_duration_rank`, { method, data, params, skipErrorHandler });
    }
}
