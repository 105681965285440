// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

const smartPower = {
  'space.smart-power.batch': '批量操作',
  'space.smart-power.batch.on': '批量通电',
  'space.smart-power.batch.off': '批量断电',
  'space.smart-power.batch.confirm-on': '确定批量给所选的设备通电吗?',
  'space.smart-power.batch.confirm-off': '确定批量给所选的设备断电吗?',
  'space.smart-power.batch.confirm-ok': '确认',
  'space.smart-power.search-placeholder': '设备编号/点位编号',
  'space.smart-power.filter.overload': '功率过大',
  'space.smart-power.id': '设备编号',
  'space.smart-power.network-status': '状态',
  'space.smart-power.network-status.online': '在线',
  'space.smart-power.network-status.offline': '离线',
  'space.smart-power.spot-id': '所属点位',
  'space.smart-power.current-power': '当前功率',
  'space.smart-power.current-power.overload': '功率过大',
  'space.smart-power.switch-status': '通电开关',
  'space.smart-power.switch-status.on': '已通电',
  'space.smart-power.switch-status.off': '已断电',
  'space.smart-power.remark.add': '添加备注',
  'space.smart-power.subscribe': '订阅预警通知',
  'space.smart-power.subscribe.title': '订阅通知',
  'space.smart-power.subscribe.on-off-hint': '系统将通过邮件发送设备预警信息',
  'space.smart-power.subscribe.on': '开启预警通知',
  'space.smart-power.subscribe.off': '关闭预警通知',
  'space.smart-power.subscribe.receiver-hint': '选择接收通知的管理员',
  'space.smart-power.total-device': '设备总数',
  'space.smart-power.current-power.normal': '正常',
  'space.smart-power.current-power.high': '高功率',
  'space.smart-power.monitoring.spot-id': '点位编号',
  'space.smart-power.monitoring.network-status': '设备状态',
  'space.smart-power.device-info': '设备信息',
  'space.smart-power.no-devices': '暂无设备',

  'space.smart-power.overview': '概况',
  'space.smart-power.performance-indicatorspower-device': '查看设备电量消耗的关键指标',

  'space.smart-power.analysis.tips-name1': '总用电量',
  'space.smart-power.analysis.tips-value1': '统计周期内的用电量总数',
  'space.smart-power.analysis.tips-name2': '比较昨日/上周/上月同期',
  'space.smart-power.analysis.tips-value2': '本统计周期某一时期用电量较上一统计周期相同时期用电量的变化百分比',
  'space.smart-power.analysis.tips-name3': '高峰用电量',
  'space.smart-power.analysis.tips-value3': `按日：一天内某个小时最高用电量
按周：一周内某天最高用电量
按月：一个月内某天最高用电量`,
  'space.smart-power.analysis.tips-name4': '比较昨日/上周/上月',
  'space.smart-power.analysis.tips-value4': '本统计周期高峰用电量较上一统计周期高峰用电量的变化百分比',
  'space.smart-power.analysis.tips-name5': '工位平均每日用电量',
  'space.smart-power.analysis.tips-value5': '本统计周期内的总用电量/使用过设备的工位总数',
  'space.smart-power.analysis.tips-name6': '比较昨日/上周/上月',
  'space.smart-power.analysis.tips-value6': '本统计周期工位平均每日用电量较上一统计周期工位平均每日用电量的变化百分比',
  'space.smart-power.analysis.tips-name7': '使用过的设备数',
  'space.smart-power.analysis.tips-value7': '统计周期内使用过的设备数',
  'space.smart-power.analysis.tips-name8': '设备总数',
  'space.smart-power.analysis.tips-value8': '公司电源总数',
  'space.smart-power.analysis.tips-name9': '更新日期',

  'space.smart-power.compare-yesterday': '比较昨日',
  'space.smart-power.compare-last-week': '比较上周',
  'space.smart-power.compare-last-month': '比较上月',
  'space.smart-power.compare-daily': '比较昨日同期',
  'space.smart-power.compare-week-on-week': '比较上周同期',
  'space.smart-power.compare-month-on-month': '比较上月同期',

  'space.smart-power.statistical-period': '数据统计周期',
  'space.smart-power.realtime-date-statistical': '实时数据统计天数',
  'space.smart-power.by-day': '按日',
  'space.smart-power.by-week': '按周',
  'space.smart-power.by-month': '按月',
  'space.smart-power.power-consumption-statistics': '用电量统计',
  'space.smart-power.power-used': '用电量',
  'space.smart-power.power-unit': 'kW⋅h',

  'space.smart-power.pick-period': '选择周期',
};

export default smartPower;
