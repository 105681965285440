// Copyright 2023 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

import { Common } from '@/services';
import { CountryRegionItem } from '@ixam/apis/types';
import { useCallback, useState } from 'react';

const { getCountryCode: getCountryCodeRequest } = Common;

export default () => {
  // 验证码
  const [countryCodeList, setCountryCodeList] = useState<CountryRegionItem[]>([]);
  const getCountryCode = useCallback(async () => {
    const data = (await getCountryCodeRequest({})).data;
    setCountryCodeList(data);
  }, []);

  return { countryCodeList, getCountryCode };
};
