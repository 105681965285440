/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { Scope } from "@ixam/apis/types/common";
// @ts-ignore
import { UserInfo } from "@ixam/apis/types/user";
export namespace User {
    export type ChangePasswordAPIPayload = {
        oldPassword: string;
        newPassword: string;
    };
    export function changePassword(payload: ChangePasswordAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["oldPassword"] = payload["oldPassword"];
        data["newPassword"] = payload["newPassword"];
        return request<ResponseSuccess<void>>(`/api/users/-/password`, { method, data, params, skipErrorHandler });
    }
    export type GetUserInfoAPIPayload = {
        uid: string;
    };
    export function getUserInfo(payload: GetUserInfoAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        return request<ResponseSuccess<UserInfo>>(`/api/users/${payload.uid}`, { method, data, params, skipErrorHandler });
    }
    export type ListAPIPayload = {
        scope: Scope;
    };
    export function list(payload: ListAPIPayload, skipErrorHandler?: boolean) {
        const method = "get";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        params["token"] = token;
        params["scope"] = `${payload["scope"]}`;
        return request<ResponseSuccess<UserInfo[]>>(`/api/users`, { method, data, params, skipErrorHandler });
    }
}
