/* eslint-disable */
/* prettier-ignore */
// WARNING: This file is generated automatically
// All changes will be overwritten.
// @ts-ignore
import { ResponseSuccess } from "@ixam/apis/types";
// @ts-ignore
import { getToken } from "@/utils/utils";
import { request } from "umi";
// @ts-ignore
import { FileType } from "@ixam/apis/types/_types";
// @ts-ignore
import { HandsetIdType } from "@ixam/apis/types/handset";
// @ts-ignore
import { SensorDirection } from "@ixam/apis/types/sensor";
// @ts-ignore
import { SortType } from "@ixam/apis/types/constant";
// @ts-ignore
import { SpotAddedItem, SpotItem, SpotType, SubSpotType } from "@ixam/apis/types/spot";
// @ts-ignore
import TimeZone from "@ixam/apis/types/timezone";
export namespace NewSpot {
    export type GetSpotListAPIPayload = {
        companyId?: number;
        query?: string;
        offset?: number;
        limit?: number;
        spotType?: SpotType[];
        sensorType?: number[];
        handsetIdType?: HandsetIdType[];
        sort?: SortType;
    };
    export function getSpotList(payload: GetSpotListAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["companyId"] = payload["companyId"];
        data["query"] = payload["query"];
        data["offset"] = payload["offset"];
        data["limit"] = payload["limit"];
        data["spotType"] = payload["spotType"];
        data["sensorType"] = payload["sensorType"];
        data["handsetIdType"] = payload["handsetIdType"];
        data["sort"] = payload["sort"];
        return request<ResponseSuccess<{
            list: SpotItem[];
            total: number;
        }>>(`/api/admin/spot/list`, { method, data, params, skipErrorHandler });
    }
    export type AddSpotAPIPayload = {
        companyId: number;
        type: SpotType;
        subtype: SubSpotType;
        sensors: {
            sensorId: string;
            sensorDirection?: SensorDirection;
        }[];
        handsetId?: string;
        eInkId?: string;
        inUse?: boolean;
        remark?: string;
        timeZone?: TimeZone;
        count?: number;
        isShared?: boolean;
    };
    export function addSpot(payload: AddSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "put";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["companyId"] = payload["companyId"];
        data["type"] = payload["type"];
        data["subtype"] = payload["subtype"];
        data["sensors"] = payload["sensors"];
        data["handsetId"] = payload["handsetId"];
        data["eInkId"] = payload["eInkId"];
        data["inUse"] = payload["inUse"];
        data["remark"] = payload["remark"];
        data["timeZone"] = payload["timeZone"];
        data["count"] = payload["count"];
        data["isShared"] = payload["isShared"];
        return request<ResponseSuccess<{
            spot: SpotAddedItem;
            sensorIds: string[];
        }>>(`/api/admin/spot`, { method, data, params, skipErrorHandler });
    }
    export type ImportSpotBatchAPIPayload = {
        file: File;
    };
    export function importSpotBatch(payload: ImportSpotBatchAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data = new FormData();
        data.append("token", token);
        data.append("file", payload["file"]);
        return request<ResponseSuccess<{
            total_count: number;
            success_count: number;
        }>>(`/api/admin/spot/import`, { method, data, params, skipErrorHandler });
    }
    export type UpdateSpotByTemplateAPIPayload = {
        file: File;
        companyId: number;
    };
    export function updateSpotByTemplate(payload: UpdateSpotByTemplateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data = new FormData();
        data.append("token", token);
        data.append("file", payload["file"]);
        data.append("companyId", payload["companyId"]);
        return request<ResponseSuccess<{
            total_count: number;
            success_count: number;
        }>>(`/api/admin/spot/update_by_template`, { method, data, params, skipErrorHandler });
    }
    export type UpdateSpotBatchAPIPayload = {
        ids: string[];
        inUse?: boolean;
        isDel?: boolean;
        isShared?: boolean;
    };
    export function updateSpotBatch(payload: UpdateSpotBatchAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["inUse"] = payload["inUse"];
        data["isDel"] = payload["isDel"];
        data["isShared"] = payload["isShared"];
        return request<ResponseSuccess<void>>(`/api/admin/spot/update`, { method, data, params, skipErrorHandler });
    }
    export type SetSpotCountingTypeAPIPayload = {
        ids: string[];
        sensorDirection: SensorDirection;
    };
    export function setSpotCountingType(payload: SetSpotCountingTypeAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["sensorDirection"] = payload["sensorDirection"];
        return request<ResponseSuccess<void>>(`/api/admin/spot/sensor_direction`, { method, data, params, skipErrorHandler });
    }
    export type ExportSpotQrCodeBatchAPIPayload = {
        ids: string[];
        title?: string;
        subTitle?: string;
        hints?: string;
        logoUrl?: string;
        locale?: string;
        width?: number;
        height?: number;
    };
    export function exportSpotQrCodeBatch(payload: ExportSpotQrCodeBatchAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["title"] = payload["title"];
        data["subTitle"] = payload["subTitle"];
        data["hints"] = payload["hints"];
        data["logoUrl"] = payload["logoUrl"];
        data["locale"] = payload["locale"];
        data["width"] = payload["width"];
        data["height"] = payload["height"];
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/data/qrcodes_export`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type BatchUnbindSensorsAPIPayload = {
        ids: string[];
        companyId: number;
    };
    export function batchUnbindSensors(payload: BatchUnbindSensorsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["companyId"] = payload["companyId"];
        return request<ResponseSuccess<void>>(`/api/admin/spot/deleteSensors`, { method, data, params, skipErrorHandler });
    }
    export type DownloadSpotUpdateTemplateAPIPayload = {
        ids: string[];
        companyId: number;
    };
    export function downloadSpotUpdateTemplate(payload: DownloadSpotUpdateTemplateAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["companyId"] = payload["companyId"];
        return new Promise((resolve, reject) => {
            request<Blob>(`/api/admin/spot/get_template`, { responseType: "blob", method, data, params })
                .then((blob) => resolve(blob))
                .catch((error) => {
                const reader = new FileReader();
                reader.onload = (event) => reject({ data: JSON.parse(event?.target?.result?.toString() || "{}") });
                reader.readAsText(error.data);
            });
        }) as Promise<Blob>;
    }
    export type BatchUnbindEInkIdsAPIPayload = {
        ids: string[];
        companyId: number;
    };
    export function batchUnbindEInkIds(payload: BatchUnbindEInkIdsAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["ids"] = payload["ids"];
        data["companyId"] = payload["companyId"];
        return request<ResponseSuccess<void>>(`/api/admin/spot/deleteEInkIds`, { method, data, params, skipErrorHandler });
    }
    export type UpdateSpotAPIPayload = {
        id: string;
        sensors?: {
            tag?: string;
            sensorDirection?: SensorDirection;
        }[];
        handsetId?: string;
        eInkId?: string;
        isDel?: boolean;
        inUse?: boolean;
        remark?: string;
        isShared?: boolean;
    };
    export function updateSpot(payload: UpdateSpotAPIPayload, skipErrorHandler?: boolean) {
        const method = "post";
        const token = getToken();
        let data: any = {};
        let params: Record<string, any> = {};
        data["token"] = token;
        data["sensors"] = payload["sensors"];
        data["handsetId"] = payload["handsetId"];
        data["eInkId"] = payload["eInkId"];
        data["isDel"] = payload["isDel"];
        data["inUse"] = payload["inUse"];
        data["remark"] = payload["remark"];
        data["isShared"] = payload["isShared"];
        return request<ResponseSuccess<void>>(`/api/admin/spot/${payload.id}`, { method, data, params, skipErrorHandler });
    }
}
