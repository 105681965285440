// Copyright 2024 9am Software. All rights reserved.
// Distribution of this file is strictly prohibited.

export default {
  // 找回密码
  'reset.password.lose': '链接无效或已过期。',
  'reset.password.continue': '继续',
  'reset.password.connect-us': '如有任何问题，可以与我们联系，我们将尽快为你解答。',
  'reset.password.phone': '电话',
  'reset.password.verify': '验证',
  'reset.password.save-success': '保存成功',
  'reset.password.save-fail': '保存失败',
  'reset.password.set-new-password': '设置新密码',
  'reset.password.set-new-password-start': '设置您新的密码并开始使用',
  'reset.password.go-login': '去登录',
  'reset.password.null': '输入账号邮箱，点击【发送】后系统将为您发送找回密码的邮件，您可以登录邮箱找回密码。',
  'reset.password.email-address': '邮箱地址',
  'reset.password.email-input': '请输入邮箱地址',
  'reset.password.send': '发送',
  'reset.password.send-email-success': '邮件发送成功',
  'reset.password.send-email-message-one': '系统已向您邮箱',
  'reset.password.send-email-message-two': '发送了一封找回密码的邮件，请注意查收。',
  'reset.password.send-email-none': '没有收到邮件？',
  'reset.password.send-again': '重新发送',
  'reset.password.email-error-format': '邮箱格式错误',
};
